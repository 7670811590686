import React, { useState, useEffect } from "react";
// import "./patientsList.css";
import "react-bootstrap-accordion/dist/index.css";
import SurveyHistoryPatientsData from "./SurveyHistoryPatientsData";
import SurveyHistoryInstanceData from "./SurveyHistoryInstanceData";
// import PatientsData from "./PatientsListData/patientsData";
function SurveyHistoryInstanceList(props) {





  return (
    <div className="m-3 finlist">
      {props.loader ?

        <div className="col-lg-12 accordionBody">
          <div
            id=""
            className="spinner"
            style={{ marginTop: "40px" }}
          >
            <div
              className="bounce1"
              style={{ backgroundColor: "#015a96" }}
            ></div>
            <div
              className="bounce2"
              style={{ backgroundColor: "#015a96" }}
            ></div>
            <div
              className="bounce3"
              style={{ backgroundColor: "#015a96" }}
            ></div>
          </div>
        </div>
        :
        <>
          <div className="row d-none d-xl-block">
            <div className="col-lg-12 col-md-12 col-sm-12 prow">
              <div className="row justify-content-center align-item-center p-3 patientDataTop">

                <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                  <span className="patientsinfoTop">Email Used</span>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                  <span className="patientsinfoTop">Phone No. Used</span>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                  <span className="patientsinfoTop">Updated Date</span>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                  <span className="patientsinfoTop">Status</span>
                </div>

              </div>
            </div>
          </div>
          {/* } */}
          {props.surveyHistoryInstanceData && (
            props.surveyHistoryInstanceData.map((data, i) => {
              return <SurveyHistoryInstanceData surveyHistoryData={props.surveyHistoryData} surveyHistoryInstanceData={data} loader={props.loader} />;
            })
          )}
        </>}
    </div>
  );
}

export default SurveyHistoryInstanceList;