import React, { useState, useEffect } from "react";
// import { BsPersonPlusFill } from "react-icons/bs";
// import { CiSearch } from "react-icons/ci";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOktaAuth } from "@okta/okta-react";
import getUserRights from "../Api/getUserRights";

const Table = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loader, setLoader] = useState(true);
  const [search, setSearch] = useState("");
  const itemsPerPage = 5;
  const { authState, oktaAuth } = useOktaAuth();
  
  useEffect(() => {
    setLoader(true);
      if (!authState?.isAuthenticated) {
        // When user isn't authenticated, forget any user info
      } else {
        oktaAuth.getUser().then((info) => {
          getUserRights(authState.accessToken.accessToken)
          .then((response) => {
            props.setUsers(response);
            setLoader(false);
          })
          .catch((err) => {
            console.log(err);
            setLoader(false);
          })
        });
        
      }
    }, [authState]);

  const handleClick = (id) => {
    setCurrentPage(Number(id));
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1); // Reset to first page on search change
  };

  const filteredPatients = props.users.filter((patient) => {
    const searchTerms = search.trim().toLowerCase().split(" ");
    if (searchTerms.length === 1) {
      return (
        patient.userFirstname.toLowerCase().includes(searchTerms[0]) ||
        patient.userLastname.toLowerCase().includes(searchTerms[0])
      );
    } else if (searchTerms.length > 1) {
      return (
        patient.userFirstname.toLowerCase().includes(searchTerms[0]) &&
        patient.userLastname.toLowerCase().includes(searchTerms[1])
      );
    }
    return true;
  });

  const getPageNumbers = () => {
    const totalPages = Math.ceil(filteredPatients.length / itemsPerPage);
    const maxPagesToShow = 5;
    const pages = [];
    let startPage = 1;
    let endPage = totalPages;

    if (totalPages > maxPagesToShow) {
      if (currentPage <= 3) {
        endPage = maxPagesToShow;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - (maxPagesToShow - 1);
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }

    if (startPage > 1) {
      pages.unshift("...");
      pages.unshift(1);
    }

    if (endPage < totalPages) {
      pages.push("...");
      pages.push(totalPages);
    }

    return pages;
  };

  const renderPageNumbers = getPageNumbers().map((number, index) => {
    if (number === "...") {
      return (
        <li key={index} className="page-item disabled">
          <span className="page-link">{number}</span>
        </li>
      );
    }
    return (
      <li
        key={index}
        className={`page-item ${currentPage === number ? "active" : ""}`}
      >
        <button className="page-link" onClick={() => handleClick(number)}>
          {number}
        </button>
      </li>
    );
  });

  const indexOfLastPatient = currentPage * itemsPerPage;
  const indexOfFirstPatient = indexOfLastPatient - itemsPerPage;
  const currentPatients = filteredPatients.slice(
    indexOfFirstPatient,
    indexOfLastPatient
  );

  return (
    <div>
      <div
        className="d-flex justify-content-between mt-4 "
        style={{ fontWeight: "600", fontSize: "2rem", color: "#004879" }}
      >
        <div>User Information</div>
        <div className="d-flex flex-column-reverse align-items-center flex-lg-row lg-align-items-center justify-content-center gap-4">

          <button
            type="submit"
            className="btn "
            style={{ backgroundColor: "#004879", color: "#E9ECEF", fontWeight:"bold" }}
            onClick={() => props.setShowForm(true)}
          >
             <FontAwesomeIcon icon={faUserPlus} size="xs"/>&nbsp;User
          </button>
        </div>

      </div>
      {loader? 
      <>
        <div id="" className="spinner" style={{ marginTop: "100px" }}>
          <div className="bounce1" style={{ backgroundColor: "#015a96" }}></div>
          <div className="bounce2" style={{ backgroundColor: "#015a96" }}></div>
          <div className="bounce3" style={{ backgroundColor: "#015a96" }}></div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-2" style={{fontWeight:"bold", color:"#015a96"}}>
          Please wait while we get the list of users
        </div>
      </>:
      <>
      <div className="d-flex search-name align-items-center gap-2 mt-1 mb-sm-2 mb-lg-0">
        <label className="flex-shrink-0 " style={{ color: "rgb(0, 72, 121)", fontWeight: "700" }}>Search by name </label>
        <input
          type="text"
          className="form-control"
          placeholder="Search by name"
          value={search}
          onChange={handleSearchChange}
        />
      </div>
        <div className="row d-none d-lg-block mt-4">
          <div className="col-lg-12 col-md-12 col-sm-12 prow">
            <div className="row justify-content-center align-item-center p-3 patientDataTop">
              <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop"> FIRST NAME </span>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop">LAST NAME</span>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop">EMAIL</span>
              </div>
            </div>
          </div>
        </div>
        {currentPatients && 
        <>
          {currentPatients.length === 0 ? 
          <div className="row d-flex justify-content-center p-3">
            <div
              className="col-12 d-flex justify-content-center"
              style={{ color: "black" }}
            >
              <span className="patientsinfoTop" >No Users found.</span>
            </div>
          </div>
          :
          <>
          {currentPatients.map((user, i) => {
          return(
            <div className="">
              <div className="row d-flex justify-content-center align-item-center p-3 patientData">
                <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                  <span className="d-lg-none patientsinfoTop" style={{ color: "black" }}>
                    First Name:&nbsp;
                  </span>
                  <span className="patientsinfo apEmail">{user.userFirstname}</span>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                  <span className="d-lg-none patientsinfoTop" style={{ color: "black" }}>
                    Last Name:&nbsp;
                  </span>
                  <span className="patientsinfo">{user.userLastname}</span>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                  <span className="d-lg-none patientsinfoTop" style={{ color: "black" }}>
                    Email:&nbsp;
                  </span>
                  <span className="patientsinfo">
                    {user.username}
                  </span>
                </div>
              </div>
            </div>
          );})}
          </>}
        </>}
      {/* <div className="table-container d-none d-lg-block mt-4">
        <div className="row">
          <div className="col-12">
            <table className="table" style={{ backgroundColor: "#CED4DA"}}>
              <thead className="table-light">
                <tr>
                  <th className="col">First Name</th>
                  <th className="col">Last Name</th>
                  <th className="col">Email</th>
                </tr>
              </thead>
              <tbody>
                {currentPatients.map((patient, index) => (
                  <tr key={index}>
                    <td>{patient.firstName}</td>
                    <td>{patient.lastName}</td>
                    <td>{patient.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}

      {/* <div className="cards-container mt-4 d-lg-none">
        {currentPatients.map((patient, index) => (
          <div className="card" key={index}>
            <div className="card-body">
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span
                  className="d-lg-none"
                  style={{ color: "black", fontWeight: "500" }}
                >
                  First Name:
                </span>
                <span className="patientsinfo text-primary">
                  &nbsp;{patient.firstName}
                </span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span
                  className="d-lg-none"
                  style={{ color: "black", fontWeight: "500" }}
                >
                  Last Name:
                </span>
                <span className="text-primary">&nbsp;{patient.lastName}</span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span
                  className="d-lg-none patientsinfoTop"
                  style={{ color: "black", fontWeight: "500" }}
                >
                  Email:
                </span>
                <span className="text-primary">&nbsp;{patient.email}</span>
              </div>
            </div>
          </div>
        ))}
      </div> */}
      <nav className="mt-3">
        <ul className="pagination justify-content-center">
          {renderPageNumbers}
        </ul>
      </nav>
      </>}
    </div>
  );
};

export default Table;
