import React from "react";
// import { PiUsersThreeFill } from "react-icons/pi";
// import { PiArrowsCounterClockwiseBold } from "react-icons/pi";
import { faUsers,faArrowsRotate, faGear, faClockRotateLeft,faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Adminpage.css";
const Sidebar = (props) => {
  const{setScreenType, screenType} = props;
  return (
    <div className=" desktop-sidebar d-none d-lg-block " style={{ width: "350px" }}>
      <ul className="nav nav-pills flex-column mt-4 gap-3">
        <li className="nav-item " onClick={() => setScreenType("userRights")}>
          <a href="#" className="nav-link additionalStyle fs-5 flex gap-2" style={{backgroundColor:screenType==="userRights"?"#61616119":""}}>
            <FontAwesomeIcon icon={faUsers} />
            &nbsp;User Info
          </a>
        </li>
        <li className="nav-item " onClick={() => setScreenType("resubmitSurveys")}>
          <a href="#" className="nav-link additionalStyle fs-5 flex gap-2" style={{backgroundColor:screenType==="resubmitSurveys"?"#61616119":""}}>
          <FontAwesomeIcon icon={faArrowsRotate} />
            &nbsp;Resubmit Surveys
          </a>
        </li>
        <li className="nav-item " onClick={() => setScreenType("surveyManager")}>
          <a href="#" className="nav-link additionalStyle fs-5 flex gap-2" style={{backgroundColor:screenType==="surveyManager"?"#61616119":""}}>
          <FontAwesomeIcon icon={faGear} />
            &nbsp;Survey Manager
          </a>
        </li>
        <li className="nav-item " onClick={() => setScreenType("surveyHistory")}>
          <a href="#" className="nav-link additionalStyle fs-5 flex gap-2" style={{backgroundColor:screenType==="surveyHistory"?"#61616119":""}}>
          <FontAwesomeIcon icon={faClockRotateLeft} />
            &nbsp;Survey History
          </a>
        </li>
        <li className="nav-item " onClick={() => setScreenType("surveyStatus")}>
          <a href="#" className="nav-link additionalStyle fs-5 flex gap-2" style={{backgroundColor:screenType==="surveyStatus"?"#61616119":""}}>
          <FontAwesomeIcon icon={faChartSimple} />
            &nbsp;Survey Stats
          </a>
        </li>
      </ul>
    </div>
  );
};
 
export default Sidebar;