import React, { useState, useEffect } from "react";
import Chevron from "../../images/chevron.png";
import dChevron from "../../images/chevron_d.png";
import deleteClinicSurvey from "../../Api/deleteClinicSurvey"
import { useAuth } from "../../AppContextProvider";

import SearchSurvey from "./SearchSurvey";
import { ClinicProject } from "./ClinicProject"; 
import { Spinner } from "react-bootstrap";
import getRegistrationFormPriority from "../../Api/getRegistrationFromPriority"
import getEncounterList from "../../Api/getEncounterList";

const SurveyInfo = (props) => {
  const [INchevron, setINchevron] = useState(false);
  const [InstrumentUnchecked, setInstrumentunchecked] = useState([]);
  const [encounterOptionList,setEncounterOptionList]=useState([]);
  const [showAddSurvey, setshowAddSurvey] = useState(false);
  const { accessToken } = useAuth();
  const[saveLoader, setSaveLoader] = useState(false);
  const [priorityArray, setPriorityArray] = useState([]);
  const[isPriorityDisabled,setIsPriorityDisabled]=useState(false)
  const[totalRegistrationFormPriorityCount,setTotalRegistrationFormPriorityCount]=useState(parseInt(localStorage.getItem("priorityCount"),10))

const setpriorityDisable=(value)=>{
  setIsPriorityDisabled(value)
}

  const handleTotalRegistrationFromPriorityCount=()=>{
    let count=parseInt(localStorage.getItem("priorityCount"),10)
    setTotalRegistrationFormPriorityCount(count)
  }
  const setPriorityData=()=>{
    getRegistrationFormPriority(accessToken)
    .then((response) => {
      setPriorityArray(response)
      setTotalRegistrationFormPriorityCount(response.length)
    })
    .catch((err)=>{
      console.log(err)
    })
  }
  
  useEffect(()=>{

  },[priorityArray])

  useEffect(() => {
    const fetchEncounterList = () => {
      getEncounterList(accessToken)
        .then((response) => {
          setEncounterOptionList(response);

        })
        .catch((err) => {
          console.log(err);
        })

    };
    fetchEncounterList();
  }, []);
 



  const handleAddSurveyPrompt = () => {
    setshowAddSurvey(!showAddSurvey);
  };

  const handleAddSurveyPromptSubmit = async () => {
    setshowAddSurvey(!showAddSurvey);
    props.setClinicSurvey(props.selectedClinic.id)
  }

  const handleInstrumentDelete = (DeptId) => {
    setInstrumentunchecked([...InstrumentUnchecked, DeptId]);

  }
  const handleInstrumentDeleteAsync = async () => {
    setSaveLoader(true);
    deleteClinicSurvey(InstrumentUnchecked, accessToken)
    .then((response) => {
        
        setInstrumentunchecked([])
        props.setClinicSurvey(props.selectedClinic.id);
        setSaveLoader(false);
    })
    .catch((err) => {
      setSaveLoader(false);
      console.log(err);
    })

  }

  const setInstrumentChecked = (DeptId) => {
    setInstrumentunchecked(data => data.filter(x => x !== DeptId));
    
  }
  
  return (
    <div className="col-12">
      {showAddSurvey && <SearchSurvey setShowAddSurvey={handleAddSurveyPromptSubmit} selectedClinic={props.selectedClinic} clinicSurvey={props.clinicSurvey} />}
      <div className="formsHeder">
        <div className="formsBody d-flex flex-sm-row justify-content-between align-items-sm-center">
          <div>Survey forms</div>
          <div className="d-flex flex-column align-items-end gap-2">

            <div class="dropdown d-sm-none d-block" onClick={() => setINchevron(!INchevron)}>
              <button class="btn " type="button" data-bs-toggle="dropdown" aria-expanded="false">
                <img
                  className="chevron "
                  src={INchevron ? dChevron : Chevron}
                alt=""
                />
              </button>
              <ul class="dropdown-menu">
                <li>              <button
                  className="d-flex justify-content-center align-items-center dropdown-item"
                  onClick={handleAddSurveyPrompt}
                  style={{

                    color: "#004879",

                    border: "none",
                    cursor: props.clinicSurvey.length > 0 ? "pointer" : "not-allowed"
                  }}
                  disabled={props.clinicSurvey.length === 0}
                >
                  Add Survey
                </button></li>
                <li>  <button
                  className="d-flex justify-content-center align-items-center dropdown-item"
                  onClick={handleInstrumentDeleteAsync}
                  style={{

                    color: "#004879",

                    border: "none",
                    cursor: InstrumentUnchecked.length > 0 ? "pointer" : "not-allowed"
                  }}

                  disabled={InstrumentUnchecked.length === 0}
                >
                  Save
                </button></li>

              </ul>
            </div>
            <div
              className={`d-sm-flex d-none gap-sm-4 gap-3 flex-column  flex-sm-row align-items-end justify-content-end ${INchevron ? "d-flex" : "d-none d-sm-flex"
                }`}
              style={{
                fontWeight: "700",
                fontSize: "smaller",
                color: "#004879",
              }}
            >
              <button
                className="addButton"
                onClick={handleAddSurveyPrompt}
                style={{
                  cursor: "pointer"
                }}
                
              >
                Add Survey
              </button>
              <button
                className="addButton"
                onClick={handleInstrumentDeleteAsync}
                style={{
                  minWidth:"100px",
                  cursor: InstrumentUnchecked.length > 0 ? "pointer" : "not-allowed",
                  opacity:InstrumentUnchecked.length > 0  ?"1":"0.8"
                }}

                disabled={InstrumentUnchecked.length === 0 || saveLoader}
              >
                {saveLoader ? <Spinner size="sm"/> : "Save"}
              </button>
            </div>
          </div>
        </div>
        {props.clinicSurvey.length>0?props.clinicSurvey.map((data) => (
          <ClinicProject projectData={data} selectedClinic={props.selectedClinic} encounterOptionList={encounterOptionList} handleInstrumentDelete={handleInstrumentDelete} setInstrumentChecked={setInstrumentChecked} handleTotalRegistrationFromPriorityCount={handleTotalRegistrationFromPriorityCount} totalRegistrationFormPriorityCount={totalRegistrationFormPriorityCount} setPriorityData={setPriorityData} priorityArray={priorityArray} setpriorityDisable={setpriorityDisable} isPriorityDisabled={isPriorityDisabled}/>
        )):
        <div className="py-4 text-center w-100 " style={{color:"#004867",fontSize:"15px"}}>No Survey Found. Please add new survey</div>
        }
      </div>
    </div>
  );
};

export default SurveyInfo;
