import React, { useState } from "react";

import "../../App.css";
import addNewUser from "../Api/addNewUser";
import { useAuth } from "../AppContextProvider";
import { Spinner } from "react-bootstrap";
import searchUserInfo from "../Api/searchUserInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";

const Form = (props) => {
  const [showThanks, setShowThanks] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userSearchLoader, setUserSearchLoader] = useState(false);
  const [userSearchError, setUserSearchError] = useState("");
  const [userData, setUserData] = useState(null);
  const [showUserDetails, setShowUserDetails] = useState(false);
  const inputHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }
  const { isAuthorized, setRedirectUri, accessToken } = useAuth();
  const [formData, setFormData] = useState(
    {
      userFirstname: '',
      userLastname: '',
      userEmail: '',
      uniqueRoleName: '',
      userSearchParam: ''
    });
  const onClickSubmit = () => {
    setLoader(true);
    addNewUser(formData, accessToken)
      .then((response) => {
        setLoader(false);
        props.setShowForm(false);
        props.getUserInfo();
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
      })
  }
  const onClickSearch = () => {
    setUserSearchLoader(true);
    setUserSearchError("");
    searchUserInfo(accessToken, formData.userSearchParam)
      .then((response) => {
        if (response != null) {
          var existingUser = props.users.filter(
            (user) => user.username.toLowerCase() === response.emailId.toLowerCase()
          );
          if(existingUser.length > 0){
            setUserSearchError("This user already has access to REDCap.")
            setUserSearchLoader(false);
            return;
          }
          setFormData({...formData, userFirstname:response.First_Name, userLastname:response.Last_Name, userEmail:response.emailId.toLowerCase()})
          setShowUserDetails(true);
        }
        else{
          setUserSearchError("Please enter a valid Email address, Employee ID or Username.")
        }
        setUserSearchLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setUserSearchLoader(false);
        setUserSearchError("Please enter a valid Email address, Employee ID or Username.");
      })
  }
  const onClickReset = () => {
    setShowUserDetails(false);
    setFormData({...formData, userSearchParam:"", uniqueRoleName:""});
  }
  return (
    <div className="popup-form-overlay">
      <div className="popup-form">
        <div className="popup-form-header">
          <h5 className="popup-form-title">Add User</h5>
        </div>
        {showUserDetails ?
          <div className="popup-form-body">
            <div className="mb-3">
              <div className="row">
                <div className="col-10">
                  <label htmlFor="" className="form-lable">User Details</label>
                </div>
                <div className="col-2 d-flex justify-content-center align-items-center resetButton" onClick={onClickReset}>
                  Reset&nbsp;<FontAwesomeIcon icon={faArrowsRotate} />
                </div>
              </div>
              <div className="userInfo py-2 px-3">
                <div className="col-12 d-flex justify-content-left mb-1">
                  <span className=" patientsinfoTop mb-1" style={{ color: "black" }}>
                    First Name:&nbsp;
                  </span>
                  <span className="patientsinfo">{formData.userFirstname}</span>
                </div>
                <div className="col-12 d-flex justify-content-left mb-1">
                  <span className=" patientsinfoTop mb-1" style={{ color: "black" }}>
                    Last Name:&nbsp;
                  </span>
                  <span className="patientsinfo">{formData.userLastname}</span>
                </div>
                <div className="col-12 d-flex justify-content-left mb-1">
                  <span className=" patientsinfoTop mb-1" style={{ color: "black" }}>
                    Email Address:&nbsp;
                  </span>
                  <span className="patientsinfo">{formData.userEmail}</span>
                </div>
              </div>
            </div>

            <div className="mb-3">
              <label htmlFor="" className="form-lable">Select Role*</label>
              <select
                className="form-select"
                name="uniqueRoleName"
                value={formData.role}
                onChange={inputHandler}
                required
              >
                <option value="">Select a role</option>
                {props.roles.map((role, index) => (
                  <option key={index} value={role.unique_role_name}>
                    {role.role_name}
                  </option>
                ))}
              </select>
            </div>
            <div className="d-flex justify-content-center gap-4  ">
              <button
                onClick={onClickSubmit}
                className="searchButton"
                disabled = {formData.uniqueRoleName === ""}
              >
                {loader ? <Spinner size="sm" animation="border"/> : "Submit"}
              </button>
              <button
                type="button"
                className="cancelButton"
                onClick={() => props.setShowForm(false)}
                disabled={loader}
              >
                Cancel
              </button>
            </div>
          </div>
          :
          <div className="popup-form-body">
            <div className="mb-1">
              <label htmlFor="" className="form-lable mb-1">Enter User's Email address, Employee ID or Username</label>
              <input
                type="text"
                name="userSearchParam"
                className="form-control"
                placeholder="Email address, Employee ID or Username"
                onChange={inputHandler}
                
              />
            </div>
            {userSearchError !== "" && 
            <>
              <p style={{margin:"0px", color:"red", fontWeight:"light" }}>{userSearchError}</p>
            </>}
            <div className="row mt-3">
              <div className="col-6 d-flex justify-content-end ">
                <button
                  className="searchButton"
                  onClick={onClickSearch}
                  disabled={formData.userSearchParam === "" || userSearchLoader}
                >
                  {userSearchLoader ? <Spinner size="sm" animation="border" /> : "Search"}
                </button>
              </div>
              <div className="col-6 ">
                <button
                  type="button"
                  className="cancelButton"
                  onClick={() => props.setShowForm(false)}
                  disabled={userSearchLoader}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default Form;
