import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../AppContextProvider";
import { Spinner, Tooltip, OverlayTrigger } from "react-bootstrap";
import deleteSurveyConfig from "../../Api/deleteSurveyConfig";
import getEncounterList from "../../Api/getEncounterList";
import getSurveyConfig from "../../Api/getSurveyConfig";
import updateSurveyConfig from "../../Api/updateSurveyConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faList, faPlus, faTrash, faEye, faL } from "@fortawesome/free-solid-svg-icons";
import PrefillInstrumentConfig from "./PrefillInstrumentConfig";
import { authenticate } from "@okta/okta-auth-js";
import ToggleButton from "./ToggleButton";

export const ClinicInstrument = (props) => {
  const initialConditions = [
    {
      label: "Age",
      value: "age"
    },
    {
      label: "Location",
      value: "location"
    },
    {
      label: "Survey Interval",
      value: "interval"
    },
    {
      label: "Encounter",
      value: "encounter"
    }
  ];
  const [showCondition, setShowCondition] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);
  const [showAdditionalSettings, setShowAdditionalSettings] = useState(false);
  const [showAddCondition, setShowAddCondition] = useState(false);
  const [type, setType] = useState("");
  const [lowerLimit, setLowerLimit] = useState("");
  const [upperLimit, setUpperLimit] = useState("");
  const [intervalValue, setIntervalValue] = useState("");
  const [Instrumentchecked, setInstrumentChecked] = useState(true);
  const [conditions, setConditions] = useState(initialConditions);
  const [selectedConditions, setSelectedConditions] = useState([]);
  const [conditionValues, setConditionValues] = useState({});
  const [errorMessage, setErrorMessage] = useState({ condition: "", msg: "" });
  const [saved, setSaved] = useState(false);
  const [loader, setloader] = useState(false);
  const [initialConditionPresent, setInitialConditionPresent] = useState(false);
  const [configLoader, setConfigLoader] = useState(false);
  const [delLoader, setDelLoader] = useState({ condition: "", value: false });
  const { accessToken } = useAuth();
  const [selectedEncounterOptions, setSelectedEncounterOptions] = useState([]);
  const [showSelectedEncounterOptions, setShowSelectedEncounterOptions] = useState(false);
  const [showEncounterOptions, setShowEncounterOptions] = useState(false);
  const [encounterSearchTerm, setEncounterSearchTerm] = useState('');
  const [encounterOptionList, setEncounterOptionList] = useState([]);
  const [isCheckedFillOnce, setIsCheckedFillOnce] = useState(false);
  const [isCheckedRegistrationForm, setIsCheckedRegistrationForm] = useState(false);
  const [isInterpretationChecked,setIsInterpretationChecked]=useState(false);
  const [isCheckedAutoSelect, setIsCheckedAutoSelect] = useState(false);
  const [isCheckedPriority, setIsCheckedPriority] = useState(false);
  const [priority, setPriority] = useState(false);
  const encounterdiv = useRef(null);
  useEffect(() => {
    const tooltipTriggerList = document.querySelectorAll(
      '[data-bs-toggle="tooltip"]'
    );

    const tooltipTriggerListRemove = document.querySelectorAll(
      '[data-bs-toggle=""]'
    );

  }, [conditions]);

  useEffect(() => {
    if (saved) {
      const timer = setTimeout(() => {
        setSaved(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [saved]);

  useEffect
    (
      () => {
        setEncounterOptionList(props.encounterOptionList);
        document.addEventListener('mousedown', handleClickOutside);

      }, []);

  const handleClickOutside = (event) => {
    if (encounterdiv.current && !encounterdiv.current.contains(event.target)) {
      setShowEncounterOptions(false);
      setShowSelectedEncounterOptions(false)
    }
  };

  useEffect(() => {
    const setPriorityStatus = () => {
      if (props.priorityArray.length != 0) {
        const match = props.priorityArray.find(item => item.surveyId === props.instrumentData.surveyId);
        let value = match ? match.priority : null;
        setPriority(value)

      }
    }
    setPriorityStatus()
  }, [props.priorityArray])
  function handleEncounterSelect(item) {

    const isSelected = selectedEncounterOptions.some(option => option === item);

    if (isSelected) {
      setSelectedEncounterOptions(selectedEncounterOptions.filter(option => option !== item));
    } else {
      setSelectedEncounterOptions([...selectedEncounterOptions, item]);
    }

  }
  const toggleEncounterOptions = () => {
    setShowEncounterOptions(!showEncounterOptions);
    setShowSelectedEncounterOptions(false);
    setEncounterSearchTerm('')
    // setShowEncounterOptions(false)
  };
  const toggleEncounterSelectedOptions = () => {
    setShowSelectedEncounterOptions(!showSelectedEncounterOptions);
    setShowEncounterOptions(false);

    // setShowEncounterOptions(false)
  };
  const filteredEncounterOptions = encounterOptionList.filter(
    item => item.toLowerCase().includes(encounterSearchTerm.toLowerCase())
  );


  const handleConditionClick = (condition) => {
    setShowAddCondition(false);
    setSelectedConditions((prev) => [...prev, condition]);
    setConditions((prevConditions) =>
      prevConditions.filter((x) => x.value !== condition.value)
    );
  };

  const handleDeleteConditionClick = (condition) => {
    setDelLoader({ condition: condition.value, value: true });
    setShowAddCondition(false);

    const data = {
      surveyId: props.instrumentData.surveyId,
      parameter: condition.value,
      value: "",
    };
    deleteSurveyConfig(data, accessToken)
      .then((response) => {
        setDelLoader({ condition: condition.value, value: false });
        setConditions((prev) => [...prev, condition]);
        setSelectedConditions((prevConditions) =>
          prevConditions.filter((x) => x.value !== condition.value)
        );
        if (condition.value === "age") {
          setLowerLimit("");
          setUpperLimit("");
          setErrorMessage({});
        } else if (condition.value === "location") {
          setType("");
        } else if (condition.value === "interval") {
          setIntervalValue("");
        }

        else if (condition.value === "encounter") {
          setSelectedEncounterOptions([])
          setEncounterSearchTerm('')
          setShowEncounterOptions(false)
        }
        const updatedConditionValues = { ...conditionValues };
        delete updatedConditionValues[condition.value];
        setConditionValues(updatedConditionValues);

        if (condition.value === "age") {
          setLowerLimit("");
          setUpperLimit("");
          setErrorMessage({});
        } else if (condition.value === "location") {
          setType("");
        } else if (condition.value === "interval") {
          setIntervalValue("");
        }

        else if (condition.value === "encounter") {
          setSelectedEncounterOptions([])
          setEncounterSearchTerm('')
          setShowEncounterOptions(false)
        }

      })
      .catch((err) => {
        setDelLoader({ condition: condition.value, value: false });
        console.log(err);
      });
  };

  const handleShowAdditionalSettings = async () => {
    setErrorMessage("");
    setSelectedConditions([]);
    setSelectedEncounterOptions([])
    let condition = !showCondition;
    setShowAdditionalSettings(!showAdditionalSettings);
    setShowCondition(!showCondition);
    setConfigLoader(true);
    if (showCondition === false) setSaved(false);
    if (condition) {
      let data = []
      getSurveyConfig(props.instrumentData.surveyId, accessToken)
        .then((response) => {
          const conditionData = response.reduce((acc, item) => {
            if (item.parameter === "age") {
              const [lower, upper] = item.value.split("-");
              acc[item.parameter] = { lowerLimit: lower, upperLimit: upper };
              data = [...data, { label: "Age", value: "age" }]
              setLowerLimit(lower);
              setUpperLimit(upper);
              setInitialConditionPresent(true);
            } else if (item.parameter == "location") {
              acc[item.parameter] = item.value;
              data = [...data, { label: "Location", value: "location" }]
              setType(item.value);
              setInitialConditionPresent(true);
            } else if (item.parameter == "interval") {
              acc[item.parameter] = item.value;
              data = [...data, { label: "Survey Interval", value: "interval" }]
              setIntervalValue(item.value);
              setInitialConditionPresent(true);
            }
            else if (item.parameter == "encounter") {
              acc[item.parameter] = item.value.split(",");
              let encounterVal = item.value.split(",");
              var encounterItem = encounterOptionList.filter(x => encounterVal.includes(x));
              setSelectedEncounterOptions(encounterItem)
              data = [...data, { label: "Encounter", value: "encounter" }]
              setInitialConditionPresent(true);
            }
            else if (item.parameter == "fillOnce") {
              setIsCheckedFillOnce(true);
            }
            else if (item.parameter == "interpreter") {
              setIsInterpretationChecked(true);
            }
            else if (item.parameter == "registrationForm") {
              setIsCheckedRegistrationForm(true);
            }
            else if (item.parameter == "autoSelect") {
              setIsCheckedAutoSelect(true);
            }
            else if (item.parameter == "priority") {
              setIsCheckedPriority(true);
              setPriority(parseInt(item.value, 10))
            }
            return acc;
          }, {});


          setConfigLoader(false);
          setConditionValues(conditionData);
          setConditions(
            initialConditions.filter(
              (condition) => !conditionData.hasOwnProperty(condition.value)
            )
          );
          setSelectedConditions(data);
        })
        .catch((err) => {
          setConfigLoader(false);
          console.log(err);
        });
    }
  };

  const handleSaveConditions = async () => {
    setloader(true);
    setErrorMessage("");

    if (
      selectedConditions.find(cond => cond.value === "age") &&
      parseInt(upperLimit) <= parseInt(lowerLimit)
    ) {
      setErrorMessage({
        condition: "age",
        msg: "Please enter upper limit greater than lower limit.",
      });
      setloader(false);
      return;
    }

    if (
      selectedConditions.find(cond => cond.value === "encounter") &&
      selectedEncounterOptions.length === 0
    ) {
      setErrorMessage({
        condition: "encounter",
        msg: "Please select atleast one encounter.",
      });
      setloader(false);
      return;
    }

    const updatedSurveyCondition = selectedConditions.map((condition) => {
      if (condition.value === "age") {
        return {
          surveyId: parseInt(props.instrumentData.surveyId),
          parameter: "age",
          value: `${lowerLimit === undefined || lowerLimit === null || lowerLimit === ""
            ? 0
            : lowerLimit || conditionValues.age?.lowerLimit
            }${upperLimit === undefined || upperLimit === null || upperLimit === ""
              ? ""
              : "-" + (upperLimit || conditionValues.age?.upperLimit)
            }`,
        };
      } else if (condition.value === "location") {
        return {
          surveyId: parseInt(props.instrumentData.surveyId),
          parameter: "location",
          value: type || conditionValues.location,
        };
      } else if (condition.value === "interval") {
        return {
          parameter: "interval",
          value: intervalValue || conditionValues.interval,
          surveyId: parseInt(props.instrumentData.surveyId),
        };
      } else if (condition.value === "encounter") {
        let encounterList = selectedEncounterOptions.map(item => item);
        let encounterString = encounterList.join();
        return {
          parameter: "encounter",
          value: encounterString,
          surveyId: parseInt(props.instrumentData.surveyId),
        };
      }
    });

    updateSurveyConfig(updatedSurveyCondition, accessToken)
      .then((response) => {
        setSaved(true);
        setloader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleInstrumentChecked = (DeptId) => {
    let checked = !Instrumentchecked;
    setInstrumentChecked(!Instrumentchecked);
    if (!checked) props.setInstrumentunchecked(DeptId);
    else props.setInstrumentChecked(DeptId);
  };

  const allConditionsFilled = () => {
    if (initialConditionPresent) return false;
    return selectedConditions.every((condition) => {
      if (condition.value === "age") {
        if (!upperLimit && !lowerLimit) return false;
        if (upperLimit) {

          return upperLimit;
        }
        if (lowerLimit) return lowerLimit;
      } else if (condition.value === "location") {
        return type;
      }
      else if (condition.value === "interval") {
        return intervalValue;
      }
      else if (condition.value === "encounter") {
        return selectedEncounterOptions.length >= 0;
      }
      return true;
    });
  };

  const handleToggle = (event) => {

    if (event.target.name === "priorityValue") {
      props.setpriorityDisable(true)
      var reqBody = [
        {
          surveyId: props.instrumentData.surveyId,
          parameter: "priority",
          value: event.target.value
        }]
      setPriority(event.target.value)
      updateSurveyConfig(reqBody, accessToken)
        .then((response) => {
          setloader(false);
          props.setPriorityData()
          props.setpriorityDisable(false)
        })
        .catch((err) => {
          console.log(err);
          props.setpriorityDisable(false)
        });
      return
    }
    if (event.target.checked) {
      if (event.target.name === "priority")
        {
        props.setpriorityDisable(true)
        setIsCheckedPriority(event.target.checked)
        let count = parseInt(localStorage.getItem("priorityCount"), 10) + 1
        localStorage.setItem("priorityCount", count)
        setPriority(count);
        props.handleTotalRegistrationFromPriorityCount()
        if (event.target.checked) {
          var reqBody = [
            {
              surveyId: props.instrumentData.surveyId,
              parameter: event.target.name,
              value: count
            }]
          updateSurveyConfig(reqBody, accessToken)
            .then((response) => {
              setloader(false);
              props.setpriorityDisable(false)
            })
            .catch((err) => {
              console.log(err);
              props.setpriorityDisable(false)
            });
        }
        return
      }
      var reqBody = [
        {
          surveyId: props.instrumentData.surveyId,
          parameter: event.target.name,
          value: event.target.checked ? "1" : "0"
        }]
      updateSurveyConfig(reqBody, accessToken)
        .then((response) => {
          setloader(false);

        })
        .catch((err) => {
          console.log(err);
        });
    }
    else {
      if(event.target.name == "registrationForm" || event.target.name === "priority"){
        props.setpriorityDisable(true)
      }
      const data = {
        surveyId: props.instrumentData.surveyId,
        parameter: event.target.name,
        value: "",
      };
      deleteSurveyConfig(data, accessToken).then((response) => {
        if (event.target.name === "priority") {

          let count = parseInt(localStorage.getItem("priorityCount"), 10) - 1
          localStorage.setItem("priorityCount", count)
          props.setPriorityData()
          props.setpriorityDisable(false)
        }
        if (event.target.name === "registrationForm" && isCheckedPriority) {
          const data = {
            surveyId: props.instrumentData.surveyId,
            parameter: "priority",
            value: "",
          };
          deleteSurveyConfig(data, accessToken).then((response) => {
            let count = parseInt(localStorage.getItem("priorityCount"), 10) - 1
            localStorage.setItem("priorityCount", count)
            props.setPriorityData()
            props.setpriorityDisable(false)
            setIsCheckedPriority(false)
          }).catch((err) => {
            console.log(err);
            props.setpriorityDisable(false)
          })
        }
        else if(event.target.name === "registrationForm")
          {props.setpriorityDisable(false)}

      })
        .catch((err) => {
          console.log(err);
          props.setpriorityDisable(false)
        })
    }
    if (event.target.name === "fillOnce")
      setIsCheckedFillOnce(event.target.checked);
    else if (event.target.name === "registrationForm")
      setIsCheckedRegistrationForm(event.target.checked)
    else if (event.target.name === "autoSelect")
      setIsCheckedAutoSelect(event.target.checked)
    else if (event.target.name === "priority")
      setIsCheckedPriority(event.target.checked)
    else if(event.target.name==="interpreter")
      setIsInterpretationChecked(event.target.checked)


  };

  return (
    <div className="instruments" style={{ fontSize: "18px" }}>
      <div className="row">
        <div className="col-10 d-flex align-items-center">
          {props.instrumentData.instrumentLabel}
        </div>
        <div className="col-2">
          <label className="custom-checkbox-label">
            {!Instrumentchecked ? (
              <span
                onClick={() => handleInstrumentChecked(props.instrumentData.id)}
              >
                &#9744;
              </span>
            ) : (
              <span
                onClick={() => handleInstrumentChecked(props.instrumentData.id)}
              >
                &#9745;
              </span>
            )}
          </label>
        </div>
      </div>
      <div className="row">
        <div className="col-12 mt-1 d-flex justify-content-end historyText">
          <div
            onClick={handleShowAdditionalSettings}
            style={{ cursor: "pointer", fontSize: "15px" }}
          >
            Additional Settings {showAdditionalSettings ? "▼" : "▲"}
          </div>
        </div>
      </div>
      {showAdditionalSettings && (
        <div className="condition" style={{ fontSize: "18px" }}>
          <div>
            <ul className="nav nav-tabs border-0 m-0">
              <li
                className={`metaData-item ${!showMetadata ? "underline" : ""}`}
                style={{
                  opacity: showMetadata ? 0.6 : 1,
                  fontWeight: showMetadata ? "500" : "700",
                }}
                onClick={() => setShowMetadata(false)}
              >
                Conditions
              </li>
              <li
                className={`metaData-item ms-sm-3 ms-0 ${showMetadata ? "underline" : ""
                  }`}
                style={{
                  opacity: !showMetadata ? 0.6 : 1,
                  fontWeight: !showMetadata ? "500" : "700",
                }}
                onClick={() => setShowMetadata(true)}
              >
                Prefill Survey
              </li>
            </ul>
          </div>
          {showCondition && !showMetadata && (
            <div>
              <div className="d-flex justify-content-end align-items-center row">
                <div className="pt-1 col-11">
                  <ToggleButton handleToggle={handleToggle} OTSChecked={isCheckedFillOnce} Reg={isCheckedRegistrationForm} isPriorityDisabled={props.isPriorityDisabled} InterpretationChecked={isInterpretationChecked}/>
                </div>
                <div className=" d-flex  justify-content-end align-items-center col-1">
                  <div className="position-relative">
                    <div
                      className="text-end mx-2"
                      style={{ fontSize: "30px" }}
                      onClick={() => setShowAddCondition(!showAddCondition)}
                    >
                      {conditions.length === 0 ? (
                        <OverlayTrigger
                          key={"bottom"}
                          placement={"bottom"}
                          overlay={
                            <Tooltip id={`tooltip-${"bottom"}`}>
                              {"All conditions have been set"}
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon icon={faPlus} size="sm" />
                        </OverlayTrigger>
                      ) : (
                        <FontAwesomeIcon icon={faPlus} size="sm" />
                      )}
                    </div>
                    {showAddCondition && conditions.length > 0 && (
                      <ul
                        className="list-group position-absolute dropdown-list"
                        style={{
                          top: "100%",
                          maxHeight: "250px",
                          zIndex: 110,
                          fontWeight: "500",
                          width: "200px",
                          overflowY: "scroll",
                        }}
                      >
                        {conditions.map((item, index) => (
                          <li
                            key={index}
                            className="list-group-item"
                            onClick={() => handleConditionClick(item)}
                            style={{ cursor: "pointer", color: "#004879" }}
                          >
                            {item.label}
                          </li>
                        ))}
                      </ul>
                    )}

                  </div>
                </div>
                {isCheckedRegistrationForm &&
                  <div className="pt-1 col-11">
                    <div className="d-flex flex-row  justify-content-end me-2">
                      <div class="form-check form-switch d-flex justify-content-end me-4 col-5 col-md-4 toggle-lg-4" >
                        <input name="autoSelect" class="form-check-input me-2" onChange={(e) => handleToggle(e)} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={isCheckedAutoSelect} />
                        <label class="apEmail" style={{ whiteSpace: "nowrap", fontSize: "17px" }} >Auto Select</label>
                      </div>
                      <div class="form-check form-switch d-flex justify-content-start  col-5 col-md-4 toggle-lg-4 ">
                        <input name="priority" class="form-check-input me-2" onChange={(e) => handleToggle(e)} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={isCheckedPriority} onClick={props.handleTotalRegistrationFromPriorityCount} disabled={props.isPriorityDisabled}/>
                        <label class="apEmail" style={{ whiteSpace: "nowrap", fontSize: "17px", width: "90px" }} >Sequence</label>
                        {isCheckedPriority && <select className="priority me-2" name="priorityValue" value={priority} onChange={(e) => handleToggle(e)} onClick={props.handleTotalRegistrationFromPriorityCount} disabled={props.isPriorityDisabled}>
                          {Array.from({ length: props.totalRegistrationFormPriorityCount }, (_, index) => (
                            <option key={index + 1} value={index + 1}>
                              {index + 1}
                            </option>
                          ))}
                        </select>}
                        {/* <input name="autoSelect" class="form-check-input me-2" onChange={(e) => handleToggle(e)} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={isCheckedAutoSelect}/> */}
                      </div>
                    </div>
                  </div>}
                <div className="col-1" />
              </div>
              {selectedConditions.length === 0 ? (
                <>
                  {configLoader ? (
                    <div className="col-lg-12 accordionBody">
                      <div
                        id=""
                        className="spinner"
                        style={{ marginTop: "40px" }}
                      >
                        <div
                          className="bounce1"
                          style={{ backgroundColor: "#015a96" }}
                        ></div>
                        <div
                          className="bounce2"
                          style={{ backgroundColor: "#015a96" }}
                        ></div>
                        <div
                          className="bounce3"
                          style={{ backgroundColor: "#015a96" }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="text-center mt-3"
                      style={{
                        fontWeight: "700",
                        color: "GrayText",
                        fontSize: "15px",
                      }}
                    >
                      No conditions have been selected yet. Please click &nbsp;
                      <FontAwesomeIcon icon={faPlus} />
                      &nbsp; to add one.
                    </div>
                  )}
                </>
              ) : (
                <div className="">
                  <ul className="list-group">
                    {selectedConditions.map((condition, index) => (
                      <>

                        <li
                          key={index}
                          className="d-flex flex-column flex-md-row align-items-start align-items-md-center pt-3"
                        >
                          <div className="col-md-3 col-12 mb-2 mb-md-0 d-flex justify-content-between align-items-center">
                            <label
                              style={{ minWidth: "50px", fontWeight: "200" }}
                            >
                              {condition.label.charAt(0).toUpperCase() +
                                condition.label.slice(1)}
                              :
                            </label>
                            <div className="col-4 d-flex d-sm-none mx-2">
                              {delLoader.condition === condition.value &&
                                delLoader.value ? (
                                <Spinner size="sm" />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleDeleteConditionClick(condition)
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div className="d-flex flex-row align-items-center col-md-9 col-12  mb-2 mb-md-0">
                            {condition.value === "age" && (
                              <div className="d-flex align-items-center col-sm-7 col-md-7 col-12">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={lowerLimit}
                                  placeholder={
                                    conditionValues.age?.lowerLimit
                                      ? conditionValues.age?.lowerLimit
                                      : "lower limit"
                                  }
                                  style={{ maxWidth: "150px" }}
                                  onChange={(e) => {
                                    setLowerLimit(e.target.value);
                                    setSaved(false);
                                    setErrorMessage({});
                                    setInitialConditionPresent(false);
                                  }}
                                />
                                <span>-</span>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={upperLimit}
                                  placeholder={
                                    conditionValues.age?.upperLimit
                                      ? conditionValues.age?.upperLimit
                                      : "upper limit"
                                  }
                                  style={{ maxWidth: "150px" }}
                                  onChange={(e) => {
                                    setUpperLimit(e.target.value);
                                    setSaved(false);
                                    setErrorMessage({});
                                    setInitialConditionPresent(false);
                                  }}
                                  required
                                />
                              </div>
                            )}


                            {condition.value === "location" && (
                              <div className="d-flex align-items-center col-sm-7 col-md-7 col-12">
                                <select
                                  className="form-control"
                                  value={type}
                                  style={{ maxWidth: "200px" }}
                                  onChange={(e) => {
                                    setType(e.target.value);
                                    setSaved(false);
                                    setInitialConditionPresent(false);
                                  }}
                                >
                                  <option value="">Select Location</option>
                                  <option value="any">Any</option>
                                  <option value="inPatient">In Patient</option>
                                  <option value="outPatient">
                                    Out Patient
                                  </option>
                                </select>
                              </div>
                            )}
                            {condition.value === "interval" && (
                              <div className="d-flex align-items-center col-sm-7 col-md-7 col-12">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={intervalValue}
                                  min="0"
                                  placeholder={
                                    conditionValues.interval
                                      ? conditionValues.interval
                                      : "interval"
                                  }
                                  style={{ maxWidth: "150px" }}
                                  onChange={(e) => {
                                    setIntervalValue(e.target.value);
                                    setSaved(false);
                                    setInitialConditionPresent(false);
                                  }}
                                />&nbsp;<div style={{ fontSize: "14px" }}>day(s)</div>
                              </div>
                            )}
                            {condition.value === "encounter" && (
                              <div ref={encounterdiv} className="d-flex align-items-center position-relative col-sm-7 col-md-7 col-12">

                                <input
                                  className="encounter-input "
                                  id="currentAppointment"
                                  type="text"

                                  value={selectedEncounterOptions.map(option => option).join(', ')}
                                  placeholder="Select value"
                                  onClick={toggleEncounterOptions}
                                  readOnly
                                />


                                <FontAwesomeIcon icon={faEye}
                                  size="xs"
                                  onClick={toggleEncounterSelectedOptions}
                                  style={{
                                    position: "absolute", top: "50%",
                                    right: "5px",
                                    transform: "translateY(-50%)",
                                  }} />

                                {showEncounterOptions && (
                                  <div className="dropdown-menu show position-absolute" style={{ width: '100%', top: '100%', zIndex: 110 }}>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search by Encounter Type"
                                      value={encounterSearchTerm}
                                      onChange={(e) => setEncounterSearchTerm(e.target.value)}
                                    />
                                    <ul className="list-group overflow-y-scroll" style={{ maxHeight: '160px', overflowY: 'auto' }}>
                                      {!filteredEncounterOptions.length == 0 ? filteredEncounterOptions.map((item, index) => (
                                        <li
                                          key={index}
                                          className="list-group-item d-flex justify-content-between"
                                          onClick={() => {
                                            handleEncounterSelect(item); setSaved(false);
                                            setInitialConditionPresent(false);
                                            setErrorMessage({});
                                          }}
                                        >
                                          <span>{item}</span>
                                          {selectedEncounterOptions.some(option => option === item) && <span>✔</span>}
                                        </li>
                                      )) : <li
                                        className="list-group-item d-flex justify-content-between"
                                      >
                                        <span>No such encounter types found</span>

                                      </li>}

                                    </ul>
                                  </div>
                                )}
                                {selectedEncounterOptions.length != 0 && showSelectedEncounterOptions && <div className="encounter-selected-options-container position-absolute" style={{ top: '100%', width: '100%', zIndex: 110 }}>
                                  {selectedEncounterOptions.map((item, index) => (
                                    <div key={index} className="encounter-selected-option">
                                      <span>{item}</span>
                                      <button onClick={() => {
                                        handleEncounterSelect(item); setSaved(false);
                                        setInitialConditionPresent(false);
                                      }}
                                        className="encounter-remove-button">×</button>
                                    </div>
                                  ))}
                                </div>}
                              </div>
                            )}
                            {/* {condition === "fill_limit" && (
                              <div className="d-flex align-items-center">
                                <select
                                  className="form-control"
                                  value={fill_limitValue}
                                  style={{ maxWidth: "200px" }}
                                  onChange={(e) => {
                                    setFill_limitValue(e.target.value);
                                    setSaved(false);
                                    setInitialConditionPresent(false);
                                  }}
                                >
                                  <option value="">Select limit</option>
                                  
                                  <option value="once">Once</option>
                                  <option value="many">
                                    Many
                                  </option>
                                </select>
                              </div>
                            )} */}
                            <div className="col-sm-5 d-none d-sm-flex mx-2">
                              {delLoader.condition === condition.value &&
                                delLoader.value ? (
                                <Spinner size="sm" />
                              ) : (
                                <FontAwesomeIcon
                                  icon={faTrash}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    handleDeleteConditionClick(condition)
                                  }
                                />
                              )}
                            </div>
                          </div>

                        </li>
                        {condition.value === errorMessage.condition && <li className="d-flex flex-column flex-md-row align-items-start align-items-md-center pb-2" >
                          <div className="col-md-3 col-12 mb-2 mb-md-0 d-flex justify-content-between align-items-center"></div>
                          {condition.value === errorMessage.condition && (
                            <div
                              className="text-left "
                              style={{
                                color: "red",
                                fontSize: "12px",
                                fontWeight: "500",
                              }}
                            >
                              {errorMessage.msg}
                            </div>
                          )}</li>}
                      </>
                    ))}
                  </ul>

                  <div className="d-flex flex-sm-row flex-column  justify-content-between">
                    <div>
                      {selectedConditions.length > 0 && (
                        <>
                          {saved && (
                            <div
                              className="text-left mt-3"
                              style={{ color: "green", fontSize: "13px" }}
                            >
                              *All Conditions have been saved successfully
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {allConditionsFilled() && (
                      <button
                        className="d-flex justify-content-center align-items-center "
                        style={{
                          color: "#004879",
                          backgroundColor: "#ffff",
                          fontSize: "smaller",
                          padding: "3px 8px",
                          fontWeight: "700",
                          borderRadius: "10px",
                          border: "0.5px solid #004879",
                          cursor: "pointer",
                          maxHeight: "35px",
                          minHeight: "35px",
                          width: "100px",
                        }}
                        onClick={handleSaveConditions}
                      >
                        {loader ? <Spinner size="sm" /> : "Save"}
                      </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
          {showMetadata && <PrefillInstrumentConfig projectId={props.projectId} instrumentData={props.instrumentData} />}
        </div>
      )}
    </div>
  );
};