import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import PatientData from "./PatientData";

const PatientList = (props) => {
    const handleInputChange = (e) => {
        props.setFormData({...props.formData, [e.target.id]:e.target.value});
    }
    return(
        <div className="m-3 finlist">
            { (props.patientsList && props.patientsList?.length !== 0) && 
            <>
                <div className="row d-none d-lg-block">
                    <div className="col-lg-12 col-md-12 col-sm-12 prow">
                        <div className="row justify-content-center align-item-center p-3 patientDataTop">
                            <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                                <span className="patientsinfoTop"> NAME </span>
                            </div>
                            <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                                <span className="patientsinfoTop">MRN</span>
                            </div>
                            <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                                <span className="patientsinfoTop">DOB</span>
                            </div>
                            <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                                <span className="patientsinfoTop">AGE</span>
                            </div>
                            <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                                <span className="patientsinfoTop"></span>
                            </div>
                        </div>
                    </div>
                </div>
                { props.patientsList.map((patientData, i) => {
                    return (<PatientData patientData={patientData} forms={props.forms}/>);
                })}
            </>
            }
        </div>
    );
}
export default PatientList