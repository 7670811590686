import axios from "axios";
import secrets from '../../env.json';

const getRegistrationFormPriorityCount = ( accessToken) => {
  const apiUrl =
    `${secrets.REACT_APP_BASEURL}` + `/RedcapAdmin/getRegistrationFormPriorityCount`;
  // Set up Axios with the access token in the headers
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    
    
  };
  return axios
    .get(apiUrl, axiosConfig)
    .then((response) => {
      // Handle the response data here
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      // Handle any errors that occurred during the API call
      return Promise.reject(error);
    });
};
export default getRegistrationFormPriorityCount;
