import React from "react";
import "./Adminpage.css";
// import { PiUsersThreeFill } from "react-icons/pi";
// import { PiArrowsCounterClockwiseBold } from "react-icons/pi";
// import { FaArrowCircleRight } from "react-icons/fa";
import { faUsers,faArrowsRotate,faArrowRight,faGear, faClockRotateLeft,faChartSimple } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Navbar = (props) => {
  const{screenType, setScreenType} = props;
  return (
    <div>
      <nav class=" navbar navbar-expand-lg bg-body-primary d-lg-none">
        <div class="container-fluid">
          <button
            class="btn"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
            style={{backgroundColor:"#004879"}}
          >
            <FontAwesomeIcon icon={faArrowRight} color="#fff"/>
          </button>

          <div
            class="offcanvas offcanvas-start desktop-sidebar"
            tabindex="-1"
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
            style={{ width: "20rem" }}
          >
            <div class="offcanvas-body">
              <ul className="nav nav-pills flex-column mt-4 gap-3">
                <li className="nav-item " onClick={() => setScreenType("userRights")}>
                  <a href="#" className="nav-link additionalStyle fs-5 flex gap-2" data-bs-target="#offcanvasExample" data-bs-toggle="offcanvas" style={{backgroundColor:screenType==="userRights"?"#61616119":""}}>
                    {/* <PiUsersThreeFill /> */}
                    <FontAwesomeIcon icon={faUsers} />
                    &nbsp;
                    User Info
                  </a>
                </li>
                <li className="nav-item " onClick={() => setScreenType("resubmitSurveys")}>
                  <a href="#" className="nav-link additionalStyle fs-5 flex gap-2" data-bs-target="#offcanvasExample" data-bs-toggle="offcanvas" style={{backgroundColor:screenType==="resubmitSurveys"?"#61616119":""}}>
                    {/* <PiArrowsCounterClockwiseBold /> */}
                    <FontAwesomeIcon icon={faArrowsRotate} />
                    &nbsp;
                    Resubmit form
                  </a>
                </li>
                <li className="nav-item " onClick={() => setScreenType("surveyManager")}>
                  <a href="#" className="nav-link additionalStyle fs-5 flex gap-2" data-bs-target="#offcanvasExample" data-bs-toggle="offcanvas" style={{backgroundColor:screenType==="surveyManager"?"#61616119":""}}>
                    {/* <PiArrowsCounterClockwiseBold /> */}
                    <FontAwesomeIcon icon={faGear} />
                    &nbsp;
                    Survey Manager
                  </a>
                </li>
                <li className="nav-item " onClick={() => setScreenType("surveyHistory")}>
                  <a href="#" className="nav-link additionalStyle fs-5 flex gap-2" data-bs-target="#offcanvasExample" data-bs-toggle="offcanvas" style={{backgroundColor:screenType==="surveyHistory"?"#61616119":""}}>
                    {/* <PiArrowsCounterClockwiseBold /> */}
                    <FontAwesomeIcon icon={faClockRotateLeft} />
                    &nbsp;
                    Survey History
                  </a>
                </li>
                <li className="nav-item " onClick={() => setScreenType("surveyStatus")}>
                  <a href="#" className="nav-link additionalStyle fs-5 flex gap-2" data-bs-target="#offcanvasExample" data-bs-toggle="offcanvas" style={{backgroundColor:screenType==="surveyStatus"?"#61616119":""}}>
                    {/* <PiArrowsCounterClockwiseBold /> */}
                    <FontAwesomeIcon icon={faChartSimple} />
                    &nbsp;
                    Survey Stats
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
