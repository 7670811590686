import React, { useState, useEffect } from "react";
import SurveyHistoryData from "./SurveyHistoryData";

function HistoryList(props) {
    return (
        <div className="w-100 d-flex justify-content-lg-center" style={{ backgroundColor: "#e9ecef", paddingBottom:"20px" }}>
            <div style={{ padding: "0px", width: "90%", backgroundColor: "#fff", margin: "auto" }}>
                {props.surveys.length == 0 ?
                    <div className="row m-0 d-flex justify-content-center p-3 surveysErrorBanner">
                        <div
                        className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center"
                        style={{ color: "black" }}
                        >
                        <span className="patientsinfoTop">No Encounters Found</span>
                        </div>
                    </div>
                :
                <>
                    {props.surveys?.map((survey, index) => (
                        <SurveyHistoryData survey={survey} forms={props.forms}/>
                    ))}
                </>}
            </div>
        </div>
    );
}

export default HistoryList;
