import React, { useState, useEffect } from "react";
// import "./patientsList.css";
import "react-bootstrap-accordion/dist/index.css";
import SurveyHistoryPatientsData from "./SurveyHistoryPatientsData";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import PatientsData from "./PatientsListData/patientsData";
function SurveyHistoryPatientsList(props) {

  useEffect(() => {
    // setpatientsSearchData(props.List);
  }, [props.List]);

//   const onReset = () => {
//     setpatientsSearchData(undefined);
//   }

      

  return (
    <div className="m-3 finlist">
      
      {/* <div className="row justify-content-end">
        <div className="col-lg-12 col-md-12 col-sm-12">
          {(props.startDateTime!="" &&  props.endDateTime!="") && 
          <>
            <div className="reset">
            <div style={{color:"#004879", fontWeight:"600"}}>Date:&nbsp;</div>
              <span  style={{cursor:"pointer"}}> {props.startDateTime} -:- {props.endDateTime}&nbsp;<FontAwesomeIcon icon={faPenToSquare} /></span>
            </div>
          </>
          }
        </div>
      </div> */}
      {/* {props.loader ? 
      <span style={{color:"#69a3dc"}}>Searching...</span>
      :
      <>
        {!patientsSearchData && 
          <span style={{color:"#69a3dc"}}>Please enter the patient's FIN, MRN, Name or DOB</span>
        }
        {patientsSearchData?.length === 0 &&
         <span style={{color:"red"}}>Patient not found. Please Check your Inputs .</span>
        }
      </>} */}
      {/* { (patientsSearchData && patientsSearchData?.length !== 0) &&  */}
        <div className="row d-none d-xl-block">
          <div className="col-lg-12 col-md-12 col-sm-12 prow">
            <div className="row justify-content-center align-item-center p-3 patientDataTop">
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop"> NAME </span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop">MRN</span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop">DOB</span>
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop">AGE</span>
              </div>
              <div className="col-lg-1 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop"></span>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                <span className="patientsinfoTop"></span>
              </div>
            </div>
          </div>
        </div>
      {/* } */}
      {props.surveyHistoryData && (
        props.surveyHistoryData.map((data, i) => {
          return <SurveyHistoryPatientsData surveyHistoryData={data}/>;
        })
      )}
    </div>
  );
}

export default SurveyHistoryPatientsList;