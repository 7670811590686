import React, { useState, useEffect } from "react";

import { Accordion } from "react-bootstrap-accordion";
import "react-bootstrap-accordion/dist/index.css";

import {
    useHistory,
} from "react-router-dom";



import { Spinner } from "react-bootstrap";
import PatientSurveyHistory from "./PatientSurveyHistory";
import SurveyHistoryPatientsList from "./SurveyHistoryPatientList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faShareSquare } from "@fortawesome/free-solid-svg-icons";



function SurveyHistoryInstanceData(props) {

    const [open, setOpen] = useState(false);
    const [loader, setloader] = useState(false);




    return (
        <>

            <div className="col-lg-12 col-md-12 col-sm-12">

                <div className="row d-flex justify-content-center align-item-center p-3 patientData">

                    <div className="col-xl-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                        <span className="d-xl-none patientsinfoTop" style={{ color: "black" }}>
                            Email Used:&nbsp;
                        </span>
                        <span className="patientsinfo apEmail">{props.surveyHistoryData.sentEmailUcRedcapSurveyHistory==""?"--":props.surveyHistoryData.sentEmailUcRedcapSurveyHistory}</span>
                    </div>
                    <div className="col-xl-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                        <span className="d-xl-none patientsinfoTop" style={{ color: "black" }}>
                            Phone No. Used:&nbsp;
                        </span>
                        <span className="patientsinfo">{props.surveyHistoryData.phoneNumberUcRedcapSurveyHistory==""?"--":props.surveyHistoryData.phoneNumberUcRedcapSurveyHistory}</span>
                    </div>
                    <div className="col-xl-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                        <span className="d-xl-none patientsinfoTop" style={{ color: "black" }}>
                            Updated Date:&nbsp;
                        </span>
                        <span className="patientsinfo">{props.surveyHistoryInstanceData.dateModifiedDtTmUcRedcapSurveyHistory}</span>
                    </div>
                    <div className="col-xl-3 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                        <span className="d-xl-none patientsinfoTop" style={{ color: "black" }}>
                            Status:&nbsp;
                        </span>  {props.surveyHistoryInstanceData.statusUcRedcapSurveyHistory !==
                            "Completed" ? (
                            <FontAwesomeIcon
                                icon={faShareSquare}
                                style={{ fontSize: "18px", color: "#FF7F00" }}
                            />
                        ) : (
                            <FontAwesomeIcon
                                icon={faCheckCircle}
                                style={{ fontSize: "20px", color: "green" }}
                            />
                        )}
                        <span className="patientsinfo">
                            {props.surveyHistoryInstanceData.statusUcRedcapSurveyHistory}

                        </span>
                    </div>

                </div>
            </div>
        </>
    );
}

export default SurveyHistoryInstanceData;