import React, { useState, useEffect } from "react";
import "../../patientsList.css";
import { Accordion } from "react-bootstrap-accordion";
import "react-bootstrap-accordion/dist/index.css";
import { useAuth } from "../../AppContextProvider";
import {
    BrowserRouter as Router,
    Route,
    useHistory,
    Switch,
} from "react-router-dom";
import SurveyHistoryInstanceList from "./SurveyHistoryInstanceList";
import surveyHistoryByInstance from "../../Api/surveyHistoryByInstance";


function PatientSurveyHistoryData(props) {

    const [open, setOpen] = useState(false);
    const [loader, setloader] = useState(false);
    const [surveyHistoryInstanceData, setSurveyHistoryInstanceData] = useState();
    const history = useHistory();
    const { accessToken } = useAuth();

    function removeUnderscores(inputString) {
        return inputString.replace(/_/g, ' ').toUpperCase();
    }

    const getDates = (data) => {
        if (data === undefined || data === "") {
            return {
                date: "--",
                age: "",
                dateFormat: data
            };
        }

        const birthMonth = data.slice(0, 2);
        const birthDay = data.slice(2, 4);
        const birthYear = data.slice(4, 8);

        const today = new Date();
        const birthDate = new Date(`${birthYear}-${birthMonth}-${birthDay}`);

        const ageInMilliseconds = today - birthDate;
        const ageInYears = Math.floor(ageInMilliseconds / 31536000000); // Approximate milliseconds in a year

        return {
            date: `${birthMonth}/${birthDay}/${birthYear}`,
            age: ageInYears + " yrs",
            dateFormat: data, // Added for "mmddyyyy" format
        };
    };

    function handelClick() {
        let Open=!open
        setOpen(!open)
        if (Open) {
            setloader(true)
            const data = {
                recordId: props.surveyHistoryData.redcapPersonIdUcRedcapSurveyHistory,
                instanceId: props.surveyHistoryData.redcapRepeatInstanceUcRedcapSurveyHistory,
                surveyId: props.surveyHistoryData.surveyIdUcRedcapSurveyHistory
            }
            surveyHistoryByInstance(data, accessToken).then((response) => {
                setSurveyHistoryInstanceData(response)
                setloader(false)
            })
                .catch((err) => {
                    console.log(err);
             
                      setloader(false)
                })
        }
    }
    return (
        <>
           
            <div className="row m-0 d-flex justify-content-center align-item-center p-3  patientFIN ">
                <div
                    className="col-xl-4 col-12 d-flex justify-content-left"
                    style={{ color: "black" }}
                >
                    <span className="d-xl-none" style={{ minWidth: "115px" }}>Survey Name:&nbsp;</span>
                    <span className="patientsinfo  apEmail">{removeUnderscores(props.surveyHistoryData.instrumentNameUcRedcapSurveyHistory)}</span>
                </div>
                <div
                    className="col-xl-2 col-md-12 col-sm-12 d-flex justify-content-left"
                    style={{ color: "black" }}
                >
                    <span className="d-xl-none">Sent Date:&nbsp;</span>
                    <span className="patientsinfo ">
                        {props.surveyHistoryData.sentDtTmUcRedcapSurveyHistory}
                    </span>
                </div>
                <div
                    className="col-xl-2 col-md-12 col-sm-12 d-flex justify-content-left"
                    style={{ color: "black" }}
                >
                    <span className="d-xl-none">Status:&nbsp;</span>
                    <span className="patientsinfo apEmail">
                        {props.surveyHistoryData.statusUcRedcapSurveyHistory}
                    </span>
                </div>

                <div
                    className="col-xl-2 col-md-12 col-sm-12 d-flex justify-content-left"
                    style={{ color: "black" }}
                >
                    <span className="d-xl-none " style={{ minWidth: "70px" }}>Sent By:&nbsp;</span>
                    <span className="patientsinfo  apEmail">
                        {props.surveyHistoryData.loggedInUserUcRedcapSurveyHistory}
                    </span>
                </div>
                {/* <div
                    className="col-xl-2 col-md-12 col-sm-12 d-flex justify-content-end historyText"
                    
                >
                    <button className=" noFin" onClick={() => handelClick()}>View Details{open ? "▼" : "▲"}</button>

                </div> */}
                <div class="col-xl-2 col-md-12 col-sm-12 d-flex justify-content-end historyText" onClick={handelClick} style={{ cursor: "pointer" }}><div> Surveys </div> <div class=" p-0 ">{open ? "▼" : "▲"}</div></div>
                {open ? (
                    <div className="row " >
                        {" "}
                        <div className="encounterTable " style={{ width: "80" }}>
                             <SurveyHistoryInstanceList surveyHistoryData={props.surveyHistoryData} surveyHistoryInstanceData={surveyHistoryInstanceData} loader={loader}/>
                        </div>

                    </div>
                ) : (
                    <></>
                )}

            </div>
        </>
    );
}

export default PatientSurveyHistoryData;