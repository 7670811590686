import React, { useState, useEffect } from "react";
import "./VisitList.css";
import getClinic from "../Api/getClinic";
import Visitstatus from "../Api/visitStatus";
import SurveyStatus from "../Api/surveyStatus"
import { useAuth } from "../AppContextProvider";
import getResources from "../Api/getResources";
import getApppointmentTypes from "../Api/getAppointmentType";
import { Spinner } from "react-bootstrap";

const VisitList = (props) => {
  const {
    clinicNames,
    setSearchParams,
    searchParams,
    searchAppointments,
    loader,
    isclinicSelected,
    setIsclinicSelected,
    showSchedule,
    showConfirmationScreen,
    showStatus,
    setStatus,
    forms
  } = props;
  const [visitStatus, setVisitStatus] = useState(Visitstatus);
  const [resources, setResources] = useState([]);
  const [apptTypes, setApptTypes] = useState([]);
  // const [status,setStatus] = useState(searchParams.apptStatusId);
  const { isAuthorized, accessToken, userInfo } = useAuth();
  const [clinicName, setClinicName] = useState("");
  const [nameOrMrn, setnameOrMrn] = useState("");
  const [surveySearchParam, setSurveySearchParam] = useState({
    loggedInUsername: userInfo?.email,
    instrumentName: "",
    status: "",
    nameOrMrn : ""
  })

  const handleClinicChange = (event) => {
    if (searchParams.apptStatusId === "0") {
      setSearchParams({
        ...searchParams,
        clinicId: event.target.value,
        resourceId: "0",
        apptTypeId: "0",
        apptStatusId: "4538",
      });
    }
     else {
      setSearchParams({ 
        ...searchParams,
        clinicId: event.target.value, 
        resourceId: "0", 
        apptTypeId: "0" 
      });
    }
    setIsclinicSelected(true);
    setClinicName(event.target.value);
    getResources(event.target.value, accessToken)
      .then((response) => {
        setResources(response);
      })
      .catch((err) => {});
    getApppointmentTypes(event.target.value, accessToken)
      .then((response) => {
        setApptTypes(response);
      })
      .catch((err) => {});
  };

  const handleResourceChange = (event) => {
    setSearchParams({ ...searchParams, resourceId: event.target.value });
  };

  const handleApptTypeChange = (event) => {
    setSearchParams({ ...searchParams, apptTypeId: event.target.value });
  };

  const handleApptStatusChange = (event) => {
    setSearchParams({ ...searchParams, apptStatusId: event.target.value });
  };

  const handleNameOrMrnChange = (event) => {
    setSurveySearchParam({ ...surveySearchParam, nameOrMrn: event.target.value });
  }

  const handleFormsChange= (event)=>{
    setSurveySearchParam({...surveySearchParam, instrumentName: event.target.value})
  };

  const handleSurveyStatusChange = (event) => {
    setSurveySearchParam({...surveySearchParam, status: event.target.value})
  }

  return (
    <div className="tabData" id="patientlistdata">
      <div id="patient_information">
        <div className="adminPageTitle container-fluid">Visits Information</div>
        {(!showSchedule && !showConfirmationScreen) && (
        <nav>
          <div className="nav nav-tabs" role="tablist">
            <button
              className="nav-link active textStyle"
              id="nav-profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-profile"
              type="button"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="true"
            >
              Filters
            </button>
          </div>
          <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane"
                  id="nav-home"
                  role="tabpanel"
                  aria-labelledby="nav-home-tab"
                >
                  <div className="row">
                    <div className="col-lg-3 col-sm-12">
                      <form>
                        <select
                          id="fruits"
                          name="fruits"
                          multiple="multiple"
                        ></select>
                      </form>
                    </div>

                    <div className="col-lg-3 col-sm-12">
                      <button
                        className="searchButton d-flex justify-content-around"
                        id="inpatientSearchButton"
                        onClick={searchAppointments}
                        disabled={!isclinicSelected}
                      >
                        <div>Search</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade show active"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  {showStatus ? (
                    <div
                      id="visitInputs"
                      className="piInputs container-fluid justify-content-center justify-content-sm-center justify-content-md-center"
                    >
                      <div className="row d-flex ">
                        <div className="custom-lg-4 col-md-12 col-sm-12 d-flex justify-content-center justify-content-sm-start mb-2">
                          <div
                            id="AppointmentResource"
                            style={{
                              width: "100%",
                            }}
                          >
                            <select
                              className="finInput"
                              id="currentAppointment"
                              onChange={handleFormsChange}
                            >
                              <option value="">Select Your Form</option>
                              {forms.map((form) => (
                                <option
                                  style={{ border: "none", width: "100%" }}
                                  value={form.instrumentName}
                                >
                                  {form.instrumentLabel}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-12 col-sm-12 d-flex  justify-content-center justify-content-sm-start mb-2">
                          <div
                            id="AppointmentResource"
                            style={{
                              width: "100%",
                            }}
                          >
                            <input
                              className="finInput"
                              placeholder="Search by Name or MRN"
                              style={{border: "1px solid #ced4da", width: "100%" }}
                              type="text"
                              id="nameOrMrn"
                              autoComplete="off"
                              onChange={handleNameOrMrnChange}
                              value={surveySearchParam.nameOrMrn}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-12 col-sm-12 d-flex  justify-content-center justify-content-sm-start mb-2">
                          <div
                            id="AppointmentResource"
                            style={{
                              width: "100%",
                            }}
                          >
                            <select
                              className="finInput"
                              id="currentAppointment"
                              onChange={handleSurveyStatusChange}
                              value={surveySearchParam.status}
                            >
                              <option value="">Select Status</option>
                              {SurveyStatus.map((clinic, index) => (
                                <option
                                  key={index}
                                  style={{ border: "none", width: "100%" }}
                                  value={clinic.key}
                                >
                                  {clinic.value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div
                          className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-center"
                          style={{ alignSelf: "center" }}
                        >
                          <div className="col w-100 m-0 p-0 d-flex justify-content-center">
                            <div className="col-sm-4 col-md-4 col-lg-12 m-0 p-0 d-flex justify-content-center">
                              <button
                                className="searchButton d-flex justify-content-around"
                                id="visitSearchButton"
                                onClick={() => setStatus(surveySearchParam)}
                              >
                                <div>{loader ? <Spinner /> : "Search"}</div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div
                      id="visitInputs"
                      className="piInputs container-fluid justify-content-center justify-content-sm-center justify-content-md-center"
                    >
                      <div className="row d-flex ">
                        <div className="custom-lg-3  col-md-12 col-sm-12 d-flex  justify-content-center justify-content-sm-start mb-2">
                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <select
                              className="finInput"
                              id="currentAppointment"
                              value={clinicName}
                              onChange={handleClinicChange}
                            >
                              <option value="0">Select Your Clinic</option>
                              {clinicNames.length !== 0 &&
                                clinicNames.map((Clinic, index) => (
                                  <option
                                    key={index}
                                    style={{ border: "none", width: "100%" }}
                                    value={Clinic.id}
                                  >
                                    {Clinic.name}
                                  </option>
                                ))}
                            </select>
                          </div>
                        </div>
                        <div className="custom-lg-3  col-md-12 col-sm-12 d-flex justify-content-center justify-content-sm-start mb-2">
                          <div
                            id="AppointmentResource"
                            style={{
                              width: "100%",
                            }}
                          >
                            <select
                              className="finInput"
                              id="currentAppointment"
                              onChange={handleResourceChange}
                              disabled={!isclinicSelected}
                              value={searchParams.resourceId}
                            >
                              <option value="0">Select Resource</option>
                              {resources.map((Resources, index) => (
                                <option
                                  key={index}
                                  style={{ border: "none", width: "100%" }}
                                  value={Resources.id}
                                >
                                  {Resources.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="custom-lg-3  col-md-12 col-sm-12 d-flex  justify-content-center justify-content-sm-start mb-2">
                          <div
                            id="AppointmentResource"
                            style={{
                              width: "100%",
                            }}
                          >
                            <select
                              className="finInput"
                              id="currentAppointment"
                              onChange={handleApptTypeChange}
                              disabled={!isclinicSelected}
                              value={searchParams.apptTypeId}
                            >
                              <option value="0">Select Appointment Type</option>
                              {apptTypes.map((ApptTypes, index) => (
                                <option
                                  key={index}
                                  style={{ border: "none", width: "100%" }}
                                  value={ApptTypes.id}
                                >
                                  {ApptTypes.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-lg-2 col-md-12 col-sm-12 d-flex  justify-content-center justify-content-sm-start mb-2">
                          <div
                            id="AppointmentResource"
                            style={{
                              width: "100%",
                            }}
                          >
                            <select
                              className="finInput"
                              id="currentAppointment"
                              onChange={handleApptStatusChange}
                              value={searchParams.apptStatusId}
                              disabled={!isclinicSelected}
                            >
                              <option value="">Select Status</option>
                              {visitStatus.map((clinic, index) => (
                                <option
                                  key={index}
                                  style={{ border: "none", width: "100%" }}
                                  value={clinic.key}
                                >
                                  {clinic.value}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div
                          className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-center"
                          style={{ alignSelf: "center" }}
                        >
                          <div className="col w-100 m-0 p-0 d-flex justify-content-center">
                            <div className="col-sm-4 col-md-4 col-lg-12 m-0 p-0 d-flex justify-content-center">
                              <button
                                className="searchButton d-flex justify-content-around"
                                id="visitSearchButton"
                                onClick={searchAppointments}
                                disabled={!isclinicSelected}
                              >
                                <div>{loader ? <Spinner /> : "Search"}</div>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
          </div>
        </nav>)}
      </div>
    </div>
  );
};

export default VisitList;
