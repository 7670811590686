import axios from "axios";
import secrets from '../../env.json';

const getUserRights = (accessToken) => {
  const apiUrl =
    `${secrets.REACT_APP_BASEURL}` + `/RedcapAdmin/userInfo`;
  // Set up Axios with the access token in the headers
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios
    .get(apiUrl, axiosConfig)
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      return Promise.reject(error);
    });
};
export default getUserRights;
