import React, { useState, useEffect } from "react";
import PatientSurveyHistoryData from "./PatientSurveyHistoryData";



function PatientSurveyHistory(props) {
  //   const [patientData, setpatientData] = useState(props.patientData);



  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <div className="row d-flex justify-content-center finlistbody m-2 ">
          <div className="col-lg-12 col-md-12 col-sm-12 p-0">
            <div className=" d-none d-xl-block">
              <div className="row m-0 d-flex justify-content-center p-3 FinDataTop">
                <div className="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-left">
                  <span className="patientsinfoTop">Survey Name</span>
                </div>
                <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left">
                  <span className="patientsinfoTop" style={{ cursor: "pointer" }}>
                    Sent Date
                  </span>
                </div>
                <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left">
                  <span className="patientsinfoTop" style={{ cursor: "pointer" }}>
                    Status
                  </span>
                </div>
                <div className="col-lg-3 col-md-4 col-sm-4 d-flex justify-content-left">
                  <span className="patientsinfoTop Encountertype text-truncate" style={{ whiteSpace: "normal" }}>
                    Sent By
                  </span>
                </div>
                <div className="col-lg-1 col-md-4 col-sm-4 d-flex justify-content-left">
                  <span className="patientsinfoTop Encountertype text-truncate" style={{ whiteSpace: "normal" }}>

                  </span>
                </div>
              </div>
            </div>
          </div>
          

            {props.surveyHistoryData && (
              props.surveyHistoryData.map((data, i) => {
                return <PatientSurveyHistoryData surveyHistoryData={data} />;
              })
            )}
         
        </div>
      </div>

    </>
  );
}

export default PatientSurveyHistory;