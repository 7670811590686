import React, { useState, useEffect } from "react";

import { Accordion } from "react-bootstrap-accordion";
import "react-bootstrap-accordion/dist/index.css";

import {
    useHistory,
} from "react-router-dom";



import { Spinner } from "react-bootstrap";
import PatientSurveyHistory from "./PatientSurveyHistory";
import SurveyHistoryPatientsList from "./SurveyHistoryPatientList";



function SurveyHistoryPatientsData(props) {
    const [patientData, setpatientData] = useState(props.surveyHistoryData.patientData);
    
    const [open, setOpen] = useState(false);
    const [loader, setloader] = useState(false);




    useEffect(() => {
        setpatientData(props.patientData);
    }, [props.patientData]);
    const getDates = (data) => {
        if (data === undefined) {
            return { date: "", age: "" };
        }
        
        const birthYear = data.slice(0, 4);
        const birthMonth = data.slice(4,6);
        const birthDay = data.slice(6,8);

        const today = new Date();
        const birthDate = new Date(`${birthYear}-${birthMonth}-${birthDay}`);

        const ageInMilliseconds = today - birthDate;
        const ageInYears = Math.floor(ageInMilliseconds / 31536000000); // Approximate milliseconds in a year

        return {
            date: `${birthMonth}/${birthDay}/${birthYear}`,
            age: ageInYears + " yrs",
            dateFormat: data, // Added for "mmddyyyy" format
        };
    };

    return (
        <>
            {false ?
                <div id="overlay">
                    <div id="loading" class="spinner">
                        <div class="bounce1"></div>
                        <div class="bounce2"></div>
                        <div class="bounce3"></div>
                    </div>
                </div> : <></>}
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row d-flex justify-content-center align-item-center p-3 patientData">
                    <div className="col-xl-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                        <span className="d-xl-none patientsinfoTop" style={{ color: "black" }}>
                            Name:&nbsp;
                        </span>
                        <span className="patientsinfo apEmail">{props.surveyHistoryData.patientData.lastNameUcRedcapPatient}, {props.surveyHistoryData.patientData.firstNameUcRedcapPatient}</span>
                    </div>
                    <div className="col-xl-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                        <span className="d-xl-none patientsinfoTop" style={{ color: "black" }}>
                            MRN:&nbsp;
                        </span>
                        <span className="patientsinfo">{props.surveyHistoryData.patientData.mrnUcRedcapPatient}</span>
                    </div>
                    <div className="col-xl-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                        <span className="d-xl-none patientsinfoTop" style={{ color: "black" }}>
                            DOB:&nbsp;
                        </span>
                        <span className="patientsinfo">
                            {getDates(props.surveyHistoryData.patientData.dobUcRedcapPatient).date}
                        </span>
                    </div>
                    <div className="col-xl-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                        <span className="d-xl-none patientsinfoTop" style={{ color: "black" }}>
                            Age:&nbsp;
                        </span>
                        <span className="patientsinfo">
                            {getDates(props.surveyHistoryData.patientData.dobUcRedcapPatient).age}
                        </span>
                    </div>

                    <div className="col-xl-1 col-md-4 col-sm-4 d-flex justify-content-center text-center">

                    </div>
                    <div className="col-xl-3 col-12 d-flex justify-content-end text-center ">
                        <button className="selectvisits noFin" onClick={()=>setOpen(!open)}>View History {open ? "▼" : "▲"}</button>
                    </div>
                </div>
                {open ? (
                    <div className="row Accordians" >
                        {" "}
                        <div className="encounterTable d-flex justify-content-center" style={{width:"80"}}>
                      <PatientSurveyHistory  surveyHistoryData={props.surveyHistoryData.surveyHistories}  />
                    </div>

                    </div>
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}

export default SurveyHistoryPatientsData;