import axios from "axios";
import React from "react";
import secrets from '../../env.json';
const searchUserInfo = (accessToken,searchParam) => {
  const apiUrl = 
  `${secrets.REACT_APP_BASEURL}`+`/RedcapAdmin/getUser`;
  // Set up Axios with the access token in the headers
  
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    params:{
        param:searchParam
    }
  };
  // Make a GET request with the Okta bearer token in the headers
  return axios
    .get(apiUrl, axiosConfig)
    .then((response) => {
      // Handle the response data here
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      // Handle any errors that occurred during the API call
      return Promise.reject(error);
    });
};
export default searchUserInfo;