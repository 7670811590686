import React, { useState } from "react";
import searchSurveyByName from "../../Api/searchSurveyByName";
import { Spinner } from "react-bootstrap";
import getSurveyConfig from "../../Api/getSurveyConfig";
import { useAuth } from "../../AppContextProvider";
import addClinicSurvey from "../../Api/addClinicSurvey";
import "../Adminpage.css";

const SearchSurvey = (props) => {
  const [searchInput, setSearchInput] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [surveyData, setSurveyData] = useState([]);
  const [alreadyExist, setAlreadyExist] = useState(false);
  const [loader, setLoader] = useState(false);
  const [suggestionLoader, setSuggestionLoader] = useState(false);
  const [instrumentSelectLoader, setInstrumentSelectLoader] = useState(false);
  const { accessToken } = useAuth();
  const [confirmLoader, setConfirmLoader] = useState(false);

  const getSurvey = (searchValue) => {
    setSuggestionLoader(true);
    searchSurveyByName(searchValue, accessToken)
      .then((response) => {
        
        setSuggestions(response.length > 0 ? response : [{ title: "No suggestion" }]);
        setSuggestionLoader(false);
      })
      .catch((err) => {
        setSuggestionLoader(false);
        console.log(err);
      });
  };

  const getSurveyData = async (clickedsuggestion) => {
    

    let alreadyExistFlag = false;
     let surveyId = clickedsuggestion.surveyId;
        props.clinicSurvey.forEach((data) => {
          if (data.pid ===clickedsuggestion.projectId) {
            
            for (let a in data.instruments) {
              
              if (data.instruments[a].surveyId === clickedsuggestion.surveyId) {
                setAlreadyExist(true);
                alreadyExistFlag = true;
                setSurveyData([]);
                setInstrumentSelectLoader(false);
                break;
              }
            }
          }
        });
        if (!alreadyExistFlag) {
          getSurveyConfig(surveyId, accessToken)
            .then((configResponse) => {
              let obj = {};
              configResponse.forEach((x) => {
                obj = { ...obj, [x.parameter]: x.value };
              });
              var temp = [];
              temp[0] = { ...clickedsuggestion, ...obj };
              setSurveyData(temp);
              setInstrumentSelectLoader(false);
            })
            .catch((err) => {
              console.log(err);
              setInstrumentSelectLoader(false);
            });
        }
       
  };

  const handleSearch = (e) => {
    setSearchInput( e.target.value);
    if(e.target.value.length>=3)
    {setAlreadyExist(false);
    setLoader(true);
    const value = e.target.value.toLowerCase();

    if (value.trim() === "") {
      setSuggestions([]);
      setLoader(false);
    } else {
      getSurvey(value.trim());
      setLoader(false);
    }}
  };

  const handleSuggestionClick = (suggestion) => {
    if (suggestion !== "No suggestion") {
      
      setInstrumentSelectLoader(true);
      getSurveyData(suggestion);
      setSuggestions([]);
    }
  };

  const handleSubmit = async () => {
    setConfirmLoader(true);
    
    const data = {
      clinicId: parseInt(props.selectedClinic.id),
      clinicName: props.selectedClinic.name,
      projectId: parseInt(surveyData[0].projectId),
      projectName: surveyData[0].projectName,
      surveyId: parseInt(surveyData[0].surveyId),
      instrumentName: surveyData[0].instrumentName,
      title: surveyData[0].title,
    };

    addClinicSurvey(data, accessToken)
      .then((response) => {
        
        props.setShowAddSurvey();
        setConfirmLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setConfirmLoader(false);
      });
  };


  return (
    <div id="overlay2">
      <div
        id="contactPopup"
        style={{ borderRadius: "10px", backgroundColor: "#e9ecef", zIndex: 100 }}
        className="position-relative"
      >
        <div className="row">
          <div className="col-12 justify-content-center align-items-center position-relative">
            <label style={{ width: "100%", textAlign: "left", marginTop: "0.5rem" }}>
              Search Survey
            </label>
            <div className="position-relative">
              <input
                type="text"
                className="form-control search-icon"
                placeholder="Search"
                value={searchInput}
                onChange={handleSearch}
              />
              {loader && (
                <ul
                  className="list-group position-absolute w-100 overflow-y-scroll"
                  style={{ top: "100%", maxHeight: "150px", zIndex: 110 }}
                >
                  <li className="list-group-item" style={{ color: "#004879" }}>
                    <Spinner />
                  </li>
                </ul>
              )}
              {suggestions.length > 0 && (
                <ul
                  className="list-group hover-suggestion position-absolute w-100 mt-1 overflow-y-auto"
                  style={{
                    top: "100%",
                    zIndex: 110,
                    backgroundColor: "#ffffff",
                    borderRadius: "10px",
                    maxHeight: "230px",
                    border: "solid 0.5px #c7c7c7",
                  }}
                >
                  {suggestions.map((item, index) => (
                    <li
                      key={index}
                      className="list-group-item"
                      onClick={() =>{ handleSuggestionClick(item);setSearchInput(item.title)}}
                      style={{
                        fontWeight: "500",
                        cursor: "pointer",
                        color: "#004876",
                        border: "none",
                        textAlign: "left",
                        paddingLeft: "10px",
                      }}
                    >
                      {item.title}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>
        <div className="container mt-3">
          {(instrumentSelectLoader || suggestionLoader) ?
            <div
              id="expand"
              className="row d-flex justify-content-center align-items-center"
              style={{
                minHeight: "200px",
                fontWeight: "700",
                color: "GrayText",
                fontSize: "20px",
              }}
            >
              <div id="" className="spinner" style={{ marginTop: "5px" }}>
                <div className="bounce1" style={{ backgroundColor: "#015a96" }}></div>
                <div className="bounce2" style={{ backgroundColor: "#015a96" }}></div>
                <div className="bounce3" style={{ backgroundColor: "#015a96" }}></div>
              </div>
            </div>
          :
          <>
            {surveyData.length > 0 ?
              <div
              id="expand"
              className="row d-flex overflow-y-scroll"
              style={{ minHeight: "200px", maxHeight: "200px" }}
            >
              <div className="row m-0 d-flex">
                <div className="m-0 p-0" style={{fontSize:"18px"}}>Survey Details</div>
                <div
                  className="col-12 d-flex justify-content-left "
                  style={{ color: "black" }}
                >
                  <div
                    className="projectSearchInfoTop"
                    style={{ color: "#004879", whiteSpace: "nowrap" }}
                  >
                    Project Name:&nbsp;
                  </div>
                  <div className="patientsinfo apEmail d-flex justify-content-left">{surveyData[0].projectName}</div>
                </div>
                <div
                  className="col-12 d-flex justify-content-left"
                  style={{ color: "black" }}
                >
                  <div
                    className="projectSearchInfoTop"
                    style={{ color: "#004879", whiteSpace: "nowrap" }}
                  >
                    Survey Title:&nbsp;
                  </div>
                  <div className="patientsinfo apEmail d-flex justify-content-left">{surveyData[0].title}</div>
                </div>
                {surveyData[0].age != null && (
                <div
                  className="col-12 d-flex justify-content-left "
                  style={{ color: "black" }}
                >
                  
                    <>
                      <span
                        className="projectSearchInfoTop"
                        style={{ color: "#004879", whiteSpace: "nowrap" }}
                      >
                        Age:&nbsp;
                      </span>
                      <span className="patientsinfo apEmail d-flex justify-content-left"> {surveyData[0].age.includes("-") ? surveyData[0].age: `${surveyData[0].age}+`}</span>
                    </>
                    </div>
                  )}
                {surveyData[0].location != null && (
                <div
                  className="col-12 d-flex justify-content-left"
                  style={{ color: "black" }}
                >
                  
                    <>
                      <span
                        className="projectSearchInfoTop"
                        style={{ color: "#004879", whiteSpace: "nowrap" }}
                      >
                        Location:&nbsp;
                      </span>
                      <span className="patientsinfo apEmail d-flex justify-content-left">{surveyData[0].location}</span>
                    </>
                    </div>
                  )}
                </div>
              
            </div>:
            <div
            id="expand"
            className="row d-flex justify-content-center align-items-center"
            style={{
              minHeight: "200px",
              fontWeight: "700",
              color: "GrayText",
              fontSize: "20px",
            }}
          >{alreadyExist ? "Instrument already exists" : "Please search for a survey"}</div>
            }
          </>}
          <div className="row" style={{ marginTop: "30px" }}>
            <div className="col-6 d-flex justify-content-end">
              <button
                className="searchButton"
                onClick={handleSubmit}
                disabled={surveyData.length === 0 || confirmLoader}
              >
                {confirmLoader ? <Spinner size="sm" animation="border" /> : "Confirm"}
              </button>
            </div>
            <div className="col-6 d-flex justify-content-start">
              <div
                className="cancelButton"
                disabled={confirmLoader}
                onClick={props.setShowAddSurvey}
              >
                <div>Cancel</div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container mt-3">
          {instrumentSelectLoader ? (
            
          ) : (surveyData.length > 0 && !instrumentSelectLoader) ? (
            
            
          ) : (
            <div
              id="expand"
              className="row d-flex justify-content-center align-items-center"
              style={{ minHeight: "200px", fontWeight: "700", color: "GrayText", fontSize: "20px" }}
            >
              {suggestionLoader || instrumentSelectLoader ? (
                <li
                  className="list-group-item"
                  style={{
                    fontWeight: "500",
                    cursor: "pointer",
                    color: "#004876",
                    border: "none",
                    textAlign: "center",
                    paddingLeft: "10px",
                  }}
                >
                  <div id="" className="spinner" style={{ marginTop: "5px" }}>
                    <div className="bounce1" style={{ backgroundColor: "#015a96" }}></div>
                    <div className="bounce2" style={{ backgroundColor: "#015a96" }}></div>
                    <div className="bounce3" style={{ backgroundColor: "#015a96" }}></div>
                  </div>
                </li>
              ) : (
                <></>
              )}
            </div>
          )}
          <div className="row" style={{ marginTop: "30px" }}>
            <div className="col-6 d-flex justify-content-end">
              <button
                className="searchButton"
                onClick={handleSubmit}
                disabled={surveyData.length === 0 || confirmLoader}
              >
                {confirmLoader ? <Spinner size="sm" animation="border" /> : "Confirm"}
              </button>
            </div>
            <div className="col-6 d-flex justify-content-start">
              <div
                className="cancelButton"
                disabled={confirmLoader}
                onClick={props.setShowAddSurvey}
              >
                <div>Cancel</div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SearchSurvey;