import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  useHistory,
  Switch,
  useLocation,
} from "react-router-dom";
import React, { useState } from "react";

import { SecureRoute, LoginCallback, Security } from "@okta/okta-react";

import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import patients from "./Components/patients.js";
import visits from "./Components/visits.js";
import patientsDetails from "./Components/PatientDetails/patientDetails.js";
import Navbar from "./Components/Nav/Navbar.js";
import LockScreenDialog from "./Components/LockScreenDialog.js";
import Authorize from "./Components/Authorize.js";
import { AuthProvider } from "./Components/AppContextProvider.js";
import oktaAuth from "./okta-config.js";
import Logout from "./Components/Logout.js";
import Admin from "./Components/Admin.js";
import SurveyStartVerification from "./Components/Modules/SurveyStartVerification.js";
import  survey  from "./Components/Modules/Survey.js";
import surveyComplete  from "./Components/Modules/SurveyComplete.js";

function App() {

  const location = useLocation();
  const currentPath = location.pathname;
  const history = useHistory();
  const [showLockedScreen, setShowLockedScreen] = useState(localStorage.getItem("locked"));
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    if (history === undefined) {
      history.push(toRelativeUrl(originalUri, window.location.origin));
    } else {
      history.replace(toRelativeUrl(originalUri, window.location.origin));
    }
  };
  const [activeNavItem, setActiveNavItem] = useState("finlookup");

  return (
    <>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <div>
          <Switch>
            <AuthProvider>

              
            {!(currentPath.includes('/surveyComplete') || currentPath.includes('/survey')) && (
                <>
                  {showLockedScreen === "true" && (
                    <LockScreenDialog setShowLockedScreen={setShowLockedScreen} />
                  )}
                  <Navbar setActiveNavItem={setActiveNavItem} />
                </>
              )}
               <Route path="/survey" component={survey} />
               <Route path="/surveyComplete" component={surveyComplete} />
              <Route path="/login/callback" component={LoginCallback} />
              <SecureRoute exact path="/" component={Authorize} />
              <SecureRoute path="/visits" component={visits} />
              <SecureRoute path="/PatientDetails" component={patientsDetails} />
              <SecureRoute path="/patients" component={patients} />
              <SecureRoute path="/logout" component={Logout} />
              <SecureRoute path="/admin" component={Admin} />
            </AuthProvider>
          </Switch>
        </div>
      </Security>
     
    </>
  );

}

const AppWithRouterAccess = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouterAccess;
