import React, { useEffect, useState } from 'react';
import markFormAsDone from '../Api/markFormAsDone';
import getFormStatus from '../Api/getFormStatus';
import Chevron from "../images/chevron.png";
import dChevron from "../images/chevron_d.png";
import "./SurveyComplete.css"
import progress_circle from "../images/progress_circle.gif"
import getAllSurveyCompleteStatus from '../Api/getAllSurveyCompleteStatus';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faClock,
  faShareSquare,
  faXmarkCircle
} from "@fortawesome/free-solid-svg-icons";
import getForms from '../Api/getForms';

const SurveyComplete = () => {
  const [completion, setCompletion] = useState(-1);
  const [totalForm, setTotalForm] = useState(-1);
  const [loader, setLoader] = useState(false)
  const [fchevron, setfchevron] = useState(false);
  const [forms, setForms] = useState()
  const [isAllFormsCompleted, setIsAllFormsCompleted] = useState(false);
  const [allFormsStatusList, setAllFormsStatusList] = useState([]);
  const markFormAsDoneData = async () => {
    setLoader(true)
    try {
      let sessionId = localStorage.getItem("sessionId")
      const response = await markFormAsDone(sessionId);
      getFormStatusData(response);
    } catch (err) {
      console.error('Error marking form as done:', err);
    }
  };
  const setSurveyName = (inputString) => {
    var data = forms.find(form => form.instrumentName === inputString);
    if (data) {
      return data.instrumentLabel;
    }
    return convertString(inputString);
  };

  const convertString = (inputString) => {
    return inputString
      .replace(/_/g, ' ')
      .replace(/\b\w/g, char => char.toUpperCase());
  }
  const getFormStatusData = async (markFormResponse) => {
    try {
      let sessionId = localStorage.getItem("sessionId")
      const response = await getFormStatus(sessionId);
      setLoader(false)
      setCompletion(response.complete);
      setTotalForm(response.total);
      if (!markFormResponse.isAllFormsCompleted) {
        setTimeout(() => {
          window.location.href = markFormResponse.redirectURL;
        }, 3000);
      }
      else {

        setTimeout(() => {
          setCompletion(-1)
          setTotalForm(-1)
          setLoader(true)
        }, 2000);
        getForms()
          .then((response) => {
            setForms(response)
          })
          .catch((err) => {
            console.log(err);

          });
        await getAllSurveyCompleteStatus(markFormResponse)
          .then((response) => {
            setTimeout(() => {
              setLoader(false)
              setIsAllFormsCompleted(true);
              setAllFormsStatusList(response);
            }, 4000);

          }
          )
      }
    } catch (err) {
      console.error('Error getting form status:', err);
    }
  };

  useEffect(() => {
    markFormAsDoneData();
  }, []);

  return (
    <div id="pagecontainer" className="container-fluid" role="main" data-mlm-text-align="left">
      <div id="container" data-mlm-text-align="left">
        <div id="pagecontent" translate="no" data-mlm-text-align="left" style={{ position: 'relative' }}>

          <div id="surveyacknowledgment" data-mlm="survey-acknowledgement" style={{ color: "#fff" }}>
            {<><p><strong>Thank you for taking the survey.</strong></p>
              <p>Have a nice day!</p></>}
            <div id="results" style={{ minHeight: '170px', textAlign: 'center' }}>
              <div style={{ color: '#fff', fontSize: '24px', fontWeight: 'bold', textAlign: 'center' }}>
                {totalForm != -1 && <>{((completion === totalForm)) ? "Online Forms Submitted!" : `${completion} of ${totalForm} Forms Completed`}</>}
              </div>
              {isAllFormsCompleted != true && <div style={{ textAlign: 'center', padding: '10px' }}>
                <div style={{ width: '400px', height: '30px', display: 'inline-block', background: '#004879', padding: '0px' }}>
                  <div id="complete" style={{ height: '40px', display: 'block', overflow: 'hidden' }}>
                    {loader ? <img src={progress_circle} alt="Loading..." /> :
                      <>{(!(totalForm === -1)) &&
                        < div style={{ textAlign: 'left', padding: '10px' }}>
                          <div style={{ width: totalForm != 0 ? `${(completion / totalForm) * 100}%` : `${((completion + 1) / (totalForm + 1)) * 100}%`, maxWidth: "400px", height: '30px', display: 'inline-block', background: '#004879', padding: '0px' }}>
                            <div
                              className='progress-bar'
                              style={{
                                height: '30px',
                                background: '#fff',
                                width: `${(completion / totalForm) * 100}%`,
                                transition: 'width 1s ease'
                              }}
                            ></div>
                          </div>
                        </div>
                      }</>
                    }

                  </div>
                </div>
              </div>}
              {isAllFormsCompleted &&
                <div className={`border border-black  `} style={{ backgroundColor: "#fff", padding: "0.1rem", borderRadius: "0.1rem" }}>
                  <div
                    className="formsHeder d-flex justify-content-between px-2 m-0"
                    onClick={() => {
                      // setShow(!show);

                    }}
                    style={{ backgroundColor: "#004879" }}
                  >
                    <div>Forms Status</div>{" "}

                  </div>


                  <div className="px-3 pb-2" style={{ backgroundColor: "#F6F5F5  " }}>
                    <div className="px-2" cellPadding="1" style={{ width: '100%', textAlign: 'left', color: "#004879", fontWeight: "normal", fontSize: "1rem" }}>
                      <div className='row' style={{ fontWeight: "bold" }}>

                        <div className='col-sm-9 col-8'>Name</div>
                        <div className='col-sm-3 col-4'>Status</div>

                      </div>
                      <div >
                        {allFormsStatusList.map((item, index) => (
                          <div className='row mt-2' key={index}>
                            <div className="col-sm-9 col-8 apEmail" style={{ color: "black" }}>{setSurveyName(item.instrumentName)}</div>
                            <div className='col-sm-3 col-4 apEmail d-flex align-items-center'>{item.status == true ?
                              <>
                                {
                                  (item.statusMessage == "Sent to witness" || item.statusMessage == "Sent to intepreter") ? <> <FontAwesomeIcon
                                  icon={faShareSquare}
                                  style={{ fontSize: "18px", color: "#FF7F00" }}
                              /><div>

                                      &nbsp; {item.statusMessage}
                                    </div> </> : <> <FontAwesomeIcon
                                      icon={faCheckCircle}
                                      style={{ fontSize: "20px", color: "green" }}
                                    />
                                    <div>
                                      &nbsp; Completed
                                    </div></>
                                }

                              </>
                              : <>
                                {
                                  item.statusMessage == "Deferred" ? <FontAwesomeIcon icon={faClock}
                                    style={{ fontSize: "20px", color: "#FFD369" }} /> : <FontAwesomeIcon icon={faXmarkCircle}
                                      style={{ fontSize: "20px", color: "red" }} />

                                }

                                <div>
                                  &nbsp;{item.statusMessage}
                                </div>
                              </>
                            }</div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>



                </div>

              }

            </div>
            {isAllFormsCompleted &&
              <div className='mt-4 d-flex justify-content-center' style={{ width: "100%", fontSize: "0.8rem", color: "#383838" }} >
                <a style={{ color: "#fff" }} href='https://www.chla.org'>Go to CHLA Home Page</a>
              </div>}
          </div>
        </div>
      </div>
    </div >

  );
};

export default SurveyComplete;
