import axios from 'axios';
import React from 'react'; 
import secrets from '../../env.json';


const deletePrefillInfo = (data,accessToken) => {
    const apiUrl =`${secrets.REACT_APP_BASEURL}`+`/RedcapAdmin/deletePrefillInfo`;
  // Set up Axios with the access token in the headers
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };
  return axios
    .post(apiUrl,data, axiosConfig)
    .then((response) => {
      // Handle the response data here
      
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      // Handle any errors that occurred during the API call
      return Promise.reject(error);
    });
};

export default deletePrefillInfo;
