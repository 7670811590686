import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useAuth } from "../../AppContextProvider";
import getForms from '../../Api/getForms';
import getSurveyFormStatus from '../../Api/getSurveyFormStatus';
import InstrumentStatus from './InstrumentStatus';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';
 
export default function StatusCountHome(props) {
  const [dateTime, setDateTime] = useState(new Date());
  const [homePageloader, setHomePageLoader] = useState(true);
  const [showCustom, setShowCustom] = useState("");
  const { accessToken } = useAuth();
  const [forms, setForms] = useState([])
  const [status, setStatus] = useState()
  const [selectedForm, setSelectedForm] = useState()
  const [startDateTime, setStartDateTime] = useState()
  const [endDateTime, setEndDateTime] = useState()
  const [loader, setLoader] = useState(false)
  const[showDate,setShowDate]=useState(false)
  const[searchDisable,setSearchDisable]=useState(true)
  const [surveyStatus,setSurveyStatus]=useState([]);
 
  useEffect(() => {

    function SetForms() {
        
    const data = {
        startDatetime: startDateTime,
        endDatetime: endDateTime,
        instrumentName: selectedForm,
        status: status
      }
      getSurveyFormStatus(data,accessToken)
      .then((response)=>{
        setHomePageLoader(false);
        setSurveyStatus(response);
        setLoader(false)
    })
        .catch((err) => {
          console.log(err);
          setLoader(false);
          setHomePageLoader(false);
        });

      getForms(accessToken)
        .then((response) => {
          setForms(response)
        })
        .catch((err) => {
          console.log(err);
 
        });
 
    }
    SetForms()
  }, [])
 
  useEffect(() => {
    if ( selectedForm || status || startDateTime || endDateTime) {
      setSearchDisable(false);
    } else {
      setSearchDisable(true);
    }
  }, [selectedForm, status, startDateTime, endDateTime]);
 
  const handleDateChange = (value) => {
    setShowCustom(value)
    setShowDate(false)
    const startOfToday = new DateObject();
    startOfToday.setHour(0)
    startOfToday.setMinute(0)
    startOfToday.setSecond(0)
    startOfToday.setMillisecond(0)

    const endOfToday = new DateObject();
    endOfToday.setHour(23)
    endOfToday.setMinute(59)
    endOfToday.setSecond(59)
    endOfToday.setMillisecond(999)

    if (value === '') {
      setStartDateTime(undefined)
      setEndDateTime(undefined)
      setShowDate(true)
    }

    if (value === "0") {

      setStartDateTime(startOfToday);
      setEndDateTime(endOfToday);
    } else if (value === "7" || value === "14" || value === "21") {

      const pastDate = new DateObject().subtract(+value, "days");
      pastDate.setHour(0)
      pastDate.setMinute(0)
      pastDate.setSecond(0)
      pastDate.setMillisecond(0)

      setStartDateTime(pastDate);
      setEndDateTime(endOfToday);
    } else if (value === "Custom") {
      setStartDateTime(undefined)
      setEndDateTime(undefined)
    }
  };
 
 
  const handleSearch = () => {
    setLoader(true)
    const data = {
      startDatetime: startDateTime!=undefined?startDateTime.format():"",
      endDatetime: endDateTime!=undefined?endDateTime.format():"",
      instrumentName: selectedForm,
      status: status
    }
    getSurveyFormStatus(data,accessToken)
    .then((response)=>{
      setSurveyStatus(response);
      setLoader(false)
  })
      .catch((err) => {
        console.log(err);
        setLoader(false)
     
 
      });
  }

  const GetSurveyMoreInfo = (instrumentName,instrumentLabel) => {
    const data = {
      custom:showCustom,
      startDatetime: startDateTime!=undefined?startDateTime.format():"",
      endDatetime: endDateTime!=undefined?endDateTime.format():"",
      instrumentName: instrumentName,
      instrumentLabel:instrumentLabel,
      status: status,
      isFromStats:true
    }
    props.setDataFromStats(data);
    props.setScreenType("surveyHistory");

  }
  
  return (
    <>
    {homePageloader? <>
          <div id="" className="spinner" style={{ marginTop: "100px" }}>
            <div className="bounce1" style={{ backgroundColor: "#015a96" }}></div>
            <div className="bounce2" style={{ backgroundColor: "#015a96" }}></div>
            <div className="bounce3" style={{ backgroundColor: "#015a96" }}></div>
          </div>
          <div className="d-flex justify-content-center align-items-center mt-2" style={{fontWeight:"bold", color:"#015a96"}}>
            Please wait while we get the Survey Stats Details
          </div>
        </>:<div className="mx-0 w-100 ">
      <div className="container px-4 ">
        <div className="tabData" id="patientlistdata">
          <div id="patient_information">
            <div className="row adminPageTitle container-fluid" style={{ marginLeft: "0" }}>
              Survey Stats
            </div>
            <nav>
              <div className="nav nav-tabs" role="tablist">
                <button
                  className="nav-link active textStyle"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="true"
                  style={{ marginLeft: "0" }}
                >
                  Select Filters
                </button>
              </div>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <div
                    id="visitInputs"
                    className="piInputs container-fluid justify-content-center"
                  >
                    <div className="row d-flex">
                      <div className="col-12  col-xl-8 d-flex justify-content-center justify-content-sm-start align-items-center mt-2 mt-xl-0">
                        <div id="AppointmentResource" style={{ width: "100%" }}>
                          <select
                            className="finInput "
                            id="currentAppointment"
                            name="Clinic"
                            onChange={e => setSelectedForm(e.target.value)}
                          >
                            <option value="">Select Your Form</option>
                            {forms.map((form) => (
                              <option
                                style={{ border: "none", width: "100%" }}
                                value={form.instrumentName}
                              >
                                {form.instrumentLabel}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-xl-4 d-flex justify-content-center justify-content-sm-start align-items-center mt-2 mt-xl-0">
                        <div id="AppointmentResource" style={{ width: "100%" }}>
                          <select
                            className="finInput "
                            id="currentAppointment"
                            name="Clinic"
                            // value={selectedClinic.id}
                            onChange={e => setStatus(e.target.value)}
                          >
                            <option value="">Select Status</option>
                            <option value="Scheduled">Scheduled</option>
                            <option value="Sent">Sent</option>
                            <option value="Started">Started</option>
                            <option value="In Validation">In Validation</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Deferred">Deferred</option>
                            <option value="Declined">Declined</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Sent to intepreter">Sent to intepreter</option>
                            <option value="Opened by intepreter">Opened by intepreter</option>
                            <option value="Sent to witness">Sent to Witness</option>
                            <option value="Opened by witness">Opened by Witness</option>
                            <option value="Completed">Completed</option>
 
                          </select>
                        </div>
                      </div>
 
 
 
 
                    </div>
                    <div className='row mt-2 px-3'>
                      <div className="col-12  col-xl-4 datefilterRow d-flex align-items-center" style={{ alignSelf: "center" }}>
 
                        <div className="dateText">Date:</div>
                        <div id="AppointmentResource" style={{ width: "100%" }}>
                        <select className="datefinInput elementFilter " id="currentDate" onChange={e => handleDateChange(e.target.value)} value={showCustom}>
                          <option value="">Date Range</option>
                          <option value="0">Today</option>
                          <option value="7">Last 7 Days</option>
                          <option value="14">Last 14 Days</option>
                          <option value="21">Last 21 Days</option>
                          <option value="Custom">Custom</option>
                        </select>
                      </div>
                      </div>
                      {showCustom!="" &&<div className="col-12 col-md-6 col-xl-4 datefilterRow d-flex align-items-center" style={{ alignSelf: "center" }}>
 
                        <div className="dateText col-2 col-xl-3">Start Date:</div>
                        <div id="AppointmentResource" style={{ width: "100%" }}>
 
                                {/* <input className='elementDate' style={{ border: "1px solid #ced4da", borderRadius: "0.25rem", overflow: "hidden",  padding: "0.375rem 0.75rem" }} placeholder="Select date and time" type="datetime-local" id="scheduledate" autocomplete="off" value={startDateTime} onChange={e=>(setStartDateTime(e.target.value),setShowCustom("Custom"))} disabled={!showDate}/> */}
                                <DatePicker
                              inputClass='elementDate'
                              format="MM/DD/YYYY HH:mm"
                              placeholder='mm-dd-yyyy HH:mm'
                              plugins={[
                                <TimePicker position="right" />
                              ]}
                              value={startDateTime}
                              onChange={e => (setStartDateTime(e), setShowCustom("Custom"))}
                              disabled={showDate}
                              maxDate={new DateObject()}
                            />
                        </div>
                           
                      </div>}
                      {showCustom!="" &&<div className="col-12 col-md-6 col-xl-4 datefilterRow d-flex align-items-center" style={{ alignSelf: "center" }}>
 
                        <div className="dateText col-2 col-xl-3">End Date:</div>
                        <div id="AppointmentResource" style={{ width: "100%" }}>
 
                                {/* <input className='elementDate' style={{ border: "1px solid #ced4da", borderRadius: "0.25rem", overflow: "hidden", padding: "0.375rem 0.75rem" }} placeholder="Select date and time" type="datetime-local" id="scheduledate" autocomplete="off" value={endDateTime} onChange={e=>(setEndDateTime(e.target.value),setShowCustom("Custom"))} disabled={!showDate}/> */}
                                <DatePicker
                              inputClass='elementDate'
                              format="MM/DD/YYYY HH:mm"
                              placeholder='mm-dd-yyyy HH:mm'
                              plugins={[
                                <TimePicker position="right" />
                              ]}
                              value={endDateTime}
                              onChange={e => (setEndDateTime(e), setShowCustom("Custom"))}
                              disabled={showDate}
                              maxDate={new DateObject()}
                            />
                        </div>
                           
                      </div>}
                      <div
                        className="col-12  d-flex justify-content-center justify-content-md-end p-0 "
 
                      >
                        <button
                          className="searchButton d-flex justify-content-around"
                          id="visitSearchButton"
                          onClick={() => handleSearch()}
                        >
                          <div>{loader ? <Spinner /> : "Filter"}</div>
                        </button>
                      </div>
 
 
                    </div>
 
                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
         {surveyStatus && <InstrumentStatus surveyStatus={surveyStatus} GetSurveyMoreInfo={GetSurveyMoreInfo}/>}
        
      </div>
    </div>}
    </>
  )
}