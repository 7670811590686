import React from 'react';
import "../../patients.css";
import PatientsBanner from '../../PatientDetails/patientsBanner';
import EncounterListData from './encounterData';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import EncounterTable from './encounterTable';

const EncounterSelectionList = (props) => {
  const {patientData, setShowEncounterList} = props;
  const location = useLocation();
  const { List } = location.state;
  
  return (
    <div class="encounterList">
      <PatientsBanner patientData = {patientData} isEncounterList = {true} setShowEncounterList={setShowEncounterList} setEncounterError = {props.setEncounterError} setIsSurvey={props.setIsSurvey} isSurvey={props.isSurvey}/>
      <EncounterTable List = {List} patientData = {patientData} EncounterList={patientData.encounter} isEncounterList = {true} setShowEncounterList={setShowEncounterList} setDetailsLoader={props.setDetailsLoader} alterEncounterSurvey={props.alterEncounterSurvey} encountersLoader = {props.encountersLoader} locationType = {props.locationType} encounterTypes = {props.encounterTypes}/>
      {/* {patientData.encounter.map((currentEncounter) => (
        <EncounterListData List = {List} patientData = {patientData} Encounter={currentEncounter} isEncounterList = {true} setShowEncounterList={setShowEncounterList} setDetailsLoader={props.setDetailsLoader} alterEncounterSurvey={props.alterEncounterSurvey}/>
      ))} */}
      </div>
  )
}

export default EncounterSelectionList