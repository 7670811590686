import React, { useState, useEffect } from "react";
import resubmitSurveys from "../../Api/resubmitSurveys";
import { useAuth } from "../../AppContextProvider";
import { Spinner } from "react-bootstrap";
import ResubmitConfirmationScreen from "./ResubmitConfirmationScreen";

function SurveyHistoryData(props) {
    const[loader, setLoader] = useState(false);
    const [showConfirmationScreen, setShowConfirmationScreen] = useState(false);
    const [showConfirmationLoader, setShowConfirmationLoader] = useState(false);
    const [isSuccess, setIsSuccess] = useState(true);
    const { accessToken } = useAuth();
    const {survey} = props;
    const setSurveyName = (inputString) => {
        var data = props.forms.find(form => form.instrumentName === inputString);
        if (data) {
          return data.instrumentLabel;
        }
        return convertString(inputString);
    };
    const convertString = (inputString) => {
        return inputString
          .replace(/_/g, ' ')
          .replace(/\b\w/g, char => char.toUpperCase());
    }
    var onClickResubmit = (screen) => {
        if(screen == "confirmation"){
            setShowConfirmationLoader(true);
        }
        else{
            setLoader(true);
        }
        resubmitSurveys(survey, accessToken)
        .then((response) => {
            if(response === "Accepted"){
                setShowConfirmationScreen(true);
            }
            else{
                setIsSuccess(false);
                setShowConfirmationScreen(true);
            }
            setLoader(false);
            setShowConfirmationLoader(false);
        })
        .catch((err) => {
            setIsSuccess(false);
            setShowConfirmationScreen(true);
            setLoader(false);
            setShowConfirmationLoader(false);
        })
    }
    return (
        <>
        {showConfirmationScreen &&
            <ResubmitConfirmationScreen setShowConfirmationScreen = {setShowConfirmationScreen} isSuccess = {isSuccess} onClickResubmit={onClickResubmit} showConfirmationLoader={showConfirmationLoader}/>
        }
        <div className="row m-0 d-flex justify-content-center p-3 patientFIN">
            <div
                className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                style={{ color: "black" }}
            >
                <span className="d-lg-none patientsinfoTop" style={{ color: "black", whiteSpace: "nowrap" }}>Status:&nbsp;</span>
                <span className="patientsinfo apEmail">{survey.statusUcRedcapSurveyHistory}</span>
            </div>
            <div
                className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                style={{ color: "black" }}
            >
                <span className="d-lg-none patientsinfoTop" style={{ color: "black", whiteSpace: "nowrap", overflow: 'hidden' }}>Sent Date:&nbsp;</span>
                <span className="patientsinfo">{survey.sentDtTmUcRedcapSurveyHistory}</span>
            </div>
            <div
                className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                style={{ color: "black" }}
            >
                <span className="d-lg-none patientsinfoTop" style={{ color: "black", whiteSpace: "nowrap" }}>Date Modified:&nbsp;</span>
                <span className="patientsinfo">{survey.dateModifiedDtTmUcRedcapSurveyHistory}</span>
            </div>
            <div
                className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                style={{ color: "black" }}
            >
                <span className="d-lg-none patientsinfoTop" style={{ color: "black", whiteSpace: "nowrap" }}>Form Name:&nbsp;</span>
                <span className="patientsinfo apEmail">{setSurveyName(survey.instrumentNameUcRedcapSurveyHistory)}</span>
            </div>
            <div
                className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                style={{ color: "black" }}
            >
                <span className="d-lg-none patientsinfoTop" style={{ color: "black", whiteSpace: "nowrap" }}>Sent By:&nbsp;</span>
                <span className="patientsinfo loggegInUserEmail" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>{survey.loggedInUserUcRedcapSurveyHistory}</span>
            </div>
            <div
                className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-lg-left justify-content-center"
                style={{ color: "black" }}
            >
                <button disabled={loader} className="resubmitButton" onClick={() => onClickResubmit("home")}> {loader ? <Spinner size="sm" animation="border"/> : "Resubmit"} </button>
            </div>
        </div>
        </>
    );
}

export default SurveyHistoryData;