import React, { useState } from 'react'
import { Spinner } from 'react-bootstrap'


const ResubmitConfirmationScreen = (props) => {
	
  return (
    <div id="overlay2">
        <div id="pin" className='py-4'>
			<div className="row">
				<div className="col-12">
					<div className="row m-0 w-100 p-0 d-flex justify-content-center">
						<div className="col-12 d-flex justify-content-left m-0 p-1">
							<div className="text-field w-100">
								<label className="lockScreenDetails">{props.isSuccess?"Your survey has been resubmitted successfully!":<div style={{color:"red"}}>Some error occurred while resubmitting the survey<br/> Please try again</div>}</label>
							</div>
						</div>
					</div>
				</div>
                <div className="col-12">
					<div className="row m-0 w-100 p-0 d-flex justify-content-center">
                        {props.isSuccess ? <div className="col-12 d-flex justify-content-center mt-3 p-1">
                            <div className={"searchButton"} onClick = {() => props.setShowConfirmationScreen(false)}>Okay</div>
						</div>
                        :
                        <>
                            <div className="col-6 d-flex justify-content-end mt-3 p-1">
                                <button disabled = {props.showConfirmationLoader} className={"searchButton"} onClick = {() => props.onClickResubmit("confirmation")}>{props.showConfirmationLoader ? <Spinner size="sm" animation="border"/> : "Try Again"}</button>
                            </div>
                            <div className="col-6 d-flex justify-content-start mt-3 p-1">
                                <button className={"cancelButton"} disabled = {props.showConfirmationLoader} onClick = {() => props.setShowConfirmationScreen(false)}>Cancel</button>
                            </div>
                        </>}
						
					</div>
				</div>
            </div>
		</div>
    </div>
  )
}

export default ResubmitConfirmationScreen