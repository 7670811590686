const LanguageConfig = {
    English: {
        patient_last_name: "Patient Last Name",
        patient_dob: "Patient Date of Birth",
        patient_zipcode: "Patient Zipcode",
        language: "English",
        birthdate_text: "Please confirm the Details for:",
        confirm_btn_text: "Confirm",
        not_recognized_text: "I don't recognize this person.",
        not_recognized_confirm: "Press Ok if you don't recognize this person",
        months : [
            { value: '01', label: 'Jan' },
            { value: '02', label: 'Feb' },
            { value: '03', label: 'Mar' },
            { value: '04', label: 'Apr' },
            { value: '05', label: 'May' },
            { value: '06', label: 'Jun' },
            { value: '07', label: 'Jul' },
            { value: '08', label: 'Aug' },
            { value: '09', label: 'Sep' },
            { value: '10', label: 'Oct' },
            { value: '11', label: 'Nov' },
            { value: '12', label: 'Dec' },
        ],
        legal_notice: "The documents provided to you are an essential part of your/your child's upcoming appointment at CHLA Hospital. Several documents require your electronic signature prior to the appointment. If you have any questions about the documents or your child's medical appointment, please call the clinic main number and someone from the Patient Access department will assist with obtaining answers for you. Thank you."
    },
    Espanol: {
        patient_last_name: "Apellido del paciente",
        patient_dob: "Fecha de nacimiento del paciente",
        patient_zipcode: "Código postal del paciente",
        language: "Espanol",
        birthdate_text: "Por favor, confirme los detalles de",
        confirm_btn_text: "Confirmar",
        not_recognized_text: "No reconozco a esta persona.",
        not_recognized_confirm: "Presiona Ok si no reconoces a esta persona",
        months : [
            { value: '01', label: 'Ene' },
            { value: '02', label: 'Feb' },
            { value: '03', label: 'Mar' },
            { value: '04', label: 'Abr' },
            { value: '05', label: 'May' },
            { value: '06', label: 'Jun' },
            { value: '07', label: 'Jul' },
            { value: '08', label: 'Ago' },
            { value: '09', label: 'Sep' },
            { value: '10', label: 'Oct' },
            { value: '11', label: 'Nov' },
            { value: '12', label: 'Dic' },
        ],
        legal_notice: "Los documentos que se le proporcionan son una parte esencial de su próxima cita o la de su hijo en el Hospital CHLA. Varios documentos requieren su firma electrónica antes de la cita. Si tiene alguna pregunta sobre los documentos o la cita médica de su hijo, llame al número principal de la clínica y alguien del departamento de Acceso de pacientes le ayudará a obtener respuestas. Gracias."
    }
};

export default LanguageConfig;
