import React, { useState, useRef, useEffect } from "react";
import "../patients.css";
import { useOktaAuth } from "@okta/okta-react";
import { useLocation, useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FormData from "../Api/FormData.js";
import Chevron from "../images/chevron.png";
import dChevron from "../images/chevron_d.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HistoryList from "./historylist.js";
import HistoryData from "../Api/HistoryData.js";
import {
  faCheckCircle,
  faShareSquare,
  faPaperPlane,
  faXmarkCircle,
  faCheck,
  faClock
} from "@fortawesome/free-solid-svg-icons";
import { InstrumentList } from "./instrumentList.js";

function Instruments(props) {
  const [Ichevron, setIchevron] = useState(props.isRegistrationForm);
  const [instrumentList, setInstrumentList] = useState(props.data);
  useEffect(() => {
    if (props.data) {
      if (props.isRegistrationForm) {
        setInstrumentList(props.data);
      }
      else {
        setInstrumentList(props.data.instruments);
      }
    }
  }, [props.data, props.isRegistrationForm])

  const createDateString = (dateString) => {
    const date1 = new Date(
      new Date(dateString).toLocaleString("en-US", {
        timeZone: "America/Los_Angeles",
      })
    );
    const date2 = new Date(
      new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
    );
    const diffTime = Math.abs(date2 - date1);
    const diffSeconds = Math.floor(diffTime / 1000);
    const diffMins = Math.floor(diffSeconds / 60);
    const diffHours = Math.floor(diffMins / 60);
    const diffDays = Math.floor(diffHours / 24);
    const diffMonths = Math.floor(diffDays / 30);
    const diffYears = Math.floor(diffMonths / 12);
    if (diffYears == 0) {
      if (diffMonths == 0) {
        if (diffDays == 0) {
          if (diffHours == 0) {
            if (diffMins == 0) {
              return " just now";
            } else {
              if (diffMins == 1) {
                return " " + diffMins + " minute ago";
              } else {
                return " " + diffMins + " minutes ago";
              }
            }
          } else {
            if (diffHours == 1) {
              return " " + diffHours + " hour ago";
            } else {
              return " " + diffHours + " hours ago";
            }
          }
        } else {
          if (diffDays == 1) return " " + diffDays + " day ago";
          else return " " + diffDays + " days ago";
        }
      } else {
        if (diffMonths == 1) return " " + diffMonths + " month ago";
        else return " " + diffMonths + " months ago";
      }
    } else {
      if (diffYears == 1) return " " + diffYears + "year ago";
      else return " " + diffYears + "years ago";
    }
  };

  const getStatus = (Projects, Instument, id) => {
    if (Projects.formsStatus != null) {
      const FormsStatusresult = Projects.formsStatus.find(
        (formStatus) => formStatus.pid === props.data.pid
      );
      if (FormsStatusresult !== undefined) {
        const instrumentResult = FormsStatusresult.instruments.find(
          (data) => data.surveyId === Instument.surveyId
        );
        if (
          instrumentResult !== undefined && instrumentResult!==null &&
          (instrumentResult["status"] === "Completed" || instrumentResult["status"] ==="Cancelled" || instrumentResult["status"] ==="Deferred" || instrumentResult["status"] ==="Declined")
        ) {
          if(instrumentResult["status"] ==="Cancelled"|| instrumentResult["status"] ==="Deferred" || instrumentResult["status"] ==="Declined")
          {
            return {
              historyStatus: true,
              status: <div style={{display:"flex",alignItems:"center"}}>
                {instrumentResult["status"] ==="Deferred"?
                <FontAwesomeIcon
                  icon={faClock}
                  style={{ fontSize: "20px", color: "#FFD369" }}
                />:
                <FontAwesomeIcon
                  icon={faXmarkCircle}
                  style={{ fontSize: "20px", color: "red" }}
                />}
                &nbsp;
                <span className="lastDate">
                {instrumentResult["status"] ==="Cancelled"?"Cancelled on":instrumentResult["status"] ==="Deferred"?"Deferred on":"Declined on"}&nbsp;{instrumentResult["lastCompleted"]}&nbsp;({" "}
                  {createDateString(instrumentResult["lastCompleted"])} )
                </span>
              </div>
            }
          }
          else{
          return {
            historyStatus: true,
            status: <div style={{display:"flex",alignItems:"center"}}>
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "20px", color: "green" }}
              />
              &nbsp;
              <span className="lastDate">
                Completed on&nbsp;{instrumentResult["lastCompleted"]}&nbsp;({" "}
                {createDateString(instrumentResult["lastCompleted"])} )
              </span>
            </div>
          }
        }
            ;
        } else if (
          instrumentResult !== undefined &&
          instrumentResult["sentDate"] !== ""
        ) {
          return {
            historyStatus: true,
            status: <div style={{display:"flex",alignItems:"center"}}>
              <FontAwesomeIcon
                icon={faShareSquare}
                style={{ fontSize: "18px", color: "#FF7F00" }}
              />
              &nbsp;
              <span className="lastDate">
                Sent&nbsp;{instrumentResult["sentDate"]}&nbsp;({" "}
                {createDateString(instrumentResult["sentDate"])} )
              </span>
            </div>
          }
            ;
        } else {

          return {
            historyStatus: false,
            status: <div style={{display:"flex",alignItems:"center"}}>
              <FontAwesomeIcon
                icon={faPaperPlane}
                style={{ fontSize: "15px", color: "#4DC1EF" }}
              />
              &nbsp;
              <span className="lastDate">Not Sent</span>
            </div>
          }
            ;
        }
      } else {

        return {
          historyStatus: false,
          status: <div style={{display:"flex",alignItems:"center"}}>
            <FontAwesomeIcon
              icon={faPaperPlane}
              style={{ fontSize: "15px", color: "#4DC1EF" }}
            />
            &nbsp;
            <span className="lastDate">Not Sent</span>
          </div>
        }
          ;
      }
    } else {
      return {
        historyStatus: false,
        status: <div style={{display:"flex",alignItems:"center"}}>
          <FontAwesomeIcon
            icon={faPaperPlane}
            style={{ fontSize: "15px", color: "#4DC1EF" }}
          />
          &nbsp;
          <span className="lastDate">Not Sent</span>
        </div>
      }
        ;
    }
  };
  return (
    <>
      {" "}
      {!props.isRegistrationForm &&
        <div
          className="formName"
          onClick={() => {
            setIchevron(!Ichevron);
          }}
        >
          <div>{props.data.name}</div>
          {Ichevron ? (
            <img className="chevron" src={dChevron} />
          ) : (
            <img className="chevron" src={Chevron} />
          )}
        </div>
      }
      <div className="row">
        {Ichevron && instrumentList ? (
          instrumentList.map((instrument) => {
            return (
              <>
                {instrument.instrumentLabel == "Patient Experience" ? (
                  <></>
                ) : (
                  <div className={props.isRegistrationForm?"col-lg-6":"col-12"}>
                    <InstrumentList
                      FormData={props.FormData}
                      instrument={instrument}
                      data={props.data}
                      HistoryData={HistoryData}
                      recordId={props.recordId}
                      alterChosenForms={props.alterChosenForms}
                      isSurveySelected={props.isSurveySelected}
                      getStatus={getStatus}
                      chosenForms={props.chosenForms}
                      setChosenForms={props.setChosenForms}
                      isRegistrationForm={props.isRegistrationForm}
                      selectForms={props.selectForms}
                      setRegFormListUpdated={props.setRegFormListUpdated}
                    />
                  </div>
                )}
              </>
            );
          })
        ) : (
          <></>
        )}
      </div>

    </>
  );
}
export default Instruments;
