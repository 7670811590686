import React, { useState, useEffect } from "react";
import { Spinner, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import getInstrumentFields from "../../Api/getInstrumentFields";
import getInstrumentPrefillInfo from "../../Api/getInstrumentPrefillInfo";
import getPrefillDtoProperties from "../../Api/getPrefillDtoValues";
import addPrefillInfo from "../../Api/addPrefillInfo";
import updatePrefillFields from "../../Api/updatePrefillFields";
import deletePrefillInfo from "../../Api/deletePrefillInfo";
import { useAuth } from "../../AppContextProvider";
export default function PrefillInstrumentConfig(props) {
  const [keys, setKeys] = useState([]);
  const [values, setValues] = useState([]);
  const [preFillLoader, setPreFillLoader] = useState(false);
  const [updatedPrefillData, setUpdatedPrefillData] = useState([]);
  const [selectedKey, setSelectedKey] = useState({});
  const [selectedValue, setSelectedValue] = useState({
    key:"",
    value:""
  });
  const [mapping, setMapping] = useState([]);
  const [save, setSave] = useState(false);
  const [addButtonLoader, setAddButtonLoader] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState({ condition: "", value: false });
  const [sucessfullText, setSucessfullText] = useState(false);
  const [saveLoader, setSaveLoader] = useState(false);
  const { accessToken } = useAuth();

  useEffect(() => {
    setMapping([]);
    setPreFillLoader(true);
    const getInstrumentFieldsMethod = () => {
      
      const data = {
       projectId: props.projectId,
       formName: props.instrumentData.instrumentName
      };
      getInstrumentFields(data,accessToken)
        .then((response) => {
          let keys = response;
          getInstrumentPrefillInfo(props.instrumentData.surveyId,accessToken)
            .then((response) => {
              let mappingTemp = [];
              response.forEach((element) => {
                keys.forEach((data) => {
                  if (data.fieldName === element.fieldName) {
                    mappingTemp = [
                      ...mappingTemp,
                      {
                        key: data.fieldName,
                        value: element.cclField,
                        label: data.fieldName,
                        isHidden:data.isHidden
                      },
                    ];
                  }
                });
                keys = keys.filter(
                  (data) => data.fieldName !== element.fieldName
                );
              });
              setMapping(mappingTemp);
              setKeys(keys);
              setPreFillLoader(false);
            })
            .catch((err) => {
              console.log(err);
              setPreFillLoader(false);
            });
        })
        .catch((err) => {
          console.log(err);
          setPreFillLoader(false);
        });
    };
    const getValues = () => {
      getPrefillDtoProperties(accessToken)
        .then((response) => {
          setValues(response);
        })
        .catch((err) => {
          console.log(err);
        });
    };

    getInstrumentFieldsMethod();
    getValues();
  }, []);

  // useEffect(() => {
  //   console.log("called");
  //   if (sucessfullText) {
      
  //   }
  // }, [sucessfullText]);

  const handleAdd = () => {
    if (selectedKey.Name && selectedValue.value) {
      setAddButtonLoader(true);
      const data = {
        projectId: props.projectId,
        surveyId: props.instrumentData.surveyId,
        fieldName: selectedKey.Name,
        value: Number(selectedValue.key),
      };
      addPrefillInfo(data,accessToken)
        .then((response) => {
          setMapping([
            ...mapping,
            {
              key: selectedKey.Name,
              value: selectedValue.value,
              label: selectedKey.Label,
              isHidden: selectedKey.isHidden
            },
          ]);
          setKeys(keys.filter((data) => data.fieldName !== selectedKey.Name));
          setSelectedKey({ Name: "", Label: "" });
          setSelectedValue({key:"",value:""});
          setAddButtonLoader(false);
        })
        .catch((err) => {
          setAddButtonLoader(false);
          console.log(err);
        });
    }
  };

  const handleSave = () => {
    setSaveLoader(true);
    let data = [];
    updatedPrefillData.forEach((element) => {
      data = [
        ...data,
        {
          projectId: props.projectId,
          surveyId: props.instrumentData.surveyId,
          fieldName: element.Name,
          value: element.value,
        },
      ];
    });
    updatePrefillFields(data,accessToken)
      .then((response) => {        
        setSelectedKey({ Name: "", Label: "" });
        setSelectedValue({key:"",value:""});
        setSaveLoader(false);
        setSucessfullText(true);
        setSave(false);
        const timer = setTimeout(() => {
          setSucessfullText(false);
          clearTimeout(timer);
        }, 3000);
      })
      .catch((err) => {
        setSaveLoader(false);
        console.log(err);
      });
    setUpdatedPrefillData([]);
  };

  const handleDelete = (index) => {
    setDeleteLoader({ condition: index, value: true });
    const data = {
      projectId: props.projectId,
      surveyId: props.instrumentData.surveyId,
      fieldName: mapping[index].key,
      value: 1,
    };
    setKeys((x) => [
      ...x,
      {
        fieldName: mapping[index].key,
        fieldLabel: mapping[index].label,
        isHidden:mapping[index].isHidden
      },
    ].sort((a, b) => a.fieldName.localeCompare(b.fieldName)));
    deletePrefillInfo(data,accessToken)
      .then((response) => {
        
        const newMapping = mapping.filter((_, i) => i !== index);
        setMapping(newMapping);
        setDeleteLoader({ condition: index, value: false });
      })
      .catch((err) => {
        setDeleteLoader({ condition: index, value: false });
        console.log(err);
      });
  };

  const handleValueChange = (index, newValue,valueid) => {
    const newMapping = mapping.map((item, i) =>
      i === index ? { ...item, value: newValue } : item
    );
    setMapping(newMapping);

    
    setUpdatedPrefillData((x) => [
      ...x,
      {
        Name: newMapping[index].key,
        Label: newMapping[index].label,
        value: valueid,
      },
    ]);
  };

  const handleInputSelectionChange = (key, value) => {
    setSelectedValue({"key":key, "value":value})
  }
  return (
    <div className="mt-5" style={{ fontSize: "18px" }}>
      <div className="row d-flex gap-3 gap-lg-0">
        <div className="col-lg-4 col-md-12 col-sm-12 d-flex justify-content-center justify-content-sm-start ">
          <div
            id="AppointmentResource"
            style={{
              width: "100%",
            }}
          >
            <Dropdown 
              className="finInput"
                id="currentAppointment"
                value={selectedKey.Name}
                // onSelect={handleDropDownSelect}
                onSelect={(eventKey, event) => {
                  const selectedKeyObject = JSON.parse(eventKey);
                  setSelectedKey({
                    Name: selectedKeyObject.name,
                    Label: selectedKeyObject.name,
                    isHidden: selectedKeyObject.isHidden
                  });
                }}
              >
              <Dropdown.Toggle variant="light" id="dropdown-basic" class='dropDownButton'
              >
                <span style={{maxWidth:"90%"}} className="dropdowntoggle">{selectedKey.Name || 'Select Key'}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu >
                {keys.map((key, index) => (
                  <Dropdown.Item key={key.fieldName} 
                  eventKey={JSON.stringify({name: key.fieldName, isHidden: key.isHidden})}   
                  value={key.fieldName}
                  style={{backgroundColor:selectedKey.Name === key.fieldName &&"#d3d3d3"}}
                  >
                    {key.fieldName} &nbsp; {key.isHidden && <FontAwesomeIcon icon={faEyeSlash}/>}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {/* <select
              className="finInput"
              id="currentAppointment"
              value={selectedKey.Name}
              onChange={(e) => {
                console.log("selected again", e.target.value,  e.target.options[e.target.selectedIndex])
                setSelectedKey({
                  Name: e.target.value,
                  Label: e.target.options[e.target.selectedIndex].innerHTML,
                });
              }}
            >
              <option value="">Select Key</option>
              {keys.map((key) => (
                <option
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    overflowWrap: "break-word",
                  }}
                  key={key.fieldName}
                  value={key.fieldName}
                >
                  {key.fieldName}
                </option>
              ))}
            </select> */}
          </div>
        </div>

        <div className="col-lg-4 col-md-12 col-sm-12 d-flex  justify-content-center justify-content-sm-start ">
          <div
            id="AppointmentResource"
            style={{
              width: "100%",
            }}
          >
                <Dropdown 
                  className="finInput"
                    id="currentAppointment"
                    value={selectedValue.value}
                    // onSelect={handleDropDownSelect}
                    onSelect={(eventKey, event) => {
                      handleInputSelectionChange(eventKey,  event.target.textContent)
                    }}
                  >
                  <Dropdown.Toggle variant="light" id="dropdown-basic" class='dropDownButton'
                  style={{overflow:"hidden"}}>
                     <span style={{maxWidth:"90%"}} className="dropdowntoggle">{selectedValue.value || 'Select Value'}</span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {values.map((value, index) => (
                      <Dropdown.Item key={value.idUcRedcapCernerFieldMapping} 
                      eventKey={value.idUcRedcapCernerFieldMapping}  
                      value={value.cernerFieldUcRedcapCernerFieldMapping.replaceAll("_"," ")} 
                      style={{backgroundColor:selectedValue.value === value.cernerFieldUcRedcapCernerFieldMapping.replaceAll("_", " ") &&"#d3d3d3"}}
                      
                      >
                        {value.cernerFieldUcRedcapCernerFieldMapping.replaceAll("_"," ")}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
            {/* <select
              className="finInput"
              id="currentAppointment"
              value={selectedValue.value}
              onChange={(e) => {handleInputSelectionChange(e.target.selectedOptions[0].id, e.target.value)}}
            >
              <option value="">Select Value</option>
              {values.map((value) => (
                <option
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    overflowWrap: "break-word",
                  }}
                  id={value.idUcRedcapCernerFieldMapping}
                  key={value.idUcRedcapCernerFieldMapping}
                  value={value.cernerFieldUcRedcapCernerFieldMapping.replaceAll("_"," ")}
                >
                  {value.cernerFieldUcRedcapCernerFieldMapping.replaceAll("_"," ")}
                </option>
              ))}
            </select> */}
          </div>
        </div>
        <div
          className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center "
          style={{ alignSelf: "center" }}
        >
          <div className="col w-100 m-0 p-0 d-flex justify-content-center">
            <div className="col-sm-4 col-md-4 col-lg-12 m-0 p-0 d-flex justify-content-center">
              <button
                className="searchsurveycancelButton d-flex justify-content-around"
                id="visitSearchButton"
                style={{ minWidth: "120px" }}
                onClick={handleAdd}
                disabled={!(selectedKey.Name && selectedValue.value)}
              >
                <div>{addButtonLoader ? <Spinner size="sm" /> : "Add"}</div>
              </button>
            </div>
          </div>
        </div>
      </div>
      {mapping.length === 0 ? (
        <>
          {preFillLoader ? (
            <div className="col-lg-12 accordionBody">
              <div id="" className="spinner" style={{ marginTop: "40px" }}>
                <div
                  className="bounce1"
                  style={{ backgroundColor: "#015a96" }}
                ></div>
                <div
                  className="bounce2"
                  style={{ backgroundColor: "#015a96" }}
                ></div>
                <div
                  className="bounce3"
                  style={{ backgroundColor: "#015a96" }}
                ></div>
              </div>
            </div>
          ) : (
            <div
              className="text-center mt-4"
              style={{
                fontWeight: "700",
                color: "GrayText",
                fontSize: "15px",
              }}
            >
              No data has been prefilled yet. Please add one.
            </div>
          )}
        </>
      ) : (
        <div>
          <ul className="d-flex flex-column gap-3 mx-0 p-3">
            {mapping.map((item, index) => (
              <li
                key={index}
                className="d-flex flex-column flex-md-row align-items-start align-items-md-center py-2"
              >
                <div className="col-md-5 col-12 mb-2 mb-md-0 d-flex align-items-center">
                  <span
                    className="apEmail"
                    style={{ minWidth: "50px", fontWeight: "200"}}
                  >
                    {item.label}&nbsp; 
                    {item.isHidden && 
                    <FontAwesomeIcon style={{verticalAlign: "middle"}} icon={faEyeSlash} size="xs"/>} :
                  </span>
                  <div className="col-4 d-flex d-sm-none mx-2">
                    {deleteLoader.condition === index &&
                    deleteLoader.value ? (
                      <Spinner size="sm" />
                    ) : (
                      <FontAwesomeIcon
                        icon={faTrash}
                        style={{ cursor: "pointer" }}
                        onClick={() => handleDelete(index)}
                      />
                    )}
                  </div>
                </div>
                <div className="col-sm-4 col-md-4 col-12 mb-2 mb-md-0">
                  <select
                    value={item.value}
                    onChange={(e) => {
                      handleValueChange(index, e.target.value,e.target.selectedOptions[0].id);
                      setSave(true);
                    }}
                    className="finInput"
                    id="currentAppointment"
                    defaultValue={item.value}
                  >
                    <option>{item.value}</option>
                    {(values.filter(x=>x.cernerFieldUcRedcapCernerFieldMapping.replaceAll("_"," ")!=item.value)).map((value) => (
                      <option
                        style={{ whiteSpace: "normal", wordWrap: "break-word" }}
                        key={value.idUcRedcapCernerFieldMapping}
                        value={value.cernerFieldUcRedcapCernerFieldMapping}
                        id={value.idUcRedcapCernerFieldMapping}
                      >
                        {value.cernerFieldUcRedcapCernerFieldMapping.replaceAll("_"," ")}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="col-3  d-none d-sm-flex mx-3">
                  {deleteLoader.condition === index &&
                    deleteLoader.value ? (
                    <Spinner size="sm" />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTrash}
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDelete(index)}
                    />
                  )}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      <div className="d-flex flex-sm-row flex-column  justify-content-between">
        <div>
          {mapping.length > 0 && (
            <>
              {sucessfullText && (
                <div
                  className="text-left mt-3"
                  style={{ color: "green", fontSize: "13px" }}
                >
                  *All Conditions have been saved successfully
                </div>
              )}
            </>
          )}
        </div>
        {save && (
          <button
            className="d-flex justify-content-center align-items-center "
            style={{
              color: "#004879",
              backgroundColor: "#ffff",
              fontSize: "smaller",
              padding: "3px 8px",
              fontWeight: "700",
              borderRadius: "10px",
              border: "0.5px solid #004879",
              cursor: "pointer",
              maxHeight: "35px",
              minHeight: "35px",
              width: "100px",
            }}
            onClick={handleSave}
          >
            {saveLoader ? <Spinner size="sm" /> : "Save"}
          </button>
        )}
      </div>
    </div>
  );
}