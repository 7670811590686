import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import getCompletedSurveyHistories from "../../Api/getCompletedSurveyHistories";
import { useAuth } from "../../AppContextProvider";
import HistoryList from "./HistoryList";
const PatientData = (props) => {
    const {patientData} = props;
    const[open, setOpen] = useState(false);
    const[surveys, setSurveys] = useState();
    const { accessToken } = useAuth();
    const[surveysLoader, setSurveysLoader] = useState(false);
    const getDates = (data) => {
        if (data === undefined) {
          return { date: "", age: "" };
        }
    
        const birthMonth = data.slice(4, 6);
        const birthDay = data.slice(6, 8);
        const birthYear = data.slice(0, 4);
    
        const today = new Date();
        const birthDate = new Date(`${birthYear}-${birthMonth}-${birthDay}`);
    
        const ageInMilliseconds = today - birthDate;
        const ageInYears = Math.floor(ageInMilliseconds / 31536000000); // Approximate milliseconds in a year
    
        return {
          date: `${birthMonth}/${birthDay}/${birthYear}`,
          age: ageInYears + " yrs",
          dateFormat: data, // Added for "mmddyyyy" format
        };
    };
    const onClickShowSurveys = () => {
        if(!open){
            if(!surveys){
                setSurveysLoader(true);
            }
            setOpen(!open);
            getCompletedSurveyHistories(patientData.recordId, accessToken)
            .then((response) => {
                setSurveys(response);
                setSurveysLoader(false);
            })
            .catch((err) => {
                setSurveysLoader(false);
                console.log(err);
            })
        }
        else{
            setOpen(!open);
        }
    }
    return (
        <>
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row d-flex justify-content-center align-item-center p-3 patientData">
                <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                    <span className="d-lg-none patientsinfoTop" style={{ color: "black" }}>
                        Name:&nbsp;
                    </span>
                    <span className="patientsinfo apEmail">{patientData.firstName + " " + patientData.lastName}</span>
                </div>
                <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                    <span className="d-lg-none patientsinfoTop" style={{ color: "black" }}>
                        MRN:&nbsp;
                    </span>
                    <span className="patientsinfo">{patientData.mrn}</span>
                </div>
                <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                    <span className="d-lg-none patientsinfoTop" style={{ color: "black" }}>
                        DOB:&nbsp;
                    </span>
                    <span className="patientsinfo">
                        {getDates(patientData.dob).date}
                    </span>
                </div>
                <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
                    <span className="d-lg-none patientsinfoTop" style={{ color: "black" }}>
                        Age:&nbsp;
                    </span>
                    <span className="patientsinfo">
                        {getDates(patientData.dob).age}
                    </span>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-lg-left justify-content-sm-center">
                    <a
                        className="selectvisits text-white"
                        onClick={(e) => {
                            onClickShowSurveys()
                        }}
                    >
                        Show Surveys {open ? "▼" : "▲"}
                    </a>
                </div>
            </div>
        </div>
        {open && 
            <>
            {surveysLoader ?
            <div className="col-lg-12 accordionBody">
                <div id="" className="spinner" style={{ marginTop: "40px" }}>
                <div className="bounce1" style={{ backgroundColor: "#015a96" }}></div>
                <div className="bounce2" style={{ backgroundColor: "#015a96" }}></div>
                <div className="bounce3" style={{ backgroundColor: "#015a96" }}></div>
                </div>
            </div> 
            : 
            <>
                <div className="surveysTable">
                    <div className="adminSurveyTable">
                        <div className="row d-flex justify-content-center finlistbody">
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="prow d-none d-lg-block">
                                    <div className="row m-0 d-flex justify-content-center p-3 FinDataTop">
                                        <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left">
                                            <span className="patientsinfoTop">Status</span>
                                        </div>
                                        <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left">
                                            <span className="patientsinfoTop ">Sent Date</span>
                                        </div>
                                        <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left">
                                            <span className="patientsinfoTop completedHistoryTop">Completed On</span>
                                        </div>
                                        <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left">
                                            <span className="patientsinfoTop ">Form Name</span>
                                        </div>
                                        <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left">
                                            <span className="patientsinfoTop">Sent By</span>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-4 d-flex justify-content-left">
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="row AdminHistoryTable d-flex justify-content-center">
                    <div
                        className="row m-0 d-none d-lg-flex justify-content-space-between px-1 py-3 sticky-top"
                        style={{ backgroundColor: " #ced4da", zIndex : "0", width:"80%" }}
                    >
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop ">Status</span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop ">Sent Date</span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop ">
                                Date Modified
                            </span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop">Sent By</span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left pl-4"
                            style={{ color: "black" }}
                        >
                            <span className="patientsinfoTop">
                                Additional Party
                            </span>
                        </div>
                        <div
                            className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left pl-4"
                            style={{ color: "black" }}
                        ></div>
                    </div>
                </div> */}
                {surveys && 
                    <HistoryList surveys={surveys} forms={props.forms}/>
                }
            </>
            }
            </>
        }
        </>
    )
}

export default PatientData;
