import React, { useState } from "react";
import { Spinner } from 'react-bootstrap';

const InstrumentStatus = (props) => {
  const [loader, setLoader] = useState(false);

  const handleViewMoreInfo=(instrumentName,instrumentLabel)=>{
    setLoader(true);
    props.GetSurveyMoreInfo(instrumentName,instrumentLabel)
    setLoader(false);
  }
  return (
    <div className="col-12 px-3">
      {props.surveyStatus.length == 1 && props.surveyStatus[0].surveyStatusCounts.length==0 ? 
      <div className="mt-3" style={{ fontWeight: "normal", fontSize: "15px", color: "red", textAlign: "center" }}>No Data Found</div> :
        <>
          <div className="formsHeder">
            {props.surveyStatus.map((instrumentData) => (
             <> {instrumentData.surveyStatusCounts.length!=0 && <div className="surveyDetailCard mb-4" style={{ backgroundColor: "#e9ecef" }}>
                <div className="row p-2 mx-0 d-flex flex-column flex-md-row" style={{ color: "#fff", backgroundColor: "#004879" }}>
                  <div className="col-md-6 col-xl-8 col-12" style={{ color: "#fff",alignContent:"center"}}>
                    {instrumentData.instrumentLabel}
                  </div>
                  <div className="col-md-6 col-xl-4 col-12 d-flex flex-column align-items-md-end" style={{ color: "#fff", fontWeight: "normal", fontSize: "1rem" }}>
                    {instrumentData.totalSurveysSent!=0 && <span>Sent by Phone/Email : <span style={{ color: "#fff" }}>{instrumentData.totalSurveysSent} </span></span>}
                    {instrumentData.totalSurveysStarted!=0 &&<span>Started in Kiosk Mode : <span style={{ color: "#fff" }}>{instrumentData.totalSurveysStarted}</span></span>}
                  </div>
                </div>
                <div className="px-3">
                  <table className="px-3" cellPadding="1" style={{ width: '50%', textAlign: 'left', color: "#004879", fontWeight: "normal", fontSize: "1rem" }}>
                    <thead>
                      <tr>
                        <th>Status</th>
                        <th>Total Count</th>
                      </tr>
                    </thead>
                    <tbody >
                      {instrumentData.surveyStatusCounts.map((item, index) => (
                        <tr key={index}>
                          <td style={{ color: "black" }}>{item.status}</td>
                          <td>{item.surveyStatusCount}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  </div>
                 { instrumentData.instrumentLabel!="Patient Experience" &&<div className=" col-12 d-flex flex-column align-items-md-end p-2" style={{ color: "#fff", fontWeight: "normal", fontSize: "1rem" }}>
                  <button
                          className="viewMoreButton d-flex justify-content-around p-1"
                          id="visitSearchButton"
                          onClick={() =>
                          handleViewMoreInfo(instrumentData.instrumentName,instrumentData.instrumentLabel)
                        }
                        >
                          <div>{loader ? <Spinner /> : "View More"}</div>
                        </button>
                </div>}
              </div>
              
              }</>
            ))}
            </div>
        </>}


    </div>
  )
};

export default InstrumentStatus;