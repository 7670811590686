import React from 'react'
import AdminScreen from './AdminPage/AdminScreen'
 
const Admin = () => {
  return (
    <div className="d-flex flex-column main-container" style={{minHeight:"100vh"}}>
        <AdminScreen/>
    </div>
  )
}
 
export default Admin