import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useAuth } from "../../AppContextProvider";
import SelectDateTime from './SelectDateTime';
import SurveyHistoryPatientsList from './SurveyHistoryPatientList';
import getForms from '../../Api/getForms';
import adminSurveyHistories from '../../Api/adminSurveyHistories';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import TimePicker from 'react-multi-date-picker/plugins/time_picker';

export default function SurveyHistoryHome(props) {
  const [dateTime, setDateTime] = useState(new Date());
  const [showCustom, setShowCustom] = useState("");
  const { accessToken } = useAuth();
  const [forms, setForms] = useState([])
  const [status, setStatus] = useState()
  const [nameOrMrn, setNameOrMrn] = useState("")
  const [selectedForm, setSelectedForm] = useState()
  const [startDateTime, setStartDateTime] = useState()
  const [endDateTime, setEndDateTime] = useState()
  const [loader, setLoader] = useState(false)
  const [showDate, setShowDate] = useState(true)
  const [searchDisable, setSearchDisable] = useState(true)
  const [surveyHistoryData, setSurveyHistoryData] = useState([])
  const [showData, setShowData] = useState(false);
  const [dataFromStats, setDataFromStats] = useState({});

  useEffect(() => {
    setDataFromStats(props.historyFromStats);
    setSelectedForm(dataFromStats.instrumentName);
    handleDateChange(dataFromStats.custom);
    setStatus(dataFromStats.status);
    if (dataFromStats.isFromStats) {
      SetForms();
      const data = {
        mrn: "",
        lastName: "",
        firstName: "",
        startDatetime: dataFromStats.startDatetime,
        endDatetime: dataFromStats.endDatetime,
        instrumentName: dataFromStats.instrumentName,
        status: dataFromStats.status
      }
      setLoader(true);
      adminSurveyHistories(data, accessToken).then((response) => {

        setSurveyHistoryData(response)
        setLoader(false)
        setShowData(true)
      })
        .catch((err) => {
          console.log(err);
          setLoader(false)
          setShowData(true)


        });
    }
    else{
      setShowDate(true)
    }
  }, [props.historyFromStats]);

  function SetForms() {
    getForms(accessToken)
      .then((response) => {
        setForms(response)
      })
      .catch((err) => {
        console.log(err);

      });

  }
  useEffect(() => {
    if (selectedForm !== "" || selectedForm !== null)
      SetForms()
  }, [])

  useEffect(() => {
    if (nameOrMrn || selectedForm || (startDateTime && endDateTime)) {
      setSearchDisable(false);
    } else {
      setSearchDisable(true);
      setShowData(false)
    }
  }, [nameOrMrn, selectedForm, status, startDateTime, endDateTime]);
  const handleDateChange = (value) => {
    setShowCustom(value)
    setShowDate(false)
    const startOfToday = new DateObject();
    startOfToday.setHour(0)
    startOfToday.setMinute(0)
    startOfToday.setSecond(0)
    startOfToday.setMillisecond(0)

    const endOfToday = new DateObject();
    endOfToday.setHour(23)
    endOfToday.setMinute(59)
    endOfToday.setSecond(59)
    endOfToday.setMillisecond(999)

    if (value === '') {
      setStartDateTime(undefined)
      setEndDateTime(undefined)
      setShowDate(true)
    }

    if (value === "0") {

      setStartDateTime(startOfToday);
      setEndDateTime(endOfToday);
    } else if (value === "7" || value === "14" || value === "21") {

      const pastDate = new DateObject().subtract(+value, "days");
      pastDate.setHour(0)
      pastDate.setMinute(0)
      pastDate.setSecond(0)
      pastDate.setMillisecond(0)

      setStartDateTime(pastDate);
      setEndDateTime(endOfToday);
    } else if (value === "Custom") {
      if (dataFromStats.endDatetime !== "" || dataFromStats.endDatetime !== null || dataFromStats.endDatetime !== undefined || dataFromStats.startDatetime !== "" || dataFromStats.startDatetime !== null || dataFromStats.startDatetime !== undefined) {
        setStartDateTime(dataFromStats.startDatetime)
        setEndDateTime(dataFromStats.endDatetime)
      }
      else {

          setStartDateTime(undefined);
          setEndDateTime(undefined);
      }
    }
  };


  const handleSearch = () => {
    setLoader(true)
    var lname = "";
    var fname = "";
    var mrn = "";
    if (nameOrMrn != "") {
      if (/^[0-9]+$/.test(nameOrMrn)) {
        mrn = nameOrMrn;
      } else {
        if (nameOrMrn.includes(", ")) {
          const nameParts = nameOrMrn.split(", ");
          lname = nameParts[0].toUpperCase();
          fname = nameParts[1].toUpperCase();
        } else if (nameOrMrn.includes(" ")) {
          const nameParts = nameOrMrn.split(" ");
          fname = nameParts[0].toUpperCase();
          lname = nameParts[1].toUpperCase();
        } else if (nameOrMrn.includes(",")) {
          const nameParts = nameOrMrn.split(",");
          lname = nameParts[0].toUpperCase();
          fname = nameParts[1].toUpperCase();
        } else {
          lname = nameOrMrn.toUpperCase();
        }
      }
    }
    const isFormatted = (date) => typeof date === 'string' && !isNaN(Date.parse(date));

    const startDatetime = startDateTime != undefined
      ? (isFormatted(startDateTime) ? startDateTime : startDateTime.format())
      : "";

    const endDatetime = endDateTime != undefined
      ? (isFormatted(endDateTime) ? endDateTime : endDateTime.format())
      : "";

    const data = {
      mrn: mrn,
      lastName: lname,
      firstName: fname,
      startDatetime: startDatetime ,
      endDatetime: endDatetime,
      instrumentName: selectedForm,
      status: status
    }
    adminSurveyHistories(data, accessToken).then((response) => {

      setSurveyHistoryData(response)
      setLoader(false)
      setShowData(true)
    })
      .catch((err) => {
        console.log(err);
        setLoader(false)
        setShowData(true)


      });
  }

  return (
    <div className="mx-0 w-100 ">
      <div className="container px-4 ">
        <div className="tabData" id="patientlistdata">
          <div id="patient_information">
            <div className="row adminPageTitle container-fluid" style={{ marginLeft: "0" }}>
              Survey History
            </div>
            <nav>
              <div className="nav nav-tabs" role="tablist">
                <button
                  className="nav-link active textStyle"
                  id="nav-profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-profile"
                  type="button"
                  role="tab"
                  aria-controls="nav-profile"
                  aria-selected="true"
                  style={{ marginLeft: "0" }}
                >
                  Select Filters
                </button>
              </div>
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="nav-profile"
                  role="tabpanel"
                  aria-labelledby="nav-profile-tab"
                >
                  <div
                    id="visitInputs"
                    className="piInputs container-fluid justify-content-center"
                  >
                    <div className="row d-flex">
                      <div className="col-12 col-xl-5 d-flex justify-content-center justify-content-sm-start align-items-center mt-2 mt-xl-0">
                        <div id="AppointmentResource" style={{ width: "100%" }}>
                          <select
                            className="finInput "
                            id="currentAppointment"
                            name="Clinic"
                            value={selectedForm}
                            onChange={e => setSelectedForm(e.target.value)}
                          >
                            <option value="">Select Your Form</option>
                            {forms.map((form) => (
                              <option
                                style={{ border: "none", width: "100%" }}
                                value={form.instrumentName}
                              >
                                {form.instrumentLabel}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-12 col-xl-4 d-flex justify-content-center justify-content-sm-start align-items-center mt-2 mt-xl-0">
                        <div id="AppointmentResource" style={{ width: "100%" }}>
                          <input className='finInput' placeholder="Search by Name or MRN" type="text" id="nameOrMrn" autocomplete="off" value={nameOrMrn} onChange={e => setNameOrMrn(e.target.value)} />
                        </div>
                      </div>

                      <div className="col-12 col-xl-3 d-flex justify-content-center justify-content-sm-start align-items-center mt-2 mt-xl-0">
                        <div id="AppointmentResource" style={{ width: "100%" }}>
                          <select
                            className="finInput "
                            id="currentAppointment"
                            name="Clinic"
                            value={status}
                            onChange={e => setStatus(e.target.value)}
                          >
                            <option value="">Select Status</option>
                            <option value="Scheduled">Scheduled</option>
                            <option value="Sent">Sent</option>
                            <option value="Started">Started</option>
                            <option value="In Validation">In Validation</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Deferred">Deferred</option>
                            <option value="Declined">Declined</option>
                            <option value="Cancelled">Cancelled</option>
                            <option value="Sent to intepreter">Sent to intepreter</option>
                            <option value="Opened by intepreter">Opened by intepreter</option>
                            <option value="Sent to witness">Sent to Witness</option>
                            <option value="Opened by witness">Opened by Witness</option>
                            <option value="Completed">Completed</option>

                          </select>
                        </div>
                      </div>




                    </div>
                    <div className='row mt-2 px-3'>
                      <div className="col-12  col-xl-4 datefilterRow d-flex align-items-center" style={{ alignSelf: "center" }}>

                        <div className="dateText ">Date:</div>
                        <div id="AppointmentResource" style={{ width: "100%" }}>
                          <select className="datefinInput elementFilter" id="currentDate" onChange={e => handleDateChange(e.target.value)} value={showCustom}>
                            <option value="">Date Range</option>
                            <option value="0">Today</option>
                            <option value="7">Last 7 Days</option>
                            <option value="14">Last 14 Days</option>
                            <option value="21">Last 21 Days</option>
                            <option value="Custom">Custom</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-12  col-md-6 col-xl-4 datefilterRow d-flex align-items-center" >

                        {!showDate && <> <div className="dateText col-2 col-xl-3">Start Date:</div>
                          <div id="AppointmentResource " style={{ width: "100%" }}>

                            {/* <input className='elementDate' style={{ border: "1px solid #ced4da", borderRadius: "0.25rem", overflow: "hidden", padding: "0.375rem 0.75rem" }} placeholder="Select date and time" type="datetime-local" id="scheduledate" autocomplete="off" value={startDateTime} onChange={e => (setStartDateTime(e.target.value), setShowCustom("Custom"))} disabled={showDate} max={formatDateToISO8601(new Date())} /> */}
                            <DatePicker
                              inputClass='elementDate'
                              format="MM/DD/YYYY HH:mm"
                              placeholder='mm-dd-yyyy HH:mm'
                              plugins={[
                                <TimePicker position="right" />
                              ]}
                              value={startDateTime}
                              onChange={e => (setStartDateTime(e), setShowCustom("Custom"))}
                              disabled={showDate}
                              maxDate={new DateObject()}
                            />
                          </div></>}

                      </div>
                      <div className="col-12  col-md-6 col-xl-4 datefilterRow d-flex align-items-center" >

                        {!showDate && <>  <div className="dateText col-2 col-xl-3">End Date:</div>
                          <div id="AppointmentResource" style={{ width: "100%" }}>

                            {/* <input className='elementDate' style={{ border: "1px solid #ced4da", borderRadius: "0.25rem", overflow: "hidden", padding: "0.375rem 0.75rem" }} placeholder="Select date and time" type="datetime-local" id="scheduledate" autocomplete="off" value={endDateTime} onChange={e=>(setEndDateTime(e.target.value),setShowCustom("Custom"))} disabled={showDate} max={formatDateToISO8601(new Date())} /> */}
                            <DatePicker
                              inputClass='elementDate '
                              format="MM/DD/YYYY HH:mm"
                              placeholder='mm-dd-yyyy HH:mm'
                              plugins={[
                                <TimePicker position="right" />
                              ]}
                              value={endDateTime}
                              onChange={e => (setEndDateTime(e), setShowCustom("Custom"))}
                              disabled={showDate}
                              maxDate={new DateObject()}
                            />
                          </div></>}

                      </div>
                      <div
                        className="col-12  d-flex justify-content-center justify-content-md-end p-0 "
                      >
                        <button
                          className="searchButton d-flex justify-content-around"
                          id="visitSearchButton"
                          onClick={() => handleSearch()}
                          disabled={searchDisable}
                        >
                          <div>{loader ? <Spinner /> : "Filter"}</div>
                        </button>
                      </div>


                    </div>

                  </div>
                </div>
              </div>
            </nav>
          </div>
        </div>
        <div>
          {showData && <>  {(surveyHistoryData.length == 0 && !searchDisable) ? <div className="row d-flex justify-content-center" style={{ color: "red" }}>No Data Found.</div> : <> {surveyHistoryData.length > 0 ? <SurveyHistoryPatientsList surveyHistoryData={surveyHistoryData} startDateTime={startDateTime} endDateTime={endDateTime} /> : <div className="row d-flex justify-content-center" style={{ color: "red" }}>No Data Found.</div>}</>}</>}
        </div>
      </div>
    </div>
  )
}