import React, { useEffect } from 'react'
import logo from "../images/Chlalogo-01.png"
import getNextForm from '../Api/getNextForm'
const StartSurvey = () => {
  const parseStringToDtoList = (input) => {
    return input.split(',').map((segment) => {
      const [pid, instrumentName, instance] = segment.split('-');
      return { pid, instrumentName, instance };
    });
  };
  const getNextFormUrl = () => {
    var requestBody = {
      sessionId: localStorage.getItem("sessionId"),
      
    }
    getNextForm(requestBody)
      .then((response) => {
        setTimeout(() => {
          window.location.href = response.redirectURL;
        }, 3500);
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    getNextFormUrl()
  }, [])
  return (
    <div>
      <div style={{ backgroundColor: '#004877' }}>
        <img
          style={{
            height: '62px',
            width: '300px',
            padding: '10px'
          }}
          src={logo}
          alt="Chlalogo"
        />
      </div>
      <div
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          padding: '50px',
          fontFamily: 'Arial, Helvetica, sans-serif',
          fontSize: '20px'
        }}
      >
         Starting new survey...
      </div>
    </div>
  )
};

export default StartSurvey;
