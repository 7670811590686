import React, { useState, useRef, useEffect } from "react";
import "../patients.css";
import { useOktaAuth } from "@okta/okta-react";
import { useLocation, useHistory } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FormData from "../Api/FormData.js";
import Chevron from "../images/chevron.png";
import dChevron from "../images/chevron_d.png";
import Instruments from "./instruments";

function Forms(props) {
  const [formData, setFormData] = useState(props.formData);
  const [show, setShow] = useState(false);
  const [showRegistrationForm, setShowRegistrationForm] = useState(false);
  const [fchevron, setfchevron] = useState(false);
  const [regFchevron, setRegFchevron] = useState(false);
  const [regFormListUpdated,setRegFormListUpdated]=useState({});

  const [registrationFormData, setRegistrationFormData] = useState([]);
  useEffect(() => {
    if (props.formData.projects) {
      setFormData(props.formData);
      const newArray = [];
      const priorityArray=[]
      const withoutPriorityArray=[]
      const formHistoryResponse = structuredClone(props.formData);
      const temp = structuredClone(formHistoryResponse);
      const projects = structuredClone(formHistoryResponse.projects);
      for (let i = 0; i < projects.length; i++) {
        const project = projects[i];
        const { pid, name, departmentSettings, instruments } = project;
        for (let j = 0; j < instruments.length; j++) {
          const instrument = instruments[j];
          if (instrument.isRegistrationForm) {
            instrument.pid = pid;
            if(instrument.priority!=""){
              priorityArray.push(instrument);
            }
            else{
              withoutPriorityArray.push(instrument)
            }
          }
        }       
      }
  
      priorityArray.sort((a, b) => a.priority - b.priority);
      withoutPriorityArray.sort((a, b) => a.instrumentName.localeCompare(b.instrumentName));
      setRegistrationFormData([...priorityArray, ...withoutPriorityArray]);
      
    }
  }, [props.formData]);

  useEffect(()=>{
    props.updateFormsAfterEncounterChange(registrationFormData);
  },[regFormListUpdated]);
  return (
    <>
      <div className="formsHeder">
        <div
          className="formsBody"
          onClick={() => {
            setShowRegistrationForm(!showRegistrationForm);
            setRegFchevron(!regFchevron);
          }}
        >
          <div>Registration forms</div>{" "}
          {regFchevron ? (
            <img className="chevron" src={dChevron} />
          ) : (
            <img className="chevron" src={Chevron} />
          )}
        </div>
        {showRegistrationForm ? (
          <>
            {(registrationFormData.length > 0) ?
              <>
                <Instruments data={registrationFormData} FormData={[]} alterChosenForms={props.alterChosenForms} recordId={props.recordId} isSurveySelected={props.isSurveySelected} chosenForms={props.chosenForms} isRegistrationForm={true} setChosenForms={props.setChosenForms} selectForms={props.selectForms} setRegFormListUpdated={setRegFormListUpdated}/>

              </>
              : 
              <div className="row m-0 d-flex justify-content-center p-3 surveysErrorBanner">
                <div
                  className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center"
                  style={{ color: "black" }}
                >
                  <span className="patientsinfoTop">No Registration forms found</span>
                </div>
              </div>}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className="formsHeder">
        <div
          className="formsBody"
          onClick={() => {
            setShow(!show);
            setfchevron(!fchevron);
          }}
        >
          <div>General forms</div>{" "}
          {fchevron ? (
            <img className="chevron" src={dChevron} />
          ) : (
            <img className="chevron" src={Chevron} />
          )}
        </div>
        {show ? (
          <>
          {(formData.projects && formData.projects.length > 0) ?
            <>
              {formData.projects.map((data) => {
                return (
                  <>
                    <Instruments data={data} FormData={formData} alterChosenForms={props.alterChosenForms} recordId={props.recordId} isSurveySelected={props.isSurveySelected} chosenForms={props.chosenForms} isRegistrationForm={false} setChosenForms={props.setChosenForms} selectForms={props.selectForms} setRegFormListUpdated={setRegFormListUpdated}/>
                  </>
                );
              })}
            </>
            : 
            <div className="row m-0 d-flex justify-content-center p-3 surveysErrorBanner">
              <div
                className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center"
                style={{ color: "black" }}
              >
                <span className="patientsinfoTop">No General forms found</span>
              </div>
            </div>}
        </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
export default Forms;
