import React from "react";
import "../Adminpage.css";

const ToggleButton = (props) => {
    return (
        <div className="d-flex flex-row  justify-content-end me-2">

            <div class="form-check form-switch d-flex justify-content-end me-4 col-4 col-md-4 customToggle-lg-4" >
                <input name="interpreter" class="form-check-input me-2" onChange={(e) => props.handleToggle(e)} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={props.InterpretationChecked} />
                <label class="apEmail" style={{ whiteSpace: "nowrap", fontSize: "17px"}} >Interpretation</label>
            </div>
            <div class="form-check form-switch d-flex justify-content-end me-4 col-3 col-md-4 customToggle-lg-4" >
                <input name="registrationForm" class="form-check-input me-2" onChange={(e) => props.handleToggle(e)} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={props.Reg} disabled={props.isPriorityDisabled}/>
                <label class="apEmail" style={{ whiteSpace: "nowrap", fontSize: "17px"}} >Registration Form</label>
            </div>
            <div class="form-check form-switch d-flex justify-content-start  col-3 col-md-4 customToggle-lg-4 ">
                <input name="fillOnce" class="form-check-input me-2" onChange={(e) => props.handleToggle(e)} type="checkbox" role="switch" id="flexSwitchCheckDefault" checked={props.OTSChecked}/>
                <label class=" apEmail" style={{ whiteSpace: "nowrap", fontSize: "17px" }} >One-Time Submission</label>

            </div>
        </div>

    );
};

export default ToggleButton;