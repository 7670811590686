import React, { useEffect, useState } from "react";
import SurveyInfo from "./SurveyInfo";
import { Spinner } from "react-bootstrap";
import getClinic from "../../Api/getClinic"
import getSurveyListByClinic from "../../Api/getSurveyListByClinic"
import { useAuth } from "../../AppContextProvider";
import "../Adminpage.css";
import getRegistrationFormPriorityCount from "../../Api/getRegistrationFromPriorityCount";

const SurveyManagerHome = () => {
    const [clinics, setClinics] = useState([]);
    const [selectedClinic, setSelectedClinic] = useState({ id: "", name: "" });
    const [clinicSurvey, setClinicSurvey] = useState([]);
    const [startscreenloader, setstartscreenloader] = useState(true);
    const [loader, setloader] = useState(false);
    const [showProject, setShowProject] = useState(false);
    const { accessToken } = useAuth();

    useEffect(() => {
        const fetchClinics = () => {
            getClinic(accessToken)
                .then((response) => {
                    
                    setstartscreenloader(false);

                    // handle success
                    setClinics(response);
                })
                .catch((err) => {
                    console.log(err);
                    setstartscreenloader(false);
                })
                getRegistrationFormPriorityCount(accessToken)
                .then((response) => {
                    
                    localStorage.setItem("priorityCount",response)
                })
                .catch((err) => {
                    console.log(err);
                    
                })

        };
        fetchClinics();
    }, []);

    const handleClinicSearch = async (id) => {
        setloader(true)
        getSurveyListByClinic(id, accessToken)
            .then((response) => {
                
                if(id === "1"){
                    setClinicSurvey(response.generalProjects);
                }
                else{
                    setClinicSurvey(response.locationProjects);
                }
                setShowProject(true)
                setloader(false);
            })
            .catch((err) => {
                setloader(false);
                console.log(err);
            })

    };

    const handleSelectChange = (e) => {
        const selectedOption = e.target.options[e.target.selectedIndex];
        const id = selectedOption.value;
        const name = selectedOption.text;
        setSelectedClinic({ id, name });
    };
    
    return (

        <div className="mx-0 w-100 ">
            {/* {startscreenloader&& 
        <div id="overlay">
          <div id="loading" className="spinner">
            <div className="bounce1"></div>
            <div className="bounce2"></div>
            <div className="bounce3"></div>
          </div>
          <div id="loaderText" style={{color:"#fff"}}>
            Please wait while we fetch some information for you
          </div>
        </div>} */}
            {/* Search Component */}
            <div className="container px-4 ">
                <div className="tabData" id="patientlistdata">
                    <div id="patient_information">
                        <div className="row adminPageTitle container-fluid" style={{ marginLeft: "0" }}>
                            Survey Management Settings
                        </div>
                        <nav>
                            <div className="nav nav-tabs" role="tablist">
                                <button
                                    className="nav-link active textStyle"
                                    id="nav-profile-tab"
                                    data-bs-toggle="tab"
                                    data-bs-target="#nav-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="nav-profile"
                                    aria-selected="true"
                                    style={{ marginLeft: "0" }}
                                >
                                    Select Clinic
                                </button>
                            </div>
                            <div className="tab-content" id="nav-tabContent">
                                <div
                                    className="tab-pane fade show active"
                                    id="nav-profile"
                                    role="tabpanel"
                                    aria-labelledby="nav-profile-tab"
                                >
                                    <div
                                        id="visitInputs"
                                        className="piInputs container-fluid justify-content-center"
                                    >
                                        <div className="row d-flex">
                                            <div className="col-lg-9 col-md-12 col-sm-12 d-flex justify-content-center justify-content-sm-start align-items-center">
                                                <div id="AppointmentResource" style={{ width: "100%" }}>
                                                    <select
                                                        className="finInput col-lg-9 col-md-12"
                                                        id="currentAppointment"
                                                        name="Clinic"
                                                        value={selectedClinic.id}
                                                        onChange={handleSelectChange}
                                                    >
                                                        <option value="">Select Your Clinic</option>
                                                        <option value="1">GENERAL</option>
                                                        {clinics.map((clinic) => (
                                                            <option key={clinic.id} value={clinic.id} >
                                                                {clinic.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div
                                                className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center mt-2 mt-lg-0"
                                                style={{ alignSelf: "center" }}
                                            >
                                                <div className="col w-100 m-0 p-0 d-flex justify-content-center">
                                                    <div className="col-sm-4 col-md-4 col-lg-12 m-0 p-0 d-flex justify-content-center">
                                                        <button
                                                            className="searchButton d-flex justify-content-around"
                                                            id="visitSearchButton"
                                                            onClick={() => handleClinicSearch(selectedClinic.id)}
                                                            disabled = {loader}
                                                        >
                                                            <div>{loader ? <Spinner size="sm"/> : "Search"}</div>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
                {/* Survey List */}
                <div className="row d-flex flex-column-reverse flex-lg-row" style={{ margin: "0" }}>
                    {showProject && <SurveyInfo clinicSurvey={clinicSurvey} setClinicSurvey={(id) => handleClinicSearch(id)} selectedClinic={selectedClinic} />}
                </div>
            </div>
        </div>

    );
};

export default SurveyManagerHome;
