import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";

const PatientSearch = (props) => {
    const handleInputChange = (e) => {
        props.setFormData({...props.formData, [e.target.id]:e.target.value});
    }
    return(
        <div id="patient_information">
        <div className="row adminPageTitle container-fluid">
          <div className="col-lg-6 col-sm-12"style={{ fontWeight: "600", fontSize: "2rem", color: "#004879" }}>Search Patients</div>
        </div>

        <div className="piInputs container-fluid justify-content-left">
          <form>
            <div className="row d-flex justify-content-left">
              <div className="col-lg-6 loolkupinput col-md-12 col-sm-12 d-flex justify-content-center justify-content-sm-start mb-2">
                <div className="finInput force-opaque">
                  <input
                    placeholder="Search by Name or MRN"
                    style={{ border: "none", width: "100%" }}
                    type="text"
                    id="nameOrMrn"
                    autoComplete="off"
                    value={props.formData.nameOrMrn}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div
                className="col-lg-3 col-md-12 col-sm-12 d-flex justify-content-center"
                style={{ alignSelf: "center" }}
              >
                <div className="row w-100 m-0 p-0 d-flex justify-content-center">
                  <div className="col-sm-4 mb-2 col-md-4 col-lg-6 m-0 p-0 d-flex justify-content-center">
                    <button
                        className="searchButton  d-flex justify-content-around"
                        id="searchButton"
                        type="submit"
                        onClick={props.handleSearch}
                        disabled = {props.loader || props.formData.nameOrMrn === "" }
                    >
                      <div id="SearchPatient">
                        {props.loader ? <Spinner size='sm'/> : "Search Patient"}
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
}
export default PatientSearch