import React, { useState, useEffect } from "react";
import PatientSearch from "./PatientSearch";
import searchPatientAdmin from "../../Api/searchPatientAdmin";
import { useAuth } from "../../AppContextProvider";
import PatientList from "./PatientList";
import getForms from "../../Api/getForms";

const ResubmitSurveyHome = () => {
    const [patientSearchLoader, setPatientSearchLoader] = useState(false);
    const [patientList, setPatientList] = useState();
    const { accessToken } = useAuth();
    const [formData, setFormData] = useState({
        nameOrMrn : ""
    })
    const [forms, setForms] = useState();
    const handleSearch = () => {
        setPatientList([]);
        var lname = "";
        var fname = "";
        var mrn = "";
        setPatientSearchLoader(true);
        if (/^[0-9]+$/.test(formData.nameOrMrn)) {
          mrn = formData.nameOrMrn;
        } else {
          if (formData.nameOrMrn.includes(", ")) {
            const nameParts = formData.nameOrMrn.split(", ");
            lname = nameParts[0].toUpperCase();
            fname = nameParts[1].toUpperCase();
          } else if (formData.nameOrMrn.includes(" ")) {
            const nameParts = formData.nameOrMrn.split(" ");
            fname = nameParts[0].toUpperCase();
            lname = nameParts[1].toUpperCase();
          } else if (formData.nameOrMrn.includes(",")) {
            const nameParts = formData.nameOrMrn.split(",");
            lname = nameParts[0].toUpperCase();
            fname = nameParts[1].toUpperCase();
          } else {
            lname = formData.nameOrMrn.toUpperCase();
          }
        }
        const searchData = {
          lastName: lname,
          firstName: fname,
          mrn: mrn
        };
        searchPatientAdmin(searchData, accessToken)
        .then((response) => {
            
            setPatientList(response);
            setPatientSearchLoader(false);
        })
        .catch((err) => {
            console.log(err);
            setPatientSearchLoader(false);
        })
        getForms(accessToken)
        .then((response) => {
          setForms(response);
        })
        .catch((err) => { });
        };
    return(
        <div className="mx-0 w-100 ">
            <div className="container px-4 ">
                <PatientSearch loader = {patientSearchLoader} formData={formData} setFormData={setFormData} handleSearch = {handleSearch}/>
                <div className="px-3">
                {patientSearchLoader?
                <>
                    <span style={{color:"#69a3dc"}}>Searching...</span>
                </>
                :
                <>
                {!patientList && 
                    <span style={{color:"#69a3dc"}}>Please enter the patient's Name or MRN</span>
                }
                {patientList?.length === 0 &&
                    <span style={{color:"red"}}>Patient not found. Please Check your Inputs .</span>
                }
                </>}
                </div>
                {patientList && patientList.length > 0 && <PatientList patientsList = {patientList} forms={forms}/>}
            </div>
        </div>
    );
}
export default ResubmitSurveyHome