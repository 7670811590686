import React, { useState, useRef, useEffect } from "react";
import "../patients.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  useHistory,
} from "react-router-dom";
import { useLocation } from "react-router-dom";
import secrets from '../../env.json';

function PatientsBanner(props) {
  const location = useLocation();
  const { selectionType } = location.state;
  const [patientData, setpatientData] = useState(props.patientData);
  const history = useHistory();

  const showEncounterList = () => {
    props.setShowEncounterList(true);
    props.setIsSurvey(false);
  }

  const getApptDetails = () => {
    return (
      <>
        <div className="col-12" style={{ textAlign: "left", whiteSpace: "nowrap" }}>
          <span>Appt Type:&nbsp;{apptType}</span>
        </div>
        <div className="col-12" style={{ textAlign: "left", whiteSpace: "nowrap" }}>
          <span>Appt Location:&nbsp;{apptLocation}</span>
        </div>
      </>
    )
  }
  // Extract data from patientData
  const getDates = (data) => {
    if (data === undefined) {
      return { date: "", age: "" };
    }

    const birthMonth = data.slice(0, 2);
    const birthDay = data.slice(2, 4);
    const birthYear = data.slice(4, 8);
    const today = new Date();
    const birthDate = new Date(`${birthYear}-${birthMonth}-${birthDay}`);

    const ageInMilliseconds = today - birthDate;
    const ageInYears = Math.floor(ageInMilliseconds / 31536000000); // Approximate milliseconds in a year

    return {
      date: `${birthMonth}/${birthDay}/${birthYear}`,
      age: ageInYears + " yrs",
      dateFormat: data, // Added for "mmddyyyy" format
    };
  };
  useEffect(() => {
    setpatientData(props.patientData);
  }, [props.patientData]);
  const patientName = patientData["patient_name"];
  const preferredLanguage = patientData["preferred_language"];
  var encounter = "No ENCOUNTER Found";
  var fin = "No FIN found";
  var payor = "Please select an encounter"
  var apptLocation = "";
  var apptType = "";
  let nameUsed = patientData["name_used"];
  let patientMrn = patientData["mrn"];
  let pmd;

  if (patientData.currentEncounter && selectionType === "encounter") {

    if (patientData.currentEncounter[0]["primary_provider_fname"] !== undefined) {
      pmd =
        patientData.currentEncounter[0]["primary_provider_fname"] +
        " " +
        patientData.currentEncounter[0]["primary_provider_lname"];
    }
    encounter =
      patientData.currentEncounter[0]["enc_type"] === undefined
        ? "No ENCOUNTER Found"
        : patientData.currentEncounter[0]["enc_type"];
    fin =
      patientData.currentEncounter[0]["fin_encounter"] === undefined
        ? "No FIN found"
        : patientData.currentEncounter[0]["fin_encounter"];
    payor=
       (patientData.currentEncounter[0]["payor"] === undefined || patientData.currentEncounter[0]["payor"] === null || patientData.currentEncounter[0]["payor"]==="" )
       ? "No Payor Found"  
       :  patientData.currentEncounter[0]["payor"] 
  }
  if (patientData.currentAppointment && patientData.currentAppointment.length > 0) {
    apptType =
      patientData.currentAppointment[0]["appt_type"] === undefined
        ? "No Appointment Type Found"
        : patientData.currentAppointment[0]["appt_type"];
    apptLocation =
      patientData.currentAppointment[0]["appt_location_unit"] === undefined
        ? "No Appointment Location Found"
        : patientData.currentAppointment[0]["appt_location_unit"];

  }
  const myRef = useRef(null);
  const observer = useRef(null);

  useEffect(() => {
    if (!props.isEncounterList) {
      observer.current = new ResizeObserver(entries => {
        for (let entry of entries) {
          if (entry.target === myRef.current) {
            props.setLookupHeight(entry.contentRect.height + 50);

          }
        }
      });
      observer.current.observe(myRef.current);
      return () => {
        if (observer.current) {
          observer.current.disconnect();
        }
      };
    }
  }, []);
  return (
    <div className={props.isEncounterList ? "container-fluid patientsBanner sticky-top" : "container-fluid patientsBanner fixed-top"} style={props.isEncounterList ? {} : { marginTop: `${secrets.REACT_APP_CERNER_ENV}` === "prod" ? "63px" : "120px", zIndex: "1020", }} ref={myRef}>
      <div className="row">
        <div
          className="col-10"
          style={{
            textAlign: "left",
            fontWeight: "bold",
            fontSize: "x-large",
            marginBottom: "5px",
          }}
        >
          {patientName}
        </div>
        {props.isEncounterList &&
          <div
            className="col-2 d-flex justify-content-right"
            style={{ justifyContent: "right", fontWeight: "bold" }}
          >
            <span>
              <div
                className="submit_button_cancel"
                id="cancelLookupNew"
                style={{
                  backgroundColor: "#EA1D36",
                  width: "24px",
                  height: "24px",
                }}
                onClick={() => {
                  if (props.isEncounterList) {
                    if (props.isSurvey) {
                      props.setEncounterError(true);
                    }
                    else {
                      props.setShowEncounterList(false);
                    }
                  }
                }}
              >
                <div>
                  <span id="cancelIcon">
                    <FontAwesomeIcon icon={faTimes} />

                    {/* <i className="fa fa-times"></i> */}
                  </span>
                </div>
              </div>
            </span>
          </div>
        }
      </div>
      <div className="row m-0">
        <div className="col-md-2 col-4  mr-0 pr-0">
          <div className="row">
            <div className="col-12" style={{ textAlign: "left" }}>
              <span></span>{" "}
              <span>
                {nameUsed !== null ? nameUsed : patientData["name_first"]}
              </span>
            </div>
            <div className="col-12" style={{ textAlign: "left" }}>
              <span>MRN:</span>&nbsp;{patientMrn}
            </div>
            {(!props.isEncounterList && selectionType === "appointment") && <>
              {getApptDetails()}
            </>}
            {(!props.isEncounterList && selectionType !== "appointment") &&
              <>
                {encounter === "No ENCOUNTER Found" ? (
                  <div className="col-12 d-none d-md-block" style={{ textAlign: "left", whiteSpace: "nowrap" }}>
                    <div className="selectEncounterButton" onClick={showEncounterList}>Select Encounter</div>
                  </div>
                ) : (
                  <>
                    <div className="col-12" style={{ textAlign: "left" }}>
                      <span>FIN:&nbsp;{fin}</span>
                    </div>
                    <div className="col-12" style={{ textAlign: "left", whiteSpace: "nowrap" }}>
                      <span>FIN Type:&nbsp;{encounter}</span>
                    </div>
                    {!props.isEncounterList &&
                      <>
                        {props.patientData.currentAppointment ?
                          <>
                            {getApptDetails()}
                          </>
                          :
                          <div className="col-12 d-none d-md-block" style={{ textAlign: "left", whiteSpace: "nowrap" }}>
                            <div className="selectEncounterButton" onClick={showEncounterList}> Change Encounter </div>
                          </div>
                        }

                      </>
                    }
                  </>
                )}
              </>
            }
          </div>
        </div>
        {/* {patientData["encounter"][0]["location_unit"] === undefined ||
        patientData["encounter"][0]["location_unit"] === "" ? null : (
          <div className="col-lg-2 col-md-12 col-sm-12 mr-0 pr-0">
            <div className="row">
              <div className="col-12" style={{ textAlign: "left" }}>
                <span></span> <span></span>
              </div>
              <div className="col-12" style={{ textAlign: "left" }}>
                <span></span> <span></span>
              </div>
              <div className="col-12 loc">
                <span>Loc:</span>{" "}
                <span>
                  {patientData["encounter"][0]["location_unit"][
                    "medical_service"
                  ] === undefined ||
                  patientData["encounter"][0]["location_unit"][
                    "medical_service"
                  ] === ""
                    ? "UNKNOWN"
                    : patientData["encounter"][0]["location_unit"][
                        "medical_service"
                      ]}
                </span>
              </div>
            </div>
          </div>
        )} */}
        <div className="col-md-3 col-4  mr-0 pr-0">
          <div className="row">
            <div className="col-12" style={{ textAlign: "left" }}>
              <span>Age:</span>{" "}
              <span>{getDates(patientData["birth_date"]).age}</span>
            </div>
            <div className="col-12" style={{ textAlign: "left" }}>
              <span>DOB:</span>{" "}
              <span>{getDates(patientData["birth_date"]).date}</span>
            </div>
            <div className="col-12" style={{ textAlign: "left" }}>
              <span></span> <span></span>
            </div>
          </div>
        </div>
        <div className="col-md-3 mr-0 pr-0 col-4 ">
          <div className="row">
            <div className="col-12" style={{ textAlign: "left" }}>
              <span><span>Sex:</span> <span>{patientData["sex"]}</span></span>
            </div>
            <div className="col-12" style={{ textAlign: "left" }}>
            <span>Language:</span> {(patientData["preferred_language"]==""|| patientData["preferred_language"]==undefined || patientData["preferred_language"]==null)?<span>No Language Found</span>:<span>{patientData["preferred_language"]}</span>}
            </div>
            {(!props.isEncounterList && selectionType !== "appointment") &&
            <>
            <div className="col-12" style={{ textAlign: "left" }}>
            <span>Payor:</span>{selectionType=="encounter"?<span style={{alignItems:"center"}} > {payor}</span>:<span style={{alignItems:"center",fontSize:"small"}} > {payor}</span>}
          </div>
            </>}
          </div>
        </div>
        <div className="col-md-2 mr-0 pr-0 col-3">
          {pmd === undefined || pmd.trim() === "" ? null : (
            <div className="row">
              <div className="col-12" style={{ textAlign: "left" }}>
                <span>PMD:</span>{" "}
                <span>
                  {pmd === undefined || pmd.trim() === ""
                    ? "UNKNOWN"
                    : pmd.trim()}
                </span>
              </div>
              <div className="col-12" style={{ textAlign: "left" }}>
                <span></span> <span></span>
              </div>
            </div>
          )}

        </div>
        <div className="col-md-2 col-12">
          {!props.isEncounterList &&
            <div className="row d-flex mt-3">

              {props.patientData.currentAppointment ? <></> :
                <div className="col-8 col-sm-4  d-flex justify-content-center  d-md-none" style={{ textAlign: "left" }}>
                  <div className="submit_button w-100" id="startForms" onClick={showEncounterList} style={{ width: "142px" }}> {selectionType === "encounter" ? "Change Encounter" : "Select Encounter"} </div>
                </div>}

              {props.patientData.currentAppointment ? <>
                <div className="col-md-12 col-6 d-sm-block d-none" style={{ textAlign: "center" }}>
                  <span className="d-flex justify-content-center" >
                    <div
                      className="submit_button"
                      id="startForms"
                      style={props.isButtonActive ? { width: "140px", backgroundColor: "#0074C2 ", color: "white" } : { width: "140px" }}
                      onClick={props.isButtonActive ? () => props.handleLockScreenStatus(true) : null}
                    >
                      <div className="start_button">
                        <div className="d-button">Start</div>
                        <span id="startIcon">
                          <FontAwesomeIcon icon={faPaperPlane} />
                        </span>
                      </div>
                    </div>
                  </span>
                  <span></span>
                </div>
                <div className="col-md-12 col-6 d-sm-block d-none" style={{ textAlign: "center" }}>
                  <span className="d-flex justify-content-center">
                    <div
                      className="submit_button"
                      id="emailForms"
                      style={props.isButtonActive ? { width: "140px", backgroundColor: "#4DC1EF", color: "white" } : { width: "140px" }}
                      onClick={props.openDialog}
                    >
                      <div className="start_button">
                        <div className="d-button">Send</div>
                        <span id="emailIcon">
                          <FontAwesomeIcon icon={faEnvelope} />
                        </span>
                      </div>
                    </div>
                  </span>
                  <span></span>
                </div>
                <div className="d-sm-none d-block col-8"></div>
                <div className="col-2 d-flex d-sm-none  justify-content-end align-items-center p-0">
                <span id="emailIcon" className="d-flex d-sm-none  justify-content-center align-items-center " style={props.isButtonActive ? { width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "#0074C2 ", color: "white" } : { width: "40px", height: "40px", backgroundColor: "#fff", color: "#004879", borderRadius: "50%" }} onClick={props.isButtonActive ? () => props.handleLockScreenStatus(true) : null} >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </span>
                </div>
                <div className="col-2 d-flex d-sm-none  justify-content-end align-items-center p-0">
                <span id="emailIcon" className="d-flex d-sm-none justify-content-center align-items-center " style={props.isButtonActive ? { width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "#4DC1EF", color: "white" } : { width: "40px", height: "40px", backgroundColor: "#fff", color: "#004879", borderRadius: "50%" }} onClick={props.openDialog}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                </div>
                <div className="col-12" style={{ textAlign: "left" }}>
                  <span></span> <span></span>
                </div>
              </> :
                <>
                  <div className="col-md-12 col-4 d-sm-block d-none" style={{ textAlign: "center" }}>
                    <span className="d-flex justify-content-center" >
                      <div
                        className="submit_button"
                        id="startForms"
                        style={props.isButtonActive ? { width: "140px", backgroundColor: "#0074C2 ", color: "white" } : { width: "140px" }}
                        onClick={props.isButtonActive ? () => props.handleLockScreenStatus(true) : null}
                      >
                        <div className="start_button">
                          <div className="d-button">Start</div>
                          <span id="startIcon">
                            <FontAwesomeIcon icon={faPaperPlane} />
                          </span>
                        </div>
                      </div>
                    </span>
                    <span></span>
                  </div>
                  <div className="col-md-12 col-4 d-sm-block d-none" style={{ textAlign: "center" }}>
                    <span className="d-flex justify-content-center">
                      <div
                        className="submit_button"
                        id="emailForms"
                        style={props.isButtonActive ? { width: "140px", backgroundColor: "#4DC1EF", color: "white" } : { width: "140px" }}
                        onClick={props.openDialog}
                      >
                        <div className="start_button">
                          <div className="d-button">Send</div>
                          <span id="emailIcon">
                            <FontAwesomeIcon icon={faEnvelope} />
                          </span>
                        </div>
                      </div>
                    </span>
                    <span></span>
                  </div>
                  <div className="col-2 d-flex d-sm-none  justify-content-center  p-0 ">
                  <span id="emailIcon" className="d-flex d-sm-none justify-content-center align-items-center mx-2" style={props.isButtonActive ? { width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "#0074C2 ", color: "white" } : { width: "40px", height: "40px", backgroundColor: "#fff", color: "#004879", borderRadius: "50%" }} onClick={props.isButtonActive ? () => props.handleLockScreenStatus(true) : null} >
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </span>
                  </div>
                  <div className="col-2 d-flex d-sm-none  justify-content-center  p-0">
                  <span id="emailIcon" className="d-flex d-sm-none  justify-content-center align-items-center mx-2" style={props.isButtonActive ? { width: "40px", height: "40px", borderRadius: "50%", backgroundColor: "#4DC1EF", color: "white" } : { width: "40px", height: "40px", backgroundColor: "#fff", color: "#004879", borderRadius: "50%" }} onClick={props.openDialog}>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </span>
                  </div>
                  <div className="col-12" style={{ textAlign: "left" }}>
                    <span></span> <span></span>
                  </div>
                </>
              }
            </div>
          }
        </div>
      </div>
    </div>
  );
}

export default PatientsBanner;
