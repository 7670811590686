import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import Table from "./Table";
import Form from "./Form";
import { useAuth } from "../AppContextProvider";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import getUserRoles from "../Api/getUserRoles";
import getUserRights from "../Api/getUserRights";
import ResubmitSurveyHome from "./ResubmitSurveys/ResubmitSurveyHome";
import SurveyManagerHome from "./SurveyManager/SurveyManagerHome";
import SurveyHistoryHome from "./SurveyHistory/SurveyHistoryHome";
import StatusCountHome from "./FormStatusCount/StatusCountHome";

const AdminScreen = () => {
  const location = useLocation();
  const history = useHistory();
  const List = location.state;
  const pathname = location.pathname;
  const [showForm, setShowForm] = useState(false);
  const [roles, setRoles] = useState([]);
  const [showUserDetails, setShowUserDetails] = useState(true);
  const [dataFromStats, setDataFromStats]=useState({isFromStats:false});
  const [historyFromStats, setHistoryFromStats]=useState({isFromStats:false});
  const [screenType, setScreenType] = useState("userRights");

  const { isAuthorized, setRedirectUri, accessToken, userAccessStatus } = useAuth();
  const [users, setUsers] = useState([]);

  useEffect(()=>{
    if(dataFromStats.isFromStats)
    {
      setHistoryFromStats(dataFromStats);
      setDataFromStats({isFromStats:false});
    }
    else{
      setHistoryFromStats({isFromStats:false});
    }
  },[dataFromStats])
  
  useEffect(() => {
    if (!isAuthorized) {
      setRedirectUri(pathname);
      history.replace({
        pathname: "/",
      });
    }
    if (userAccessStatus === "admin_access") {
      getUserRoles(accessToken)
        .then((response) => {
          setRoles(response);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isAuthorized, userAccessStatus]);

  const getUserInfo = () => {
    getUserRights(accessToken)
      .then((response) => {
        setUsers(response);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  return (
    <div className="flex-grow-1 d-flex flex-column flex-lg-row">
      {userAccessStatus === "admin_access" ? (
        <>
          <Sidebar setScreenType={setScreenType} screenType={screenType} />
          <div className="w-100">
            <Navbar setScreenType={setScreenType} screenType={screenType} />
            <div className="main-content">
              {screenType === "userRights" && (
                <div className="mx-0 w-100">
                  <div className="container px-4">
                    <Table setShowForm={setShowForm} users={users} setUsers={setUsers} />
                    {showForm && (
                      <Form setShowForm={setShowForm} roles={roles} getUserInfo={getUserInfo} users={users} />
                    )}
                  </div>
                </div>
              )}
              {screenType === "resubmitSurveys" && <ResubmitSurveyHome />}
              {screenType === "surveyManager" && <SurveyManagerHome />}
              {screenType === "surveyHistory" && <SurveyHistoryHome historyFromStats={historyFromStats}/>}
              {screenType === "surveyStatus" && <StatusCountHome setDataFromStats={setDataFromStats} setScreenType={setScreenType}/>}
            </div>
          </div>
        </>
      ) : (
        <div className="text-center w-100">
          <div style={{ fontWeight: "bold", marginTop: "50px" }}>
            <span style={{ fontSize: "70px" }}>404</span>
            <br />
            Not Found
            <br />
            The Page you're looking for is not available.
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminScreen;
