
import React, { useEffect, useState } from "react";
import Chevron from "../../images/chevron.png";
import dChevron from "../../images/chevron_d.png";
import { useAuth } from "../../AppContextProvider";
import { ClinicInstrument } from "./ClinicInstrument";


export const ClinicProject = (props) => {

  const [Ichevron, setIchevron] = useState(false);

  return (
    <div key={props.projectData.name}>
      <div className="formName" onClick={() => setIchevron(!Ichevron)}>
        <div>{props.projectData.name}</div>
        <img className="chevron" src={Ichevron ? dChevron : Chevron} />
      </div>
      {Ichevron && (props.projectData.instruments.map((instrumentData) => (
          <ClinicInstrument encounterOptionList={props.encounterOptionList} projectId = {props.projectData.pid} instrumentData={instrumentData} selectedClinic={props.selectedClinic} setInstrumentunchecked={props.handleInstrumentDelete} setInstrumentChecked={props.setInstrumentChecked} handleTotalRegistrationFromPriorityCount={props.handleTotalRegistrationFromPriorityCount} totalRegistrationFormPriorityCount={props.totalRegistrationFormPriorityCount} setPriorityData={props.setPriorityData} priorityArray={props.priorityArray} isPriorityDisabled={props.isPriorityDisabled} setpriorityDisable={props.setpriorityDisable}/>
      )))}
    </div>
  )
}
