import React, { useState, useEffect } from "react";
import "../patientsList.css";
import { Accordion } from "react-bootstrap-accordion";
import "react-bootstrap-accordion/dist/index.css";
import EncounterTable from "./Encounter/encounterTable";
import AppointmentTable from "./Appointment/appoitnmentTable";
import {
  useHistory,
} from "react-router-dom";
import getPatientHistory from "../Api/getPatientHistory";
import { useAuth } from "../AppContextProvider";
import getAllEncounters from "../Api/getAllEncounters";
import { Spinner } from "react-bootstrap";
import { getAge } from "../../Util/PatientDetailUtil";


function PatientsData(props) {
  const [patientData, setpatientData] = useState(props.patientData);
  const [encountersLoader, setEncountersLoader] = useState(false);
  const [open, setOpen] = useState(false);
  const [encounter, setEncounter] = useState(false);
  const [appointment, setAppointment] = useState(false);
  const patientsName = patientData?.name_used.replaceAll("|", " ");
  const [loader, setloader] = useState(false);
  const { accessToken } = useAuth();
  const history = useHistory();
  const getPatientSurveyHistoryData = () => {
    setloader(true);
    var requestBody = {
      recordId : patientData.record_id,
      age : getAge(patientData.birth_date),
      gender : patientData.sex
    }
    getPatientHistory(requestBody, accessToken)
      .then((response) => {

        setloader(false);
        history.replace({
          pathname: "/PatientDetails",
          state: {
            patientData: patientData,
            List: props.List, // Replace with your list of values,
            surveyData: response,
            selectionType: "patient",
            fin : props.fin
          }
        });
      })
      .catch((err) => {
        setloader(false);

      });
  }

  const getAllEncountersForPatient = (mrn) => {
    setOpen(!open);
    if (patientData && (patientData.encounter.length === 0 && patientData.appointment.length === 0)) {
      setEncountersLoader(true);
      var fin = props.fin;
      if (fin !== "") {
        mrn = "";
      }
      const searchData = {
        lname: "",
        fname: "",
        fin: fin,
        dob: "",
        mrn: mrn,
        cernerEnv: localStorage.getItem('cernerENV')
      };
      getAllEncounters(searchData, accessToken)
        .then((response) => {
          var temp = structuredClone(patientData);
          temp.appointment = response.appointment;
          temp.encounter = response.encounter;
          var ind = props.List.indexOf(patientData);
          if(ind > -1){
            var tempList = structuredClone(props.List);
            tempList[ind] = temp;
            props.setList(tempList);
            tempList = null;
          }
          setpatientData(temp);
          setEncountersLoader(false);
        })
        .catch((err) => {
          setEncountersLoader(false);

        });
    }
  }

  useEffect(() => {
    setpatientData(props.patientData);
  }, [props.patientData]);
  const getDates = (data) => {
    if (data === undefined) {
      return { date: "", age: "" };
    }

    const birthMonth = data.slice(0, 2);
    const birthDay = data.slice(2, 4);
    const birthYear = data.slice(4, 8);

    const today = new Date();
    const birthDate = new Date(`${birthYear}-${birthMonth}-${birthDay}`);

    const ageInMilliseconds = today - birthDate;
    const ageInYears = Math.floor(ageInMilliseconds / 31536000000); // Approximate milliseconds in a year

    return {
      date: `${birthMonth}/${birthDay}/${birthYear}`,
      age: ageInYears + " yrs",
      dateFormat: data, // Added for "mmddyyyy" format
    };
  };

  return (
    <>
      {loader ?
        <div id="overlay">
          <div id="loading" class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
          </div>
        </div> : <></>}
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="row d-flex justify-content-center align-item-center p-3 patientData">
          <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none patientsinfoTop" style={{ color: "black" }}>
              Name:&nbsp;
            </span>
            <span className="patientsinfo apEmail">{patientsName}</span>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none patientsinfoTop" style={{ color: "black" }}>
              MRN:&nbsp;
            </span>
            <span className="patientsinfo">{patientData.mrn}</span>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none patientsinfoTop" style={{ color: "black" }}>
              DOB:&nbsp;
            </span>
            <span className="patientsinfo">
              {getDates(patientData.birth_date).date}
            </span>
          </div>
          <div className="col-lg-2 col-md-12 col-sm-12 d-flex justify-content-left mb-1">
            <span className="d-lg-none patientsinfoTop" style={{ color: "black" }}>
              Age:&nbsp;
            </span>
            <span className="patientsinfo">
              {getDates(patientData.birth_date).age}
            </span>
          </div>
          {patientData.encounter.length > 0 ||
            patientData.appointment.length > 0 ? (
            <>
              <div className="col-lg-2 col-md-4 col-sm-4 d-flex justify-content-center text-center">
                <button
                  className="selectFin"
                  onClick={(e) => { getPatientSurveyHistoryData(); }}
                >
                  Select Person
                </button>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 d-flex justify-content-center text-center">
                <a
                  className="selectvisits text-white"
                  onClick={(e) => {
                    setOpen(!open);
                  }}
                >
                  Show Visits {open ? "▼" : "▲"}
                </a>
              </div>
            </>
          ) : (
            <>
              <div className="col-lg-2 col-md-4 col-sm-4 d-flex justify-content-center text-center">
                <button className="selectFin" onClick={(e) => { getPatientSurveyHistoryData(); }}>Select Person</button>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-4 d-flex justify-content-center text-center">
                <button className="selectvisits noFin" onClick={() => getAllEncountersForPatient(patientData.mrn)}>Show Visits {open ? "▼" : "▲"}</button>
              </div>
            </>
          )}
        </div>
        {open ? (
          <div className="row Accordians">
            {" "}
            {encountersLoader ?
              <div className="col-lg-12 accordionBody">
                <div id="" className="spinner" style={{ marginTop: "40px" }}>
                  <div className="bounce1" style={{ backgroundColor: "#015a96" }}></div>
                  <div className="bounce2" style={{ backgroundColor: "#015a96" }}></div>
                  <div className="bounce3" style={{ backgroundColor: "#015a96" }}></div>
                </div>
              </div>
              :
              <>
                <div className="col-lg-12 accordionBody">
                  <button
                    className="accordionButton"
                    onClick={(e) => {
                      setEncounter(!encounter);
                    }}
                  >
                    <div>ALL ENCOUNTERS</div> <div>{encounter ? "▼" : "▲"}</div>
                  </button>
                  {encounter ? (
                    <div className="encounterTable">
                      <EncounterTable List={props.List} patientData={patientData} EncounterList={patientData.encounter} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-lg-12 accordionBody">
                  <button
                    className="accordionButton"
                    onClick={(e) => {
                      setAppointment(!appointment);
                    }}
                  >
                    <div>ALL APPOINTMENTS</div> <div>{appointment ? "▼" : "▲"}</div>
                  </button>
                  {appointment ? (
                    <div className="encounterTable">
                      <AppointmentTable List={props.List} patientData={patientData} appointmentList={patientData.appointment} />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            }

          </div>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default PatientsData;
